import Formatter from "../utils/Formatter";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMultiWalletCurrency } from "../context/MultiWalletCurrencyContext"


export default function useUserConfig() {
    const { playerGeneral } = useSelector(state => state.PlayerGeneral);

    const { selectedCurrency } = useMultiWalletCurrency();

    const adjustAmountWithUserCurrency = useCallback(
      (value) => Formatter.formatAmount(
        value ?? 0,
        selectedCurrency
          ? selectedCurrency
          : (playerGeneral?.totalBalance?.currency
            ? playerGeneral?.totalBalance?.currency
            : (playerGeneral && playerGeneral?.currency) || '')
      ), [playerGeneral, selectedCurrency]
    );

    return {
        adjustAmountWithUserCurrency,
    };
}

