import { createContext, useContext, useState } from 'react';
import { useSelector } from "react-redux"

const MultiWalletCurrencyContext = createContext(undefined);

export const MultiWalletCurrencyProvider = ({ children }) => {
  const { playerGeneral } = useSelector(state => state.PlayerGeneral);
  const initialPlayerCurrency = playerGeneral?.totalBalance.currency || '';

  const [selectedCurrency, setSelectedCurrency] = useState(initialPlayerCurrency);

  const changeCurrency = (currency) => {
    setSelectedCurrency(currency);
  };

  return (
    <MultiWalletCurrencyContext.Provider value={{ selectedCurrency, changeCurrency }}>
      {children}
    </MultiWalletCurrencyContext.Provider>
  );
};

export const useMultiWalletCurrency = () => useContext(MultiWalletCurrencyContext);