import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import './style.scss';
import brand_arrow from '../../assets/images/common/brand-arrow.svg';
import useClickOutside from '../../common/hooks/useClickOutside';
import useHandleEsc from '../../common/hooks/useHandleEsc';

import { useDispatch, useSelector } from 'react-redux';
import {
    searchPlayerList,
    searchAffiliateList,
    searchDepositList,
    searchWithdrawalList,
    resetSearchListErrors
} from '../../store/actions';
import useAlertService from '../../hooks/useAlertService';
import { condition } from "../../utils/search"
import { PAYMENT_STATUS } from "../../constants/status";
import ListOfResultSearch from "../../UI/listOfResultSearch/ListOfResultSearch";
import FormSearch from "../../UI/formSearch/FormSearch";
import { useTranslation } from "react-i18next";
import { getStorageXSiteId } from "../../common/utils/common";

export const SEARCH_TYPE = {
    AFFILIATE: 'AFFILIATE',
    PLAYER: 'PLAYER',
    DEPOSIT: 'DEPOSIT',
    WITHDRAWAL: 'WITHDRAWAL'
};

export const RECENT_LOCAL_STORAGE_NAME = 'RECENT_LOCAL_STORAGE_NAME';
export const RECENT_LIST_SIZE = 5;

let timeout;

export const SEARCH_LIMIT = 50;

export const SearchBar = () => {
    const areaRef = useRef();
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        active: activeBrand,
    } = useSelector(state => state.Brand);

    useClickOutside(areaRef, () => handleEsc());
    useHandleEsc(() => handleEsc());

    useEffect(() => {
        return () => {
            localStorage.removeItem(RECENT_LOCAL_STORAGE_NAME);
        };
    }, []);

    const { searchResultList, error, isLoading } = useSelector(state => state.Search);

    const [activeType, setActiveType] = useState(SEARCH_TYPE.PLAYER);
    const [searchText, setSearchText] = useState('');

    const [isActive, setIsActive] = useState(false);
    const [isShowTypeMenu, setShowTypeMenu] = useState(false);
    const [isShowSearchMenu, setShowSearchMenu] = useState(false);

    useEffect(() => {
        if (error) {
            alertService.showError(error?.message);
            dispatch(resetSearchListErrors());
        }
    }, [error]);

    const handleEsc = () => {
        setIsActive(false);
        setShowTypeMenu(false);
        setShowSearchMenu(false);
    };

    const handleOpenTypeMenu = useCallback(() => {
        setShowTypeMenu(true);
        setShowSearchMenu(false);
    }, []);

    const handleSelectActiveType = useCallback(type => {
        setActiveType(type);
        setShowTypeMenu(false);
        setSearchText('');
    }, []);

    const getUrlAddress = useCallback(
      (item) => {
          if (activeType === SEARCH_TYPE.AFFILIATE) {
              return `/affiliates/${item.id}`;
          } else if (activeType === SEARCH_TYPE.PLAYER) {
              return `/player-list/${item.id}`;
          } else if (activeType === SEARCH_TYPE.DEPOSIT) {
              return `/deposits/${item.paymentId}`;
          } else if (activeType === SEARCH_TYPE.WITHDRAWAL) {
               if (item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL) {
                   return `/withdrawals-pending-approve/${item.paymentId}`;
               } else {
                   return `/withdrawals/${item.paymentId}`;
               }
          }
      },
      [activeType]
    );

    const setToRecentList = useCallback(
        item => {
            const xSiteId = getStorageXSiteId();
            const recent = JSON.parse(localStorage.getItem(RECENT_LOCAL_STORAGE_NAME)) || {};

            if (!recent[xSiteId]) {
                recent[xSiteId] = {};
            }

            const recentBySiteId = recent[xSiteId];
            // Check or exist item in recent
            const recentList = (recentBySiteId[activeType] || []).filter(
                data => `${data.id}` !== `${item.id}`
            );

            recentBySiteId[activeType] = [
                { id: item.id, label: item.label, status: item.status },
                ...recentList
            ].slice(0, RECENT_LIST_SIZE);

            localStorage.setItem(RECENT_LOCAL_STORAGE_NAME, JSON.stringify(recent));
        },
        [
            activeType,
            localStorage.getItem(RECENT_LOCAL_STORAGE_NAME),
            getStorageXSiteId(),
        ]
    );

    const handleChangeSearchText = useCallback(
        ({ target }) => {
            setSearchText(target.value)
            clearInterval(timeout);
            if (target.value) {
                timeout = setTimeout(() => {
                    setShowSearchMenu(true);
                    handleLoadResult(target.value);
                }, 500);
            } else {
                setShowSearchMenu(false);
            }
        },
        [activeType]
    );

    const handleLoadResult = useCallback(
        value => {
            const query = { siteId: activeBrand?.id, searchText: value, limit: SEARCH_LIMIT };

            if (activeType === SEARCH_TYPE.AFFILIATE) {
                dispatch(searchAffiliateList(query));
            } else if (activeType === SEARCH_TYPE.PLAYER) {
                dispatch(searchPlayerList(query));
            } else if (activeType === SEARCH_TYPE.DEPOSIT) {
                dispatch(searchDepositList(query));
            } else if (activeType === SEARCH_TYPE.WITHDRAWAL) {
                dispatch(searchWithdrawalList(query));
            }
        },
        [activeType]
    );

    const prepareObjWithLabel = (data, activeType) => {
        if (activeType === SEARCH_TYPE.AFFILIATE) {
            return {
                id: data.id,
                status: data.status,
                label: `${data.email} (${data.name}, ${data.contactName}, (id: ${data.oid})`
            };
        } else if (activeType === SEARCH_TYPE.PLAYER) {
            return {
                id: data.id,
                status: data.status,
                label: `${data.firstName} ${data.lastName} (id: ${data.id})`
            };
        } else if (activeType === SEARCH_TYPE.DEPOSIT) {
            return {
                id: data.id,
                status: data.status,
                paymentId: data.paymentId,
                label: `${t(`filter.constant.${data.status}`).toUpperCase()}: ${data.amount} ${data.currency} (deposit id: ${data.id})`
            };
        } else if (activeType === SEARCH_TYPE.WITHDRAWAL) {
            return {
                id: data.id,
                status: data.status,
                paymentId: data.paymentId,
                label: `${t(`filter.constant.${data.status}`).toUpperCase()}: ${data.amount} ${data.currency} (withdrawal id: ${data.id})`
            };
        } else {
            return {
                id: data.id,
                label: `${data.id} (${data.player?.firstName} ${data.player?.lastName})`
            };
        }
    };

    const resultList = useMemo(() => {
        if (searchResultList?.length) {
            const highCorrespondingResults = [];
            const lowCorrespondingResults = [];

            searchResultList.forEach(element => {
                const preparedItem = prepareObjWithLabel(element, activeType);

                if (condition(preparedItem.label, searchText))  {
                    highCorrespondingResults.push(preparedItem);
                } else {
                    lowCorrespondingResults.push(preparedItem);
                }
            });

            return [ ...highCorrespondingResults, ...lowCorrespondingResults ] || [];
        }
    }, [activeType, searchResultList]);

    const recentList = useMemo(() => {
        const xSiteId = getStorageXSiteId();
        const recent = JSON.parse(localStorage.getItem(RECENT_LOCAL_STORAGE_NAME)) || {};

        const recentBySiteId = recent[xSiteId] || {};

        return recentBySiteId[activeType] || [];
    }, [
        activeType,
        localStorage.getItem(RECENT_LOCAL_STORAGE_NAME),
        getStorageXSiteId(),
    ]);

    return (
        <div ref={areaRef} className="app-search-bar">
            <div
                className={`app-search-bar__form ${isActive ? 'active' : ''}`}
                onClick={() => setIsActive(true)}
            >
                <div
                    className="search-type-select"
                    onClick={handleOpenTypeMenu}
                >
                    {t(`common.searchType.${activeType}`)}
                    <img
                        className="search-type-select__icon"
                        src={brand_arrow}
                        alt="vector"
                    />
                </div>
                <div
                    className="search-input-select"
                    onClick={() => setShowTypeMenu(false)}
                >
                    <FormSearch
                        onChangeSearchValue={handleChangeSearchText}
                        searchValue={searchText}
                    />
                </div>
            </div>

            {isShowTypeMenu && (
                <div className="app-search-bar__type-menu">
                    {Object.keys(SEARCH_TYPE).map(item => {
                        const isActive = item === activeType;

                        return (
                            <div
                                key={item}
                                className={`menu-item ${
                                    isActive ? 'active' : ''
                                }`}
                                onClick={() => handleSelectActiveType(item)}
                            >
                                <div className="menu-item__label">
                                    {t(`common.searchType.${item}`)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            {isShowSearchMenu && !isLoading && (
                <ListOfResultSearch
                    setShowSearchMenu={setShowSearchMenu}
                    searchText={searchText}
                    isLoading={isLoading}
                    resultList={resultList}
                    recentList={recentList}
                    setToRecentList={setToRecentList}
                    getUrlAddress={getUrlAddress}
                />
            )}
        </div>
    );
};
