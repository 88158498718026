import React, { useCallback, useEffect } from "react";
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Select } from '@mui/material';

import { COUNTRIES } from '../../utils/countries';
import { CURRENCIES } from '../../utils/currencies';
import { GMT_LIST } from '../../utils/timezone';
import { LIMIT_LEVEL_TYPE_SELECT } from '../../utils/limitLevelTypeSelect';
import { SELECT_CATEGORIES } from '../../constants/selectCategories';
import { LANGUAGES } from '../../utils/languages';
import {PROMOTION_ENTER_CONDITION} from "../../pages/Promotions/common/promotionConstants";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import {SELECT_REQUIRE_FIELDS} from "../../utils/require-fields";
import { useDispatch, useSelector } from "react-redux";
import { getAgentsList } from "../../store/agents/agents/actions";
import { SITE_BRAND } from "../../constants/siteBrands";

/**
 * @param category can be "gender", "countries", "currencies"
 * @param fulWidth
 * @param label
 * @param value
 * @param error
 * @param handleChange
 * @param className
 * @param disabled
 * @param options
 * @param hasClear
 * @returns {JSX.Element}
 * @constructor
 */
function AMultiSelectField({
    category = 'gender',
    fulWidth = false,
    label,
    value,
    error,
    handleChange,
    className,
    disabled = false,
    options,
    hasClear = true
}) {
    const style = {
        style: {
            ...(disabled && { color: '#20262d' }),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            minWidth: '195px',
            marginTop: '6px'
        },
        wrapper: {
            position: 'relative',
        },
        label: {
            marginLeft: '12px',
            fontSize: '12px',
            color: error
                ? '#d23f2f'
                : disabled
                ? 'rgba(0, 0, 0, 0.38)'
                : '#495057'
        },
        iconButton: {
            position: 'absolute',
            right: '32px',
            bottom: '5px',
            fontSize: '1.25rem',
            zIndex: 99,
            display: 'none'
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxWidth: 390,
            },
            sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "#c5c9c6 !important"
                }
            }
        },
    };

    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const { agentsList } = useSelector(state => state.AgentList);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const [_agentList, _setAgentList] = React.useState([]);

    useEffect(() => {
        dispatch(getAgentsList());
    }, [activeBrand?.siteName])

    useEffect(() => {
        if (agentsList && activeBrand?.siteName === SITE_BRAND.Dealer21) {
            const isWiztechUser = agentSelf?.email.includes('wiztechgroup.com');
            const filteredAgents = isWiztechUser
              ? agentsList
              : agentsList.filter(agent => !agent.email.includes('wiztechgroup.com'));

            _setAgentList(filteredAgents);
        } else {
            _setAgentList(agentsList);
        }
    }, [agentsList, agentSelf, activeBrand]);

    const getOptions = category => {
        switch (category) {
            case SELECT_CATEGORIES.AGENT_OWNER:
                return (_agentList || [])?.map(agent => ({
                    value: agent.id,
                    content: agent.agentName
                }));
            case SELECT_CATEGORIES.GENDER:
                return [
                    { value: 'male', content: i18n.t(`common.gender.male`) },
                    { value: 'female', content: i18n.t(`common.gender.female`) }
                ];
            case SELECT_CATEGORIES.ENTER_CONDITION:
                return Object.values(PROMOTION_ENTER_CONDITION)
                    .map(value => ({value, content: value}));
            case SELECT_CATEGORIES.COUNTRIES:
                return COUNTRIES.map(key => {
                    return {
                        value: key,
                        content: i18n.t(key)
                    };
                });
            case SELECT_CATEGORIES.LANGUAGES:
                return LANGUAGES.map(key => {
                    return {
                        value: key,
                        content: i18n.t(`language.${key}`)
                    };
                });
            case SELECT_CATEGORIES.CURRENCIES:
                return CURRENCIES.map(key => {
                    return {
                        value: key,
                        content: key
                    };
                });
            case SELECT_CATEGORIES.SELECT_REQUIRE_FIELDS:
                return SELECT_REQUIRE_FIELDS.map(({key,value}) => {
                    return {
                        value: key,
                        content: value
                    };
                });
            case SELECT_CATEGORIES.TIMEZONE:
                return GMT_LIST.map(key => {
                    return {
                        value: key,
                        content: key
                    };
                });
            case SELECT_CATEGORIES.LIMIT_LEVEL_TYPE_SELECT:
                return LIMIT_LEVEL_TYPE_SELECT.map(key => {
                    return {
                        value: key,
                        content: i18n.t(`admin.constants.${key}`)
                    };
                });
            case SELECT_CATEGORIES.GAMES:
                return options.map(({ id, friendlyName }) => {
                    return {
                        value: id,
                        content: friendlyName
                    };
                });
            case SELECT_CATEGORIES.DEFAULT:
                return options?.map(({ id, name }) => {
                    return {
                        value: id,
                        content: name
                    };
                });
        }
    };

    const handleChange_ = useCallback(
        e => {
            handleChange(e.target.value);
        },
        [handleChange]
    );

    const handleClear = () => handleChange([]);

    return (
        <div style={style.wrapper} className={'wrapperMultiSelect'}>
            <div
                style={fulWidth ? { width: '100%' } : {}}
                className={`fieldContainer multiSelect ${
                    disabled ? 'disabledField' : ''
                }`}
            >
                {label && category && (
                    <InputLabel id={category} style={style.label}>
                        {label}
                    </InputLabel>
                )}
                {hasClear && !disabled &&
                    <IconButton size="small" style={style.iconButton} onClick={handleClear}>
                        <CloseRoundedIcon fontSize="inherit" />
                    </IconButton>
                }
                <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                    style={fulWidth ? { width: '100%' } : {}}
                >
                    <Select
                        multiple
                        labelId={category}
                        id={category}
                        variant="standard"
                        disabled={disabled}
                        value={value}
                        onChange={handleChange_}
                        className={`fieldContainer ${className}`}
                        style={{ ...style.style }}
                        error={error}
                        title={value}
                        sx={{ maxWidth: '320px'}}
                        MenuProps={MenuProps}
                    >
                        {getOptions(category).map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.content}
                            </MenuItem>
                        ))}
                    </Select>
                    {error && (
                        <span
                            style={{
                                color: '#d32f2f',
                                fontSize: '13px',
                                marginTop: '3px'
                            }}
                        >
                            {error}
                        </span>
                    )}
                </FormControl>
            </div>
        </div>
    );
}

export default React.memo(AMultiSelectField);
