import { getCashierListApi } from "../helpers/cashierApi";
import { useEffect, useState } from "react";

const useCashierDepositMethods = () => {
  const [cashierDepositsOptions, setCashierDepositsOptions] = useState([]);
  const [isLoadingCashierMethods, setIsLoadingCashierMethods] = useState(true);

  useEffect(() => {
    const loadMethods = async () => {
      try {
        const depositResponse = await getCashierListApi('DEPOSIT');

        const depositData = depositResponse.data.result
          .filter(item => item.method !== '' && item.status !== undefined)
          .map(item => ({
            value: item.id,
            label: `${item.name} (${item.status ? 'active' : 'disabled'})`,
          }));

        setCashierDepositsOptions(depositData);
      } catch (error) {
        console.error('Failed to load methods', error);
      } finally {
        setIsLoadingCashierMethods(false);
      }
    };

    loadMethods();
  }, []);

  return { cashierDepositsOptions, isLoadingCashierMethods };
};

export default useCashierDepositMethods;