import { get, postSync } from "./api_helper"

const getSecretVipLevelAvailable = (oid) => {
  const url = `/players/oid/${oid}/secret-vip-level-status`;
  return get(url);
};

const activateSecretVipLevel = (oid) => {
  const url = `/players/oid/${oid}/unlock-secret-vip-level`;
  return postSync(url);
}

const addVipComment = (oid, comments) => {
  const url = `/players/oid/${oid}/comment-on-secret-vip-level`;
  return postSync(url, {comments});
}

export {
  getSecretVipLevelAvailable,
  activateSecretVipLevel,
  addVipComment,
}