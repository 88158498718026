import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import PromotionTypeSelect from "../common/PromotionTypeSelect";
import { PROMOTION_STATUS, PROMOTION_STATUS_ARR } from "../common/promotionConstants";
import SelectInput from "../../../common/inputs/common/SelectInput";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";

const PromotionsFilter = ({ filters, setFilters, setOpen, isSportMode = false }) => {
    const ACTIVE_PROMOTIONS = `${PROMOTION_STATUS.PENDING}, ${PROMOTION_STATUS.RUNNING}`;
    const [componentFilters, setComponentFilters] = useState({});

    const initialFilter = {
        type: null,
        status: ACTIVE_PROMOTIONS,
        name: '',
        ids: '',
        byAgent: false,
    }

    useEffect(() => {
        if (!Object.keys(componentFilters).length) {
            setComponentFilters(filters);
        }
    }, [filters]);

    const setFilterValue = (key, value) => {
        setComponentFilters(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const onApplyClick = () => {
        setFilters(componentFilters);
        setOpen();
    }

    const onCancelClick = () => {
        setFilters(initialFilter);
        setComponentFilters(initialFilter);
    }
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilterValue(name, type === 'checkbox' ? checked : value);
    }

    return (
      <div>
          <div className="d-flex mt-4 align-items-center">
              <div className={'grid_desc'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                      {i18n.t("promotions.type")}:
                  </div>
              </div>

              <div className={'grid_valueView'}>
                  <PromotionTypeSelect
                    onChange={(value) => setFilterValue('type', value)}
                    value={componentFilters?.type}
                    isSportMode={isSportMode}
                  />
              </div>
          </div>

          <div className="d-flex mt-4 align-items-center">
              <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.status')}:</span>
              </div>
              <div className={'grid_valueView'} style={{marginLeft: '38px'}}>
                  <SelectInput
                    options={PROMOTION_STATUS_ARR
                      .map(item => ({label: i18n.t(`promotion.statuses.${item}`), value: item}))
                    }
                    value={componentFilters?.status}
                    onChange={(value) => setFilterValue('status', value)}
                  />
              </div>
          </div>

          <div className="d-flex mt-4 align-items-center">
              <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.promoName')}:</span>
              </div>
              <div className={'grid_valueView'} style={{marginLeft: '38px', maxWidth: '100px'}}>
                  <input
                    className='form-control form-control-gray'
                    name="name"
                    onChange={handleChange}
                    value={componentFilters?.name || ''}
                  />
              </div>
          </div>

          <div className="d-flex mt-4 align-items-center">
              <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.ids')}:</span>
              </div>
              <div className={'grid_valueView'} style={{marginLeft: '38px', maxWidth: '100px'}}>
                  <input
                    className='form-control form-control-gray'
                    name="ids"
                    onChange={handleChange}
                    value={componentFilters?.ids || ''}
                    placeholder='Separated by commas'
                  />
              </div>
          </div>

          <div className="d-flex align-items-center mt-2">
              <div>
                  <span className="font-w-500 promo-filter__checkbox">{i18n.t('promotions.onlyMyPromotions')}:</span>
              </div>
              <div className='grid_valueView grid_valueView__checkbox promo__checkbox'
                   style={{marginLeft: '10px', marginBottom: '20px'}}>
                  <ACommonDataField
                    component={A_COMMON_DATA_FIELD.CHECK_BOX}
                    value={componentFilters?.byAgent}
                    handleChange={(e) => setFilterValue('byAgent', e)}
                    className={'wrapper_checkBox'}
                  />
              </div>
          </div>

          <div className='buttons-filter'>
              <button onClick={onCancelClick} className='btn btn-rounded btn-light me-2 mb-lg-2'>
                  {i18n.t('crm.clear')}
              </button>
              <button onClick={onApplyClick} className='btn btn-rounded btn-primary mb-lg-2'>
                  {i18n.t('crm.apply')}
              </button>
          </div>

      </div>
    )
}

export default PromotionsFilter;
