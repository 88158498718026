import Formatter from "../../../utils/Formatter";
import right_arrow from '../../../assets/images/common/right-arrow (1) 1.svg';
import { useSelector } from "react-redux";
import useVipLevelMapping from "../../../hooks/useVipLevelMapping";

const RenderVIPAudit = ({ vipAuditList }) => {
  const { agentsList } = useSelector(state => state.AgentList);
  const { getVipLevelBrandName } = useVipLevelMapping();

  const displayAgentNameById = (id) => {
    const agent = agentsList?.find(agent => agent.id === id);
    return agent ? agent.agentName : id;
  };

  const renderPropertyValue = (item, value) => {
    switch (item.property) {
      case 'agentOwner':
        return displayAgentNameById(value);
      case 'vipLevel':
        const vipName = getVipLevelBrandName(value);
        return vipName ? vipName : value;
      default:
        return value;
    }
  };

  return (
    <div className='verification-history-box'>
      <ul>
        {(vipAuditList ?? []).map((item, index) => {
          const dayAgo = Math.floor(
            (Date.now() - new Date(item.time).getTime()) / (24 * 60 * 60 * 1000)
          );

          const fromValue = renderPropertyValue(item, item.from);
          const toValue = renderPropertyValue(item, item.to);

          return (
            <li
              className="font-w-500 mb-3"
              key={`${item.property}-${index}-${item.time}`}
            >
              <div className="font-w-500">
                <span className="me-2">
                  {item.action}
                  {dayAgo > 0 && (
                    <span className="text-muted"> {dayAgo} days ago</span>
                  )}
                </span>
              </div>

              <div className="mt-1">
                <span className="me-2" style={{ color: '#000' }}>
                  {item.property}:
                </span>

                {`${fromValue}`}
                <img className="me-2 ms-2" src={right_arrow} alt="to" />
                {`${toValue}`}

                <span className="text-muted ms-2">
                  {Formatter.displayDateTime(item.time)}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RenderVIPAudit;