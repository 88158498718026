import React, { memo } from "react";
import { removeUnScrollBody, setUnScrollBody } from "../../../../../utils/unScrollBody";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import i_51 from "../../../../../assets/images/common/Frame 176 (1).svg";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import useCashierDepositMethods from "../../../../../hooks/useCashierDepositMethods";
import usePromotionLists from "../../../../../hooks/usePromotionLists";
import Loader from "../../../../../common/components/Loader";


const ActionSettingsModal = (props) => {
  const {
    modalTitle,
    onToggle,
    disabledPaymentMethods,
    setDisabledPaymentMethods,
    setSpecificCasinoIds,
    setSpecificSportIds,
    specificCasinoIds,
    specificSportIds,
    updatePromoIdsInCurrentRules,
  } = props;

  const { cashierDepositsOptions, isLoadingCashierMethods } = useCashierDepositMethods();
  const { casinoPromoList, sportPromoList, isLoadingPromoList } = usePromotionLists();

  const { t } = useTranslation();

  const handlePaymentMethodsChange = (selectedOptions) => {
    const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setDisabledPaymentMethods(selectedIds);
  };

  const handlePromoChange = (setSpecificIds, promoIdKey) => (selectedOptions) => {
    const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSpecificIds(selectedIds);
    updatePromoIdsInCurrentRules(selectedIds, promoIdKey);
  };


  return (
    <Modal
      isOpen={true}
      role="dialog"
      autoFocus={true}
      data-toggle="modal"
      className="audit-changes-modal fraud-settings-modal"
      centered
      onOpened={setUnScrollBody}
      onExit={removeUnScrollBody}
    >
      <div className="modal-header">
        <h5 className="modal-title text-uppercase">
          {t(`admin.modal.${modalTitle}`)}
        </h5>
        <button className="close" onClick={onToggle} />
      </div>

      <ModalBody>
        <div className='modal-body-fraud'>
          {modalTitle === 'block_specific_promotions' ? (
            isLoadingPromoList ? (
              <Loader size='sm' />
            ) : (
              <div className="promotion-modal-list-wrapper">
                <div>
                  <span>{t('promotions.casinoPromotions')}</span>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    value={casinoPromoList.filter(option => specificCasinoIds.includes(option.value))}
                    options={casinoPromoList}
                    isSearchable
                    onChange={handlePromoChange(setSpecificCasinoIds, 'promotionIds')}
                    className={`react-select multiselect`}
                    classNamePrefix="react-select multiselect"
                  />
                </div>

                <div>
                  <span>{t("promotions.sport.promotions")}</span>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    value={sportPromoList.filter(option => specificSportIds.includes(option.value))}
                    options={sportPromoList}
                    isSearchable
                    onChange={handlePromoChange(setSpecificSportIds, 'sportPromotionIds')}
                    className={`react-select multiselect`}
                    classNamePrefix="react-select multiselect"
                  />
                </div>
              </div>
            )
          ) : (
            isLoadingCashierMethods ? (
              <Loader size="sm" />
            ) : (
              <Select
                isMulti
                closeMenuOnSelect={false}
                value={cashierDepositsOptions.filter(option => disabledPaymentMethods.includes(option.value))}
                options={cashierDepositsOptions}
                isSearchable
                onChange={handlePaymentMethodsChange}
                className={`react-select multiselect`}
                classNamePrefix="react-select multiselect"
              />
            )
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="footer-fraud-modal">
          <button
            className="btn btn-primary btn-rounded"
            onClick={onToggle}
          >
          <img src={i_51} alt="" />
            {t('crm.submit')}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ActionSettingsModal);