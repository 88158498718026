import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import GamePriorityUpdateModal from '../../pages/CRM/modal/GamePriorityUpdateModal';
import GameUpdateModal from '../../pages/CRM/modal/GameUpdateModal';

import groupCheckbox from '../../assets/images/layout/group-checkbox.svg';
import { DIRECTION } from "../../constants/layout";
import {
    getCategoryGames,
    putCategoryGame,
    updateGame,
    updateSiteGame
} from "../../store/games/actions";
import clsx from "clsx";
import TableRow from '../../pages/Content/components/TableRow';
import GameCategoryUpdateModal from "../../pages/CRM/modal/GameCategoryUpdateModal";
import ButtonsPanel from "../../pages/CRM/general/ButtonsPanel";
import { withRouter } from "react-router-dom";
import { getColumnSettingsLsKey, initTableColumns } from '../../common/utils/common';
import TableSettingsModal from "../../pages/CRM/modal/TableSettingsModal";
import { useTranslation } from "react-i18next";
import { SCREEN_SIZES } from 'utils/consts';
import useWindowSize from 'hooks/useWindowSize';
import useAlertService from "../../hooks/useAlertService";
import { SITE_PERMISSION } from "../constants/common";

const DEFAULT_WINPOT_CDN_URL = 'https://winpot-cdn.mx/prod/mobile/';
const GAME_PICTURE_REG_EXP = /[^0-9a-zA-Z]+/g;

const WITH_TWO_CAPITAL_WORDS = [
    'obryans',
    'obryan',
];

const WITH_NUMBERS = [
    'CAI SHEN 88',
    'MILLION 88',
    'WIlDCANO',
];

const WITH_SPACE_WORDS = [
    'Alchemy Ways'
];

const LOWER_CASE_WORDS = ['and', 'the', 'at', 'of', 'vs'];

const LOWER_CASE_REGEX = /[a-z]/g;

const ONLY_FIRST_CAPITAL = [
    'Cash Cuisine',
];

function capitalizeFirstLetter(string) {
    if (WITH_TWO_CAPITAL_WORDS.includes(string.toLowerCase())) {
        return string.slice(0,2).toUpperCase() + string.slice(2).toLowerCase();
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const capitalizeStringArr = (stringArr) => stringArr.map((word, ind) => {
    if (word === '&') {
        word = 'and';
    }
    return LOWER_CASE_WORDS.includes(word.toLowerCase()) && ind > 0
      ? word.toLowerCase()
      : capitalizeFirstLetter(word);
}).join(' ');

const prepareFriendlyName = (friendlyName = '') => {
    if (friendlyName[friendlyName.length - 1] === '%') {
        friendlyName = friendlyName.slice(0, -1);
    }
    const friendlyNameArr = friendlyName.split(' ');

    const capitalize = (friendlyName, friendlyNameArr) => {
        if (WITH_NUMBERS.includes(friendlyName) || !LOWER_CASE_REGEX.test(friendlyName)) {
            return capitalizeStringArr(friendlyNameArr);
        }
        return friendlyName;
    }

    let preparedName;
    if (friendlyNameArr.length === 1) {
        preparedName = capitalize(friendlyName, friendlyNameArr)
    }
    else {
        const lastPart = +friendlyNameArr[friendlyNameArr.length - 1];
        if (!WITH_NUMBERS.includes(friendlyName) && typeof lastPart === "number" && lastPart < 100 && lastPart > 50) {
            preparedName = friendlyNameArr.slice(0, -1).join(' ');
        }
        else {
            preparedName = capitalize(friendlyName, friendlyNameArr)
        }
    }
    return preparedName.replace(/\s/g, "");
}

const getGamePicture = (friendlyName, isMobileView) => {
    let preparedName = prepareFriendlyName(friendlyName);

    if (ONLY_FIRST_CAPITAL.includes(preparedName)) {
        preparedName = capitalizeFirstLetter(preparedName);
    }

    if (!WITH_SPACE_WORDS.includes(preparedName)) {
        preparedName = preparedName.replace(GAME_PICTURE_REG_EXP, '');
    }
    return DEFAULT_WINPOT_CDN_URL + preparedName + ".png";
};

const handleColumns = (columns) => {
    const columnsCount = columns.length;
    let defaultColumns = new Array(columnsCount);
    const columnsObj = {};
    let checkableId = {
        first: '',
        last: '',
    };

    let counter = 0;
    columns.forEach((column, ind) => {
        const { id, title } = column;
        columnsObj[id] = column;

        if (!!title) {
            defaultColumns[counter++] = id;
        }
        else {
            if (ind === 0) {
                checkableId.first = id;
            }
            else {
                checkableId.last = id;
            }
        }
    });

    defaultColumns = defaultColumns.filter((column) => !!column);

    return { defaultColumns, checkableId, columnsObj };
};


const GameTable = (props) => {
    const {
        columns,
        notSortedColumns,
        games,
        activePage,
        pageSize,
        currentRowHeight,
        onCheckAll,
        onSortGames,
        modalMode,
        categoryMode,
        gameCategory,
        isGamePriorityUpdateModal,
        increaseOrDecreaseGames,
        canEditByPermission,
        handleGameCheckboxChange,
        handleCheckCategoryGame,
        isShownSelectedGames,
        isGamesDB,
        isShowTableSettings,
        onCloseTableSettings,
        match,
        isUseColumns,
        defaultSortedColumn,
        defaultSortDirection,
    } = props;
    const isPromoPage = location.pathname.includes('promotions');

    const { t } = useTranslation();
    const size = useWindowSize();

    const dispatch = useDispatch();
    const [showArrow, setShowArrow] = useState(false);
    const [currentColumn, setCurrentColumn] = useState(null);
    const [currentGame, setCurrentGame] = useState({});
    const [sortDirection, setSortDirection] = useState(DIRECTION.ASC);
    const [showGamePriorityModal, setShowGamePriorityModal] = useState(false);
    const [showGameCategoryUpdateModal, setShowGameCategoryUpdateModal] = useState(false);
    const [gamePictures, setGamePictures] = useState({});
    const [changedFields, setChangedFields] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const refGamePictures = useRef({});
    const prevState = useRef({
        isUpdatingGames: false,
    });
    const { isUpdatingGames, errorGames } = useSelector(
        state => state.Games
    );
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const canEditGamesDetails = agentPermissions?.includes(SITE_PERMISSION.Game__Edit_Game_Details);

    const [activeColumns, setActiveColumns] = useState([]);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [currentColumnsArr, setCurrentColumnsArr] = useState([]);
    const [checkableId, setCheckableId] = useState('');
    const [columnsObj, setColumnsObj] = useState('');
    const [activeRow, setActiveRow] = useState(false);
    const alertService = useAlertService();

    useEffect(() => {
        if (defaultSortedColumn) {
            const column = columns.find(col => col.id === defaultSortedColumn);
            setCurrentColumn(column);
            setSortDirection(defaultSortDirection);
            setShowArrow(true);
        }
    }, [defaultSortedColumn, defaultSortDirection, columns]);

    useEffect(() => {
        if (!isUseColumns) {
            const {defaultColumns, checkableId, columnsObj} = handleColumns(columns);

            initTableColumns(match.path, () => defaultColumns, setActiveColumns);
            setDefaultColumns(defaultColumns);
            setCheckableId(checkableId);
            setColumnsObj(columnsObj);
        }
    }, [columns, isUseColumns]);

    useEffect(() => {
        const currentColumnsArr = [
            ...(checkableId.first ? [columnsObj[checkableId.first]] : []),
            ...activeColumns.map((column) => columnsObj[column]),
            ...(checkableId.last ? [columnsObj[checkableId.last]] : []),
        ];

        setCurrentColumnsArr(currentColumnsArr);
    }, [activeColumns, checkableId, columnsObj]);

    useEffect(() => {
        if (!isUpdatingGames && prevState.current.isUpdatingGames && !errorGames) {
            hideGamePriorityModal();
            hideGameCategoryUpdateModal();
        }
        prevState.current = {
            ...prevState.current,
            isUpdatingGames,
        }
    }, [isUpdatingGames, errorGames]);

    useEffect(() => {
        setIsEdit(!!Object.values(changedFields).length);
    }, [changedFields]);

    useEffect(() => {
        const currGamePictures = refGamePictures.current;
        const pageGames = games
            .slice((activePage - 1) * pageSize, pageSize * activePage);
        const pageGameIds = pageGames.map((game) => game.id);
        pageGames.filter((game) => !currGamePictures[game.id])
            .forEach((game) => currGamePictures[game.id]
                = getGamePicture(game.friendlyName || game.gameName));
        const gamePictures = {};
        pageGameIds.forEach((id) => gamePictures[id] = currGamePictures[id]);
        setGamePictures(gamePictures);
    }, [games, activePage, pageSize]);

    const sortColumn = useCallback((column) => {
        if (column?.id) {
            const newDirection = sortDirection === DIRECTION.ASC
                ? DIRECTION.DESC
                : DIRECTION.ASC
            onSortGames(column, newDirection);
            setSortDirection(newDirection);
            setCurrentColumn(column);
            setShowArrow(true);
        }
    }, [sortDirection, onSortGames]);

    const handleCancel = () => {
        dispatch(getCategoryGames(gameCategory));
        setChangedFields({})
    }

    const handleSave = () => {
        Object.values(changedFields).forEach((game) => {
            dispatch(putCategoryGame({
                id: gameCategory,
                gameId: game.id,
                priority: game.priority || 1000,
                emphasize: game.emphasize
            }));
        });
        setChangedFields({});
    };

    const handleGameClick = (game, index) => {
        if (!canEditGamesDetails) {
            return alertService.showError(t('crm.accessRestricted'));
        }
        if (canEditByPermission) {
            setCurrentGame(game);
            setShowGamePriorityModal(true);
        }
        if (canEditByPermission && categoryMode) {
            setShowGameCategoryUpdateModal(true);
            setShowGamePriorityModal(false);
        }
    };

    const hideGamePriorityModal = () => {
        setCurrentGame({});
        setShowGamePriorityModal(false);
    }

    const hideGameCategoryUpdateModal = () => {
        setCurrentGame({});
        setShowGameCategoryUpdateModal(false);
    }

    const handlePriorityUpdateConfirm = ({gameName, ...fields}) => {
        dispatch(updateSiteGame({
            gameId: currentGame.id,
            ...fields,
            ...(gameName && {friendlyName: gameName} || {}),
        }));
    };

    const handleCategoryGameUpdateConfirm = ({gameName, ...fields}) => {
        dispatch(putCategoryGame({
            id: gameCategory,
            gameId: currentGame.id,
            ...fields,
        }));
    };

    const handleUpdateGame = (game) => {
        dispatch(updateGame({
            gameId: currentGame.id,
            ...game,
        }));
    };

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleRowClick = (index) => {
        if (size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT) {
            activeRow === index ? setActiveRow(null) : setActiveRow(index)
        } else {
            return;
        }
    }

    return (
        <>
            <table>
                <thead className='game-table-header'>
                    <tr style={{height: '5vh'}}>
                        {(isUseColumns ? columns : currentColumnsArr)
                            .map((column, ind) => (
                            <th
                                className={clsx({
                                    'check-title-cell': column.id === 'check',
                                    'not-sorted': notSortedColumns.includes(column.id)
                                })}
                                onClick={column.id === 'check' || notSortedColumns.includes(column.id)
                                  ? () => {}
                                  : () => sortColumn(column)
                                }
                            >
                                {column.id === 'check' ? (
                                    isPromoPage ? (
                                        <div className='stub'/>
                                    ) : (
                                        <img
                                            className="group-checkbox"
                                            src={groupCheckbox}
                                            alt=""
                                            onClick={onCheckAll}
                                        />
                                    )
                                ) : (
                                    column.title
                                )}
                                <span  className={clsx('direction__arrow-games',
                                    {'is-show-games': showArrow && column.id === currentColumn.id},
                                    {'is-rotate-games': sortDirection === DIRECTION.ASC})}
                                />
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {games
                        .slice((activePage - 1) * pageSize, pageSize * activePage)
                        .map((game, index) => (
                            <TableRow
                                key={game.id}
                                game={game}
                                gameEdit={changedFields[game.id]}
                                categoryMode={categoryMode}
                                columns={isUseColumns ? columns : currentColumnsArr}
                                currentRowHeight={currentRowHeight}
                                setEditedGame={setChangedFields}
                                handleGameClick={isShownSelectedGames || categoryMode || isGamesDB
                                    ? () => handleGameClick(game)
                                    : () => {}
                                }
                                increaseOrDecreaseGames={increaseOrDecreaseGames}
                                gamePictures={gamePictures}
                                handleGameCheckboxChange={
                                    handleGameCheckboxChange
                                        ? () => handleGameCheckboxChange(game.id)
                                        : () => handleCheckCategoryGame(game.id)
                                }
                                onClick={modalMode
                                    ? () => {}
                                    : () => handleGameClick(game, index)
                                }
                                handleRowClick={handleRowClick}
                                modalMode={modalMode}
                                canEditByPermission={canEditByPermission}
                                isShownSelectedGames={isShownSelectedGames}
                                className={clsx({['open']: activeRow === index})}
                                index={index}
                            />
                        ))}
                </tbody>
                {showGamePriorityModal && (isGamePriorityUpdateModal ? (
                        <GamePriorityUpdateModal
                            onConfirm={handlePriorityUpdateConfirm}
                            onUpdateGame={handleUpdateGame}
                            onHide={hideGamePriorityModal}
                            currentGame={currentGame}
                            categoryMode={categoryMode}
                        />
                    ) : (
                        <GameUpdateModal
                            onConfirm={handlePriorityUpdateConfirm}
                            onUpdateGame={handleUpdateGame}
                            onHide={hideGamePriorityModal}
                            currentGame={currentGame}
                            categoryMode={categoryMode}
                        />
                    )
                )
                }
                {showGameCategoryUpdateModal && categoryMode &&
                    <GameCategoryUpdateModal
                        onConfirm={handleCategoryGameUpdateConfirm}
                        onUpdateGame={handleUpdateGame}
                        onHide={hideGameCategoryUpdateModal}
                        gameName={prepareFriendlyName(currentGame?.gameName)}
                        currentGame={currentGame}
                        categoryMode={categoryMode}
                    />
                }
            </table>
            {isEdit && categoryMode &&
                <ButtonsPanel
                    className='buttons-category'
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            }

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={t('crm.tableSettings')}
                    titlesObj={columnsObj}
                />
            )}
        </>
    );
};

GameTable.propTypes = {
    columns: PropTypes.array,
    notSortedColumns: PropTypes.array,
    games: PropTypes.array,
    activePage: PropTypes.number,
    pageSize: PropTypes.number,
    currentRowHeight: PropTypes.number,
    onCheckAll: PropTypes.func,
    onChangeGames: PropTypes.func,
    onSortGames: PropTypes.func,
    modalMode: PropTypes.func,
    categoryMode: PropTypes.bool,
    gameCategory: PropTypes.string,
    isGamePriorityUpdateModal: PropTypes.bool,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
    isUseColumns: PropTypes.bool,
};

GameTable.defaultProps = {
    columns: [],
    notSortedColumns: [],
    games: [],
    activePage: 1,
    pageSize: 1,
    currentRowHeight: 40,
    onCheckAll: () => {},
    onChangeGames: () => {},
    onSortGames: () => {},
    modalMode: false,
    categoryMode: false,
    gameCategory: '',
    isGamePriorityUpdateModal: false,
    isShowTableSettings: false,
    onCloseTableSettings: () => {},
    isUseColumns: false,
};

export default React.memo(withRouter(GameTable));
