import React from "react";
import PropTypes from "prop-types";
import close from "../../../assets/images/common/close 1.svg";
import i18n from "../../../i18n";
import vector from "../../../assets/images/common/Vector 71 (1).svg";
import group_4752 from "../../../assets/images/common/Group 4752.svg";


function ButtonsPanel({ onCancel, onSave, onSetIsEdit, isEdit, isNew, isDisabled }) {
  return (
    <div className="buttons-row">
      {isEdit || isNew
          ? <>
            {!isNew &&
              <button className="btn btn-rounded btn-light me-2" onClick={onCancel} disabled={false}>
                <img className="me-2" src={close} alt=""/>
                {i18n.t('common.cancel')}
              </button>
            }

            <button className="btn btn-rounded btn-primary" onClick={onSave} disabled={isDisabled}>
              <img className="me-2" src={vector} alt="" />
              {isNew ? i18n.t('common.create') : i18n.t('common.save')}
            </button>
          </>
          : <>
            <button className="btn btn-rounded btn-primary" onClick={() => onSetIsEdit(true)} disabled={isDisabled}>
              <img className="me-2" src={group_4752} alt="" />
              {i18n.t('common.edit')}
            </button>
          </>
      }
    </div>
  );
}

ButtonsPanel.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSetIsEdit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isNew: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default React.memo(ButtonsPanel);
