import overview from "../../assets/images/layout/menu-icons/overview.svg";
import overviewActive from "../../assets/images/layout/menu-icons/overview-active.svg";
import playerList from "../../assets/images/layout/menu-icons/player-list.svg";
import playerListActive from "../../assets/images/layout/menu-icons/player-list-active.svg";
import playerReport from "../../assets/images/layout/menu-icons/playerReport.svg";
import playerReportActive from "../../assets/images/layout/menu-icons/playerReportActive.svg";
import pendingKycVerification from "../../assets/images/layout/menu-icons/pending-kyc-verification.svg";
import pendingKycVerificationActive from "../../assets/images/layout/menu-icons/pending-kyc-verification-active.svg";
import affiliates from "../../assets/images/layout/menu-icons/affiliates.svg";
import affiliatesActive from "../../assets/images/layout/menu-icons/affiliates-active.svg";
import gameCategories from "../../assets/images/layout/menu-icons/game-categories.svg";
import gameCategoriesActive from "../../assets/images/layout/menu-icons/game-categories-active.svg";
import Cashier from "../../assets/images/layout/menu-icons/cashier.svg";
import CashierActive from "../../assets/images/layout/menu-icons/cashier-active.svg";
import deposits from "../../assets/images/layout/menu-icons/deposits.svg";
import depositsActive from "../../assets/images/layout/menu-icons/deposits-active.svg";
import withdrawals from "../../assets/images/layout/menu-icons/withdrawals.svg";
import withdrawalsActive from "../../assets/images/layout/menu-icons/withdrawals-active.svg";
import pendingTransactions from "../../assets/images/layout/menu-icons/pending-transactions.svg";
import pendingTransactionsActive from "../../assets/images/layout/menu-icons/pending-transactions-active.svg";
import promotions from "../../assets/images/layout/menu-icons/promotions.svg";
import promotionsActive from "../../assets/images/layout/menu-icons/promotions-active.svg";
import user from "../../assets/images/layout/menu-icons/user.svg";
import users from "../../assets/images/layout/menu-icons/user.svg";
import userActive from "../../assets/images/layout/menu-icons/user-active.svg";
import usersActives from "../../assets/images/layout/menu-icons/user-active.svg";
import site from "../../assets/images/layout/menu-icons/site.svg";
import siteActive from "../../assets/images/layout/menu-icons/site-active.svg";
import automation from "../../assets/images/layout/menu-icons/automation.svg";
import userManual from "../../assets/images/layout/menu-icons/userManual.svg";
import automationActive from "../../assets/images/layout/menu-icons/automation-active.svg";
import {isProdMode, SITE_PERMISSION} from "../../common/constants/common";
import {MENU_POINTS_KEYS, siteSettings} from "./menuConfiguration";

// check if query param dev=1
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const isDevMode = params.
    dev === '1';

const filterBrandMenu = (menus, siteName, siteSettings) => {
    return menus.reduce((acc, currMenu) => {
        return [
            ...acc,
            {...currMenu,
                items: currMenu.items?.filter(m => siteSettings[m.key] ? siteSettings[m.key].has(siteName) : true)
            },
        ]
    }, []);
};

const MENU_POINTS = (t, activeBrand) => {
    const CRM_MENU = {
        menuTitle: t("crm.menuTitle.CRM"),
        items: [
            {
                permissions: [SITE_PERMISSION.Dashboard__View],
                key: MENU_POINTS_KEYS.overview,
                link: '/overview',
                img: overview,
                activeImg: overviewActive,
                title: 'crm.overview'
            },
            {
                permissions: [
                    SITE_PERMISSION.Player__View_Documents,
                    SITE_PERMISSION.Player__View_Player_Info,
                ],
                key: MENU_POINTS_KEYS.playerList,
                link: '/player-list',
                img: playerList,
                activeImg: playerListActive,
                title: 'crm.playerList'
            },
            {
                permissions: [SITE_PERMISSION.Player__Report__View],
                key: MENU_POINTS_KEYS.playerReport,
                link: '/player-report',
                img: playerReport,
                activeImg: playerReportActive,
                title: 'crm.playerReport'
            },
            {
                permissions: [SITE_PERMISSION.Player__View_Verification_Tab],
                key: MENU_POINTS_KEYS.pendingKYC,
                link: '/pending-kyc-verification',
                img: pendingKycVerification,
                activeImg: pendingKycVerificationActive,
                title: 'crm.pendingKycVerification',
            },
            {
                permissions: [SITE_PERMISSION.Player__Affiliate],
                key: MENU_POINTS_KEYS.affiliates,
                link: '/affiliates',
                img: affiliates,
                activeImg: affiliatesActive,
                title: 'crm.affiliates'
            }
        ]
    }

    const CASHIER_MENU = {
        menuTitle: t("crm.menuTitle.Cashier"),
        items: [
            {
                permissions: [SITE_PERMISSION.Cashier__View,SITE_PERMISSION.AgentModel__Enable],
                key: MENU_POINTS_KEYS.cashierDeposit,
                link: '/cashier/deposit',
                img: Cashier,
                activeImg: CashierActive,
                title: 'crm.depositCashier',
            },
            {
                permissions: [SITE_PERMISSION.Cashier__View,SITE_PERMISSION.AgentModel__Enable],
                key: MENU_POINTS_KEYS.cashierWithdraw,
                link: '/cashier/withdraw',
                img: Cashier,
                activeImg: CashierActive,
                title: 'crm.withdrawCashier',
            },
            {
                permissions: [SITE_PERMISSION.Cashier__View,SITE_PERMISSION.AgentModel__Enable],
                key: MENU_POINTS_KEYS.cashierSuppliers,
                link: '/cashier/suppliers',
                img: Cashier,
                activeImg: CashierActive,
                title: 'crm.suppliersCashier',
                disabled: !isDevMode,
                comingSoon: !isDevMode
            }
        ]
    }

    const FINANCE_MENU = {
        menuTitle: t("crm.menuTitle.Finance"),
        permissions: [SITE_PERMISSION.Transactions__View],
        items: [
            {
                permissions: [SITE_PERMISSION.Transactions__View],
                key: MENU_POINTS_KEYS.deposits,
                img: deposits,
                activeImg: depositsActive,
                title: 'finance.deposits',
                isCollapsed: true,
                subMenu: [
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "deposits",
                        link: '/deposits',
                        img: deposits,
                        activeImg: depositsActive,
                        title: 'finance.allDeposits',
                    },
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "deposits-action",
                        link: '/deposits-action',
                        img: deposits,
                        activeImg: depositsActive,
                        title: 'finance.deposits-action',
                    },
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "deposits-declines",
                        link: '/deposits-declines',
                        img: deposits,
                        activeImg: depositsActive,
                        title: 'finance.deposits-declines',
                    }
                ]
            },
            {
                permissions: [SITE_PERMISSION.Transactions__View],
                key: MENU_POINTS_KEYS.withdrawals,
                img: withdrawals,
                activeImg: withdrawalsActive,
                title: 'finance.withdrawals',
                isCollapsed: true,
                subMenu: [
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "withdrawals",
                        link: '/withdrawals',
                        img: withdrawals,
                        activeImg: withdrawalsActive,
                        title: 'finance.allWithdrawals',
                    },
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "pendingWithdrawals",
                        link: '/withdrawals-pending-approve',
                        img: pendingTransactions,
                        activeImg: pendingTransactionsActive,
                        title: 'finance.pendingWithdrawals',
                    },
                    {
                        permissions: [SITE_PERMISSION.Transactions__View],
                        key: "pendingWireWithdrawals",
                        link: '/withdrawals-pending-transfer',
                        img: pendingTransactions,
                        activeImg: pendingTransactionsActive,
                        title: 'finance.pendingWireWithdrawals',
                    }
                ]
            },
        ]
    }

    const CONTENT_MENU = {
        menuTitle: t("crm.menuTitle.Content"),
        items: [
            {
                key: MENU_POINTS_KEYS.altenarprovider,
                link: isProdMode() ?
                    'https://sb2admin-altenar2.biahosted.com/' :
                    'https://sb2admin-altenar2-stage.biahosted.com/',
                img: playerList,
                activeImg: playerListActive,
                title: 'crm.sport',
                isLink: true,
            },
            {
                key: MENU_POINTS_KEYS.gameCategories,
                permissions: [SITE_PERMISSION.Game__View_Game_Categories],
                link: '/game-categories',
                img: gameCategories,
                activeImg: gameCategoriesActive,
                title: 'content.gameCategories',
            },
            {
                key: MENU_POINTS_KEYS.gameManagement,
                permissions: [SITE_PERMISSION.Game__View_Game_Site],
                link: '/game-management',
                img: gameCategories,
                activeImg: gameCategoriesActive,
                title: 'content.gameManagement',
            },
        ]
    }

    const ENGAGEMENT_MENU = {
        menuTitle: t("crm.menuTitle.Engagement"),
        items: [
            {
                key: MENU_POINTS_KEYS.casinoPromotions,
                permissions: [SITE_PERMISSION.Promotion__Get_Promotions],
                link: "/promotions",
                img: promotions,
                activeImg: promotionsActive,
                title: "promotions.casinoPromotions",
            },
            {
                key: MENU_POINTS_KEYS.sportPromotions,
                permissions: [SITE_PERMISSION.Promotion__Get_Promotions],
                link: "/sport-promotions",
                img: promotions,
                activeImg: promotionsActive,
                title: "promotions.sport.promotions",
            },
            {
                key: MENU_POINTS_KEYS.smartico,
                link: "/smartico",
                img: promotions,
                activeImg: promotionsActive,
                title: "promotions.smartico",
            },
            // {
            //     key: MENU_POINTS_KEYS.tracker,
            //     permissions: [
            //         SITE_PERMISSION.Player__View_Documents,
            //         SITE_PERMISSION.Player__View_Player_Info,
            //     ],
            //     link: "/tracker",
            //     img: playerList,
            //     activeImg: playerListActive,
            //     title: 'crm.tracker',
            // },
        ]
    }

    const MANAGE_MENU = {
        menuTitle: t("crm.menuTitle.Manage"),
        items: [
            {
                key: MENU_POINTS_KEYS.agentProfile,
                link: '/agent-view',
                img: user,
                activeImg: userActive,
                title: 'admin.managerView'
            },
            {
                permissions: [SITE_PERMISSION.IT__Get_Brand_Configuration],
                key: MENU_POINTS_KEYS.brandSettings,
                link: '/site',
                img: site,
                activeImg: siteActive,
                title: 'admin.brandSettings'
            },
            {
                permissions: [SITE_PERMISSION.IT__Set_Agents],
                key: MENU_POINTS_KEYS.adminRole,
                link: '/admin-role',
                img: automation,
                activeImg: automationActive,
                title: 'admin.adminRole'
            },
            {
                permissions: [SITE_PERMISSION.IT__Set_Agents],
                key: MENU_POINTS_KEYS.agents,
                link: '/admin-agents',
                img: users,
                activeImg: usersActives,
                title: 'admin.agents'
            },
            {
                key: MENU_POINTS_KEYS.userManual,
                link: 'https://wgaming-cdn.com/backoffice/Backoffice.pdf',
                img: userManual,
                activeImg: userManual,
                title: 'admin.userManual',
                isLink: true,
            }
        ]
    }

    const ADMIN_MENU = {
        menuTitle: t("crm.menuTitle.Admin"),
        items: [
            {
                key: MENU_POINTS_KEYS.gamesDB,
                permissions: [SITE_PERMISSION.Game__View_DB_Game],
                link: '/games-db',
                img: gameCategories,
                activeImg: gameCategoriesActive,
                title: 'content.gamesDB',
            },
        ]
    }

    const RETAIL_MENU = {
        menuTitle: t("crm.menuTitle.Retail"),
        items: [
            {
                permissions: [SITE_PERMISSION.Retail__MX_View],
                key: MENU_POINTS_KEYS.retail,
                link: '/retail-player-list',
                img: gameCategories,
                activeImg: gameCategoriesActive,
                title: 'content.retail',
            }
        ]
    }

    const array = [
        CRM_MENU,
        FINANCE_MENU,
        RETAIL_MENU,
        CASHIER_MENU,
        CONTENT_MENU,
        ENGAGEMENT_MENU,
        MANAGE_MENU,
        ADMIN_MENU,
    ]

    return filterBrandMenu(array, activeBrand?.siteName, siteSettings);
}

export default MENU_POINTS;
