import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardBody, Col} from 'reactstrap';

import ClipboardButton from '../../../common/cellData/CopyButton';
import StatusView from '../../../components/Status/StatusView';
import useToggleModal from '../../../hooks/useToggleModal';
import ManualAdjustmentModal from './modal/ManualAdjustmentModal';

import frame_10950 from '../../../assets/images/common/Frame 10950.svg';
import frame_10950_hover from '../../../assets/images/common/Frame 10950_hover.svg';
import FlagCountry from '../../../common/components/FlagCountry';
import StatusChangeModal from '../../../components/Status/StatusChangeModal';
import {useDispatch, useSelector} from 'react-redux';
import {
    createManualAdjustment, getAgentSelf,
    getPlayerEmail,
    getPlayerGeneral, getPlayerPhone,
    resetManualAdjustmentAlertsErrors,
    setPlayerGeneralEdit, showPlayerDropdown,
    updatePlayerNote,
    updatePlayerStatus,
} from '../../../store/actions';
import group from '../../../assets/images/common/Tooltip.svg';
import coin_1 from '../../../assets/images/common/coin 1.svg';
import AKYCField from '../../../components/GenericComponents/kycField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import CallView from './callView/CallView';
import useAlertService from '../../../hooks/useAlertService';
import {prepareFieldErrors} from './model/playerEditModel';
import {useParams, withRouter} from 'react-router-dom';
import NotesPopup from './notes/notesPopup';
import NotesView from './notesView/NotesView';
import Formatter from '../../../utils/Formatter';
import {Block} from 'react-color/lib/components/block/Block';
import {PLAYERS_COLORS} from '../../Admin/site/siteConstants';
import clsx from 'clsx';
import {customApiCall, getSync, patchSync} from '../../../helpers/api_helper';
import ChatPopup from './chatModal/chatPopup';
import SimpleInfoLine from "../../../UI/infoLine/SimpleInfoLine";
import {getGuidShortFormat, preparePlayerPromoAmount, handleChangePath} from "../../../common/utils/common";
import {A_COMMON_DATA_FIELD} from '../../../constants/aCommonDataField';
import ButtonsPanel from './ButtonsPanel';
import {useTranslation} from "react-i18next";
import {PROMOTION_TYPE} from "../../Promotions/common/promotionConstants";
import {SITE_PERMISSION} from "../../../common/constants/common";
import {useTimeContext} from "../../../context/TimeContext";
import UserStatistics from "../../../components/UserStatistics/UserStatistics";
import PermissionWrapper from "../../../components/PermissionWrapper";
import useUserConfig from "../../../hooks/useUserConfig";
import useVipLevelMapping from "../../../hooks/useVipLevelMapping";
import CoolOffTimer from "../../Admin/components/CoolOff/CoolOffTimer/CoolOffTimer"
import Summarise from "./summarise/Summarise"
import { SITE_BRAND } from "../../../constants/siteBrands"
import MultiWalletSelect from "./MultiWallet/MultiWalletSelect"
import { useMultiWalletCurrency } from "../../../context/MultiWalletCurrencyContext"

const queryString = require('query-string');

function ButtonManualAdjustment({onClick}) {
    const {i18n} = useTranslation();

    const [isHover, setIsHover] = useState(false);
    return (
        <div className="align-self-center flex-1">
            <button
                className="btn btn-outline-light btn-rounded"
                style={{boxShadow: 'none'}}
                onClick={onClick}
                onMouseDown={() => setIsHover(true)}
                onMouseUp={() => setIsHover(false)}
            >
                <img
                    src={frame_10950_hover}
                    alt=""
                    className={`me-2 ${isHover ? '' : 'd-none'}`}
                />
                <img
                    src={frame_10950}
                    alt=""
                    className={`me-2 ${isHover ? 'd-none' : ''}`}
                />
                {i18n.t('crm.manualAdjustment')}
            </button>
        </div>
    );
}

function InfoSubBlock({player}) {
    const {i18n} = useTranslation();
    const {timeFormat} = useTimeContext();

    const dispatch = useDispatch();
    const {
        email,
        phone,
        isLoadedEmail,
        isLoadedPhone,
    } = useSelector(state => state.PlayerGeneral);

    const infoSchema = useMemo(() => {
        return [
            {
                label: i18n.t('crm.emailAddress'),
                value: email,
                onLoadSecureField: () => dispatch(getPlayerEmail(player.oid)),
                isLoadedSecureField: isLoadedEmail,
            },
            {
                label: i18n.t('crm.phoneNumber'),
                value: phone?.baseUserPhone,
                onLoadSecureField: () => dispatch(getPlayerPhone(player.oid)),
                isLoadedSecureField: isLoadedPhone,
            },
            {
                label: i18n.t('crm.language'),
                value: player.language
                    ? i18n.t(`language.${player.language.toUpperCase()}`)
                    : '-'
            },
            {
                label: i18n.t('crm.currency'),
                value: player?.currency
            },
            {
                label: i18n.t('crm.lastLogin'),
                value: player.last_login
                    ? Formatter.displayDateTimeMinutes(timeFormat.parseTime(player.last_login))
                    : '-'
            },
            {
                label: i18n.t('crm.registrationDate'),
                value: player.registration_date
                    ? Formatter.displayDateTimeMinutes(timeFormat.parseTime(player.registration_date))
                    : '-'
            },
            {
                label: i18n.t('crm.ftdDate'),
                value: player.ftdDate
                    ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(player.ftdDate))
                    : '-'
            }
        ]
    }, [player, phone, email, isLoadedEmail, isLoadedPhone, timeFormat, i18n.language]);


    return (
        <div className="sub-content">
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={group} alt=""/>
                <span className="font-w-500 info__title">{i18n.t('crm.info')}</span>
            </div>
            <div className="sub-content__content row">
                <div
                    className="col-xxl-auto col-xxl-auto"
                    style={{width: '100%'}}
                >
                    {infoSchema.map(item => {
                        return (
                            <ACommonDataField
                                key={item.label}
                                disabled={true}
                                label={item.label}
                                value={item.value}
                                onLoadSecureField={item.onLoadSecureField}
                                isLoadedSecureField={item.isLoadedSecureField}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function BalancesSubBlock({player, handleShowManualAdjustment}) {
    const {i18n} = useTranslation();
    const { selectedCurrency } = useMultiWalletCurrency();

    const summary = player?.totalBalances
      ? player?.totalBalances?.find(balance => balance.currency === selectedCurrency)?.summary || {}
      : player?.totalBalance?.summary || {};

    const pendingWinArray = player?.totalBalance?.freeSpins?.map(spins => spins.pendingWin) || [];
    const sumPendingWinsFS = pendingWinArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const { adjustAmountWithUserCurrency } = useUserConfig();

    const infoSchema = useMemo(() => {
        return [
            {
                label: i18n.t('crm.total'),
                value: adjustAmountWithUserCurrency(summary?.total)
            },
            {
                label: i18n.t('crm.withdrawable'),
                value: adjustAmountWithUserCurrency(summary?.withdrawal)
            },
            {
                label: i18n.t('crm.realBalance'),
                value: adjustAmountWithUserCurrency(summary?.cash)
            },
            {
                label: i18n.t('crm.casinoPromoBalance'),
                value: adjustAmountWithUserCurrency(summary?.promo)
            },
            {
                label: i18n.t('crm.sportPromoBalance'),
                value: adjustAmountWithUserCurrency(summary?.sportPromo)
            },
            {
                label: i18n.t('crm.pendingFreeSpinWin'),
                value: adjustAmountWithUserCurrency(sumPendingWinsFS)
            },
            {
                label: i18n.t('crm.pendingBalance'),
                value: adjustAmountWithUserCurrency(summary?.pending)
            },
            {
                label: i18n.t('crm.committedFunds'),
                value: adjustAmountWithUserCurrency(summary?.committedFunds)
            },
            {
                label: i18n.t('crm.block'),
                value: adjustAmountWithUserCurrency(summary?.block)
            }
        ];
    }, [summary, sumPendingWinsFS, i18n.language]);

    return (
        <div className="sub-content">
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={coin_1} alt=""/>
                <span className="font-w-500 info__title">{i18n.t('crm.balances')}</span>
            </div>
            <div className="sub-content__content row">
                {infoSchema.map(({label, value}) =>
                    <div key={label}>
                        <SimpleInfoLine label={label} info={(value)}/>
                    </div>
                )}
            </div>

            <PermissionWrapper accessPermissions={[SITE_PERMISSION.Transactions__Manual_Adjustments]}>
                <div className='mt-4'>
                    <ButtonManualAdjustment onClick={handleShowManualAdjustment}/>
                </div>
            </PermissionWrapper>
        </div>
    );
}

const StatisticsSubBlock = ({player, bonusAmount, freeSpinsAmount}) => {
    const {i18n} = useTranslation();
    const netProfit = player?.totalDeposits - player?.totalWithdrawal;
    const { adjustAmountWithUserCurrency } = useUserConfig();

    const infoSchema = [
        {
            label: i18n.t('crm.deposits'),
            value: adjustAmountWithUserCurrency(player?.totalDeposits)
        },
        {
            label: i18n.t('crm.withdrawals'),
            value: adjustAmountWithUserCurrency(player?.totalWithdrawal)
        },
        {
            label: i18n.t('crm.netDeposits'),
            value: adjustAmountWithUserCurrency(netProfit)
        },
        {
            label: i18n.t('crm.total.bonuses'),
            value: adjustAmountWithUserCurrency(bonusAmount)
        },
        {
            label: i18n.t('crm.total.free.spins'),
            value: isNaN(freeSpinsAmount) || freeSpinsAmount === 0
                ? 0
                : freeSpinsAmount
        },
        {
            label: i18n.t('crm.bonus.gross.ratio'),
            value: Formatter.calculateRatio(bonusAmount, player?.totalDeposits)
        },
        {
            label: i18n.t('crm.bonus.net.ratio'),
            value: Formatter.calcNetBonusRatio(bonusAmount, netProfit)
        },
        {
            label: i18n.t('crm.wd.ratio'),
            value: Formatter.calculateRatio(player?.totalWithdrawal, player?.totalDeposits)
        },
        {
            label: i18n.t('crm.net.cash'),
            value: Formatter.calculateRatio(player?.totalDeposits, player?.totalWithdrawal)
        },
    ];

    return (
        <div className="sub-content">
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={coin_1} alt=""/>
                <span className="font-w-500 info__title">{i18n.t('crm.statistics')}</span>
            </div>
            <div className="sub-content__content row">
                {infoSchema.map(({label, value}) =>
                    <div key={label}>
                        <SimpleInfoLine label={label} info={value}/>
                    </div>
                )}
            </div>
            <UserStatistics playerID={player?.id}/>
        </div>
    )
}

const NoteSubBlock = (props) => {
    const {i18n} = useTranslation();

    const {
        player,
        dispatch,
        isDisabled,
        note,
        setNote,
        setIsEditNote,
        isEditNote,
        AINote,
        setAINote,
    } = props;

    const {agentSelf} = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];
    const canEditNote = agentPermissions.includes(SITE_PERMISSION.Player__Edit_Note);

    const blockItem = 'note';

    const alertService = useAlertService();

    const onSave = () => {
        dispatch(updatePlayerNote({
            oid: player.oid,
            dto: {note},
        }));
        setIsEditNote(false);
        alertService.showSuccess(i18n.t('crm.alerts.noteHasBeenEdited'));
        player.note = note;
    };

    const onChange = useCallback(() => change => {
        setNote(change);

        if (isEditNote && change === player.note) {
            setIsEditNote(false);
        } else if (!isEditNote) {
            setIsEditNote(true);
        }
    }, [player.note, isEditNote]);

    const onCancel = () => {
        setNote(player.note || '');
        setIsEditNote(false);
    }

    const onSaveAINote = () => {
        setNote(AINote);
        setIsEditNote(true);
        setAINote('');
    }

    return (
        <>
            <div className="sub-content">
                {process.env.REACT_APP_IS_SHOW_AI_BUTTON === "true" && (
                  <>
                      <Summarise
                        playerOid={player?.oid}
                        setAINote={setAINote}
                      />
                      <div id="summariseOutput"></div>
                  </>
                )}


                {AINote !== '' && (
                  <div className="d-flex w-100 justify-content-center">
                      <button
                        className="btn btn-rounded btn-primary saveAINote"
                        onClick={onSaveAINote}>
                          {i18n.t("crm.copyAINote")}
                      </button>
                  </div>
                )}

                <div className="sub-content__header d-flex align-items-center">
                    <img className="me-3" src={group} alt="" />
                    <span className="font-w-500 info__title">{i18n.t("crm.playerNote")}</span>
                </div>

                <Col key={blockItem}>
                    <ACommonDataField
                      multiline={true}
                      fulWidth={true}
                      id={blockItem}
                      component={A_COMMON_DATA_FIELD.TEXT}
                      label={i18n.t(`crm.${blockItem}`)}
                      value={note}
                      handleChange={onChange(blockItem)}
                      disabled={!canEditNote}
                    />
                </Col>
                {isEditNote && (
                  <div className="note-save-buttons">
                      <ButtonsPanel
                        onSave={onSave}
                        onCancel={onCancel}
                        isDisabled={isDisabled}
                      />
                  </div>
                )}
            </div>
        </>
    );
}

function MainCard({
                      playerModel,
                      setPlayerModel,
                      onToggleDropdown = () => {
                      },
                      ...otherProps
                  }) {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const params = useParams();
    const {isLoading, playerGeneral, error, isStatusUpdated, currency: generalCurrency} =
        useSelector(state => state.PlayerGeneral);

    const {verification} = useSelector(state => state.PlayerVerification);

    const [note, setNote] = useState(playerGeneral?.note || '');
    const [AINote, setAINote] = useState('');

    const [isEditNote, setIsEditNote] = useState(false);

    const coolOfReopenTime = playerGeneral?.accountReopenTime;
    const isPEPUser = verification?.pepInfo?.isPep;

    useEffect(() => {
        handleChangePath(otherProps);
    }, []);

    const {
        activePromotionsList,
    } = useSelector(state => state.PlayerPromotions);

    const {agentSelf} = useSelector(
        state => state.AgentSelf
    );

    const {
        isLoading: isLoadingManualAdjustment,
        error: errorManualAdjustment,
        successAlert
    } = useSelector(state => state.PlayerManualAdjustment);
    const {isPlayerDropdownCollapsed} = useSelector(state => state.Layout);
    const { active: activeBrand } = useSelector((state) => state.Brand);

    useEffect(() => {
        setAINote('');
    }, [isPlayerDropdownCollapsed]);

    const isShowMultiWallet = useMemo(() => {
        return activeBrand?.siteName === SITE_BRAND.SPINOLOCO || activeBrand?.siteName === SITE_BRAND.LUCKYSTAKE
    }, [activeBrand?.siteName])

    const isPhoneExtension = agentSelf?.phoneExtension ?? false;
    const canUseClick2Call = agentSelf?.permissions.includes('Player__Call_Phone');

    const [isDisabledCallView, setIsDisabledCallView] = useState(false);
    const [hexColor, setHexColor] = useState();
    const [showColorBlock, setShowColorBlock] = useState(false);
    const [showCallDialog, setShowCallDialog] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showNoteId, setShowNoteId] = useState(false);
    const [fieldErrorsManualAdjustment, setFieldErrorsManualAdjustment] = useState({});
    const [vipLevel, setVipLevel] = useState(null);
    const { getVipLevelBrandName, vipBadgeColors } = useVipLevelMapping();

    const [
        isShowManualAdjustment,
        handleShowManualAdjustment,
        handleHideManualAdjustment
    ] = useToggleModal(false);
    const [isShowChangeStatus, handleShowChangeStatus, handleHideChangeStatus] =
        useToggleModal(false);

    const switchNotes = () => setShowNotes(!showNotes);

    const switchChat = () => setShowChat(!showChat);

    const handleSetEdit = useCallback(nextIsEdit => {
        dispatch(setPlayerGeneralEdit(nextIsEdit));
    }, []);

    const handleChangeStatusSave = useCallback(
        dto => {
            dispatch(updatePlayerStatus({id: playerGeneral.oid, dto}));
        },
        [playerGeneral]
    );

    useEffect(() => {
        dispatch(getAgentSelf());
    }, []);

    useEffect(() => {
        setVipLevel(playerModel?.vipLevel);
    }, [playerModel?.vipLevel])

    useEffect(() => {
        if (activePromotionsList) {
            let bonusAmount = 0;
            let freeSpinsAmount = 0;
            activePromotionsList.forEach((promo) => {
                const {amountValue} = preparePlayerPromoAmount(promo, generalCurrency);
                if (promo?.promotion?.settings?.type === PROMOTION_TYPE.FREE_SPINS) {
                    freeSpinsAmount += amountValue;
                } else {
                    bonusAmount += amountValue;
                }
            })
        }
    }, [activePromotionsList]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetManualAdjustmentAlertsErrors());
            dispatch(getPlayerGeneral(params.id));
            handleHideManualAdjustment();
        }
    }, [successAlert]);

    useEffect(() => {
        if (isStatusUpdated) {
            alertService.showSuccess(i18n.t('crm.alerts.statusChanged'));
            dispatch(getPlayerGeneral(params.id));
        }
    }, [isStatusUpdated]);

    useEffect(() => {
        if (!errorManualAdjustment) {
            return;
        }
        if (
            errorManualAdjustment.statusCode === 400 ||
            errorManualAdjustment.statusCode === 422
        ) {
            setFieldErrorsManualAdjustment(
                prepareFieldErrors(errorManualAdjustment?.data?.errors)
            );
            alertService.showError(errorManualAdjustment?.message);
        } else if (errorManualAdjustment.statusCode === 405) {
            alertService.showError('Insufficient Balance');
            handleHideManualAdjustment();
        } else {
            alertService.showError(errorManualAdjustment?.message);
            handleHideManualAdjustment();
        }
        dispatch(resetManualAdjustmentAlertsErrors());
    }, [errorManualAdjustment]);
    const handleCreateManualAdjustment = useCallback(dto => {
        setFieldErrorsManualAdjustment({});
        dispatch(createManualAdjustment(dto));
    }, []);
    const queryParams = queryString.parse(location.search);
    useEffect(() => {
        if (queryParams.popup === 'notes') {
            setShowNotes(true);
            setShowNoteId(queryParams.popupId);
        }
    }, [queryParams.popupId]);
    const colorPicker = document.getElementsByClassName(
        'block-picker custom-block-picker'
    );
    useEffect(() => {
        setHexColor(playerGeneral?.color);
        if (!colorPicker || colorPicker.length === 0) {
            return;
        }
    }, [showColorBlock, colorPicker]);
    const updatePlayerColor = async () => {
        if (hexColor === playerGeneral.color) {
            return;
        }
        const url = `/players/oid/${playerGeneral.oid}/general`;
        try {
            const result = await patchSync(alertService, url, {
                color: hexColor
            });
            if (result.accountType) {
                alertService.showSuccess('Color updated');
                dispatch(getPlayerGeneral(params.id));
            }
        } catch (e) {
            alertService.showError('Cannot update color');
        }
    };
    useEffect(() => {
        handleHideChangeStatus();
        setHexColor(undefined);
    }, [playerGeneral]);

    const onDropdownClick = () => {
        dispatch(showPlayerDropdown(!isPlayerDropdownCollapsed));
        onToggleDropdown();
    }

    const getPhoneNumberForCall = async () => {
        const url = `/players/oid/${playerGeneral.oid}/call/phone`;
        try {
            const result = await getSync(url, {});
            if (result?.data?.phone) {
                return result.data;
            } else {
                alertService.showError('Cannot get phone number');
                return false;
            }
        } catch (e) {
            alertService.showError(e.message);
            return false;
        }
    };

    const onCallClick = useCallback(async () => {
        setIsDisabledCallView(true);
        try {
            const result = await getPhoneNumberForCall();
            if (!result.phone) {
                alertService.showError('No phone number');
                return;
            }
            if (!result.type) {
                alertService.showError('Your site has no call center configured');
                return;
            }
            const phoneUrlCall = result.phone?.replace(/\+/g, "");
            switch (result.type) {
                case 'Squaretalk':
                    if (!result?.settings?.accountName) {
                        alertService.showError('[Squaretalk] "accountName" does not exist');
                        return;
                    }
                    const clickToCallURL = `https://${result.settings.accountName}.squaretalk.com:8443/Integration/api_call.php`;
                    const url = `${clickToCallURL}?Mode=Call&Extension=${agentSelf.phoneExtension}&Destination=${phoneUrlCall}`;
                    const config = {
                        method: 'get',
                        url,
                        timeout: 30000
                    }
                    const phoneCallResult = await customApiCall(config);
                    setShowNotes(true);

                    break;
            }
        } catch (e) {
            setIsDisabledCallView(false);
            console.error(e);
        }
    }, [showCallDialog]);


    return (
        <>
            <Card onMouseDown={() => setShowColorBlock(false)}>
                {showColorBlock && (
                    <>
                        <Block
                            className={'custom-block-picker'}
                            width={150}
                            triangle="hide"
                            hex={hexColor || playerGeneral.color}
                            colors={PLAYERS_COLORS.map(c => c.value)}
                            onChange={color => {
                                setHexColor(color?.hex);
                            }}
                        />
                        <span
                            className={clsx('custom-block-picker-save')}
                            role="button"
                            onClick={updatePlayerColor}
                        >
                            <i className="mdi mdi-content-save"/>
                            &nbsp;Save
                        </span>
                    </>
                )}
                <CardBody className="user-general__main-card">
                    <div className="d-flex justify-content-between main-card__header flex-wrap">
                        <div className="d-flex align-items-center flex-wrap main-card__header__outer">
                            {showNotes && (
                                <NotesPopup
                                    onClose={switchNotes}
                                    showNoteId={showNoteId}
                                    playerOid={playerGeneral.oid}
                                />
                            )}
                            {showChat && <ChatPopup playerGeneral={playerGeneral} onClose={switchChat}/>}

                            <div className="main-card__header__row">
                                <div
                                    className="flag-div"
                                    style={{
                                        backgroundColor: `'${playerGeneral.color}'`,
                                        boxShadow: `0px 0px 0px 3px ${playerGeneral.color}`,
                                        borderRadius: '50%'
                                    }}
                                >
                                    <FlagCountry
                                        countryCode={
                                            [playerGeneral?.address?.addressCountryAlfa2, playerGeneral?.citizenship]
                                        }
                                        className="flag-wrapper"
                                    />
                                </div>
                                <span className="me-3">
                                    <span
                                        style={{cursor: 'default'}}
                                        className="profile-name"
                                    >
                                        {playerGeneral.firstName}&nbsp;
                                        {playerGeneral.lastName}
                                    </span>
                                    {playerGeneral.isTest && (
                                        <>
                                            &nbsp;&nbsp;
                                            <span className="badge badge-soft-gray">
                                                {i18n.t('crm.test')}
                                            </span>
                                        </>
                                    )}
                                    <AKYCField kyc={playerGeneral.kyc}/>
                                </span>
                            </div>
                            
                            <div className="main-card__header__row">
                                {
                                    playerGeneral?.id &&
                                    <span className="me-2">
                                        <span className="me-2 main-card__header__id">ID {getGuidShortFormat(playerGeneral.id)} </span>
                                        <ClipboardButton text={playerGeneral.id}/>
                                    </span>
                                }
                                <span className="me-3">
                                    <span className="main-card__header__id">No. {playerGeneral.oid}</span>
                                    &nbsp;&nbsp;
                                    <ClipboardButton text={playerGeneral.oid}/>
                                </span>
                            </div>

                            <div className="main-card__header__row">
                                <span className="me-3">
                                    <NotesView onClick={switchNotes}/>
                                </span>
                                <span className={clsx("me-3", {
                                    "disable-phone-ext": !isPhoneExtension,
                                    "d-none": !canUseClick2Call,
                                })}
                                >
                                    <CallView onClick={onCallClick} isDisabled={!isDisabledCallView}/>
                                </span>
                                {vipBadgeColors[vipLevel] && (
                                  <span className="me-3">
                                    <span className="player__status"
                                          style={{ backgroundColor: vipBadgeColors[vipLevel] }}>
                                        {getVipLevelBrandName(vipLevel)}
                                    </span>
                                </span>
                                )}
                            </div>

                        </div>
                        <div className="main-card__header__status">
                            {isPEPUser &&
                                <span
                                    className="badge badge-soft-danger mr-8"
                                    style={{padding: '6px', width: '40px'}}
                                >
                                    {i18n.t('crm.pep')}
                                </span>
                            }
                            {isShowMultiWallet && (
                              <MultiWalletSelect />
                            )}
                            <div className='d-flex align-items-center'>
                                {coolOfReopenTime && (
                                  <CoolOffTimer coolOfReopenTime={coolOfReopenTime}/>
                                )}
                                <StatusView
                                  status={playerGeneral.status}
                                  onClick={handleShowChangeStatus}
                                />
                            </div>
                            {isShowChangeStatus && (
                                <StatusChangeModal
                                    status={playerGeneral.status}
                                    error={error}
                                    isLoading={isLoading}
                                    onHide={handleHideChangeStatus}
                                    onConfirm={handleChangeStatusSave}
                                />
                            )}
                        </div>
                    </div>
                    {isPlayerDropdownCollapsed &&
                        <>
                            <hr/>
                            <div className="main-card__content">
                                <InfoSubBlock
                                    player={playerModel}
                                    handleSetEdit={handleSetEdit}
                                    setPlayerModel={setPlayerModel}
                                />
                                <BalancesSubBlock
                                    player={playerGeneral}
                                    handleShowManualAdjustment={
                                        handleShowManualAdjustment
                                    }
                                />
                                <StatisticsSubBlock
                                    player={playerGeneral}
                                    bonusAmount={playerGeneral?.totalBonuses}
                                    freeSpinsAmount={playerGeneral?.totalFreeSpins}
                                />
                                <NoteSubBlock
                                    player={playerModel}
                                    dispatch={dispatch}
                                    isDisabled={isLoading}
                                    note={note}
                                    setNote={setNote}
                                    setIsEditNote={setIsEditNote}
                                    isEditNote={isEditNote}
                                    AINote={AINote}
                                    setAINote={setAINote}
                                />
                            </div>
                        </>
                    }
                    <button
                        className={clsx("dropdownBtn", {
                            showDropdown: isPlayerDropdownCollapsed,
                        })}
                        onClick={onDropdownClick}
                    />
                </CardBody>
            </Card>
            {isShowManualAdjustment && (
                <ManualAdjustmentModal
                    playerId={playerGeneral.id}
                    onHide={handleHideManualAdjustment}
                    onConfirm={handleCreateManualAdjustment}
                    fieldErrors={fieldErrorsManualAdjustment}
                    isLoading={isLoadingManualAdjustment}
                />
            )}
        </>
    );
}

export default React.memo(withRouter(MainCard));
