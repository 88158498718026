export function randomColor() {
    return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export function shortId() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export const shortText = (text) => {
    const maxLength = 16;
    const DOTS = '...';

    if (text?.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + DOTS;
    }

}

export const DataTypes = Object.freeze({
    NUMBER: 'number',
    TEXT: 'text',
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    EDIT: 'edit',
});

const brands = ['OBR_EU',
    'LiquidityX_EU',
    'PatronFX_EU',
    'InvestMarkets_INT',
    'Investous_EU',
    'Investous_INT',
    'ForexTB_EU'];


const Countries_Languages = ['AF', 'SQ', 'AR', 'EU', 'BE', 'BG', 'CA', 'HR', 'CS', 'DA', 'NL', 'EN', 'ET', 'FO', 'FA', 'FI', 'FR', 'GD', 'DE', 'EL', 'HE', 'HI', 'HU', 'IS', 'ID', 'GA', 'IT', 'JA', 'KO', 'KU', 'LV', 'LT', 'MK', 'ML', 'MS', 'MT', 'NO', 'NB', 'NN', 'PL', 'PT', 'PA', 'RO', 'RU', 'SR', 'SK', 'SL', 'SB', 'ES', 'SV', 'TH', 'TS', 'TN', 'TR', 'UK', 'UR', 'VE', 'VI', 'CY', 'XH', 'JI', 'ZU'];

export const statues = [
    {
        label: 'Active',
        backgroundColor: 'hsla(206, 100%, 64%, 1)'
    },
    {
        label: 'Blocked',
        backgroundColor: 'hsla(14, 100%, 64%, 1)'
    },
    {
        label: 'Inactive',
        backgroundColor: 'hsla(0, 100%, 64%, 1)'
    },
    {
        label: 'Migrated',
        backgroundColor: 'hsla(75, 100%, 64%, 1)'
    }
];


const permissions = [
    {
        label: 'Global',
        backgroundColor: 'hsla(206, 100%, 64%, 1)'
    },
    {
        label: 'Office',
        backgroundColor: 'hsla(14, 100%, 64%, 1)'
    },
    {
        label: 'Local',
        backgroundColor: 'hsla(0, 100%, 64%, 1)'
    },
    {
        label: 'Basic',
        backgroundColor: 'hsla(75, 100%, 64%, 1)'
    }
];

const rolesColors = [
    'hsla(771, 100%, 64%, 1)',
    'hsla(695, 100%, 64%, 1)',
    'hsla(15, 100%, 64%, 1)',
    'hsla(25, 100%, 64%, 1)',
    'hsla(35, 100%, 64%, 1)',
    'hsla(45, 100%, 64%, 1)',
    'hsla(55, 100%, 64%, 1)',
    'hsla(65, 100%, 64%, 1)',
    'hsla(75, 100%, 64%, 1)',
    'hsla(85, 100%, 64%, 1)',
    'hsla(25, 100%, 64%, 1)',
    'hsla(205, 100%, 64%, 1)',
    'hsla(1005, 100%, 64%, 1)',
    'hsla(771, 100%, 64%, 1)',
    'hsla(695, 100%, 64%, 1)'
];

const generateColor = (index) => {
    let _index = index;
    while (!rolesColors[_index]) {
        _index = _index - rolesColors.length;
    }
    return rolesColors[_index];
};

const generateRandomList = (list) => {
    list = list ? list : [];
    return list.map((v, index) => {
        return {
            label: v,
            backgroundColor: generateColor(index)
        };
    });
}

export const agentBrandColumns = (rolesList, brandNames, t, canChangePasswordPermission, canAgentRegisterPermission) => {
    rolesList = generateRandomList(rolesList?.map((v) => v.name));
    const brandOptions = brandNames.map((siteName) => ({
        label: siteName,
        backgroundColor: 'hsla(206, 100%, 64%, 1)'
    }));
    const columns = [
        {
            minWidth: 120,
            width: 120,
            id: 'id',
            label: t("filter.constant.id"),
            accessor: 'id',
            dataType: DataTypes.TEXT,
            isFilter: true,
            enableSorting: true
        },
        {
            minWidth: 100,
            width: 100,
            id: 'oid',
            label: t("filter.constant.oid"),
            accessor: 'oid',
            dataType: DataTypes.TEXT,
            isFilter: true,
            enableSorting: true
        },
        {
            minWidth: 120,
            width: 120,
            id: 'firstName',
            label: t("filter.constant.first_name"),
            accessor: 'firstName',
            dataType: DataTypes.TEXT,
            isFilter: true,
            enableSorting: true
        },
        {
            minWidth: 130,
            width: 130,
            id: 'lastName',
            label: t("filter.constant.last_name"),
            accessor: 'lastName',
            dataType: DataTypes.TEXT,
            isFilter: true,
            enableSorting: true
        },
        {
            minWidth: 300,
            width: 300,
            id: 'email',
            label: t("agent.email"),
            accessor: 'email',
            dataType: DataTypes.TEXT,
            isFilter: true,
            enableSorting: true
        },
        {
            minWidth: 100,
            width: 100,
            id: 'status',
            label: t("crm.status"),
            accessor: 'status',
            dataType: DataTypes.SELECT,
            options: statues,
            enableSorting: true
        },
        {
            minWidth: 100,
            width: 100,
            id: 'role',
            label: t("agent.roleId"),
            accessor: 'role',
            dataType: DataTypes.SELECT,
            options: rolesList,
            enableSorting: true
        },
        {
            minWidth: 220,
            width: 220,
            id: 'brands',
            label: t("crm.brandList"),
            accessor: 'brands',
            dataType: DataTypes.MULTISELECT,
            options: brandOptions,
            enableSorting: true
        },
        {
            minWidth: 120,
            width: 120,
            id: 'manager',
            label: t("crm.manager"),
            accessor: 'manager',
            dataType: DataTypes.TEXT,
            isFilter: true,
        },
        {
            minWidth: 150,
            width: 150,
            id: 'department',
            label: t("crm.department"),
            accessor: 'department',
            dataType: DataTypes.TEXT,
            enableSorting: true
        },
        {
            minWidth: 168,
            width: 168,
            id: 'assignedPlayers',
            label: t("agent.assignedPlayers"),
            accessor: 'assignedPlayers',
            dataType: DataTypes.NUMBER,
            enableSorting: true
        },
        {
            minWidth: 170,
            width: 170,
            id: 'phoneExtension',
            label: t("agent.phoneExtension"),
            accessor: 'phoneExtension',
            dataType: DataTypes.TEXT,
            enableSorting: true
        },
    ];

    if (canAgentRegisterPermission) {
        columns.push({
            minWidth: 50,
            width: 50,
            id: 'edit',
            label: t("common.edit"),
            dataType: DataTypes.EDIT,
        });
    }

    if (canChangePasswordPermission) {
        columns.push({
            minWidth: 150,
            width: 150,
            id: 'changePassword',
            label: t("common.changePassword"),
            dataType: DataTypes.EDIT,
        });
    }

    return columns;
};

export function makeData(columns, response) {
    let data = [];
    for (let i = 0; i < response?.result?.length; i++) {
        const _response = response.result[i];
        let row = {
            createdAt: _response.createdAt,
            brands: _response.brands,
            firstName: _response.firstName,
            lastName: _response.lastName,
            id: _response.id,
            oid: _response.oid || '',
            manager: _response?.manager?.name || '',
            managerId: _response?.manager?.id || '',
            department: _response.department,
            role: _response.role,
            email: _response.email,
            status: _response.status,
            assignedPlayers: _response.assignedPlayers,
            phoneExtension: _response.phoneExtension,
        };
        data.push(row);
    }

    // sort by createdAt, manager id
    data.sort((a1,a2) => a1.manager?.id > a2.manager?.id ? a1 : a2);
    data.sort((a1,a2) => a1.createdAt > a2.createdAt ? a1 : a2);
    return { columns: columns, data: data, skipReset: false };
}

export const ActionTypes = Object.freeze({
    ADD_OPTION_TO_COLUMN: 'add_option_to_column',
    SET_COLUMNS: 'set_columns',
    ADD_ROWS: 'add_rows',
    ADD_ROW: 'add_row',
    UPDATE_COLUMN_TYPE: 'update_column_type',
    UPDATE_COLUMN_HEADER: 'update_column_header',
    UPDATE_CELL: 'update_cell',
    ADD_COLUMN_TO_LEFT: 'add_column_to_left',
    ADD_COLUMN_TO_RIGHT: 'add_column_to_right',
    DELETE_COLUMN: 'delete_column',
    ENABLE_RESET: 'enable_reset',
    CANCEL_CHANGES: 'cancel_changes'
});

export const bytesToMegaBytes = (size) => (
    (size / 1000000).toFixed(2)
);
