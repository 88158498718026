import React, { useEffect, useMemo, useState } from 'react';
import Formatter from '../../../utils/Formatter';
import NewCustomTable from '../../../components/NewCustomTable';
import PropTypes from 'prop-types';
import CheckboxInput from '../../../common/inputs/common/CheckboxInput';
import useTableCheckbox from '../../../components/NewCustomTable/useTableCheckbox';
import { getProductName } from "../../../utils/products"
import { DIRECTION } from "../../../constants/layout";
import { useTranslation } from "react-i18next";
import Loader from "../../../common/components/Loader";
import { getColumnSettingsLsKey, initTableColumns } from "../../../common/utils/common";
import { useSelector } from "react-redux";
import { SITE_BRAND } from "../../../constants/siteBrands";
import { useTimeContext } from "../../../context/TimeContext";
import clsx from "clsx";
import { IdCell, PlayerCell } from "../../../components/NewCustomTable/CellsWithPermissions";
import { withRouter } from "react-router-dom";
import TableSettingsModal from "../modal/TableSettingsModal";
import i18n from "../../../i18n";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import { COLUMN_IDS, getDefaultColumns } from "../../../constants/tableSettings";
import { ACCOUNT_STATUS, DEPOSITOR_STATUS} from "../../../constants/status";


export const PLAYER_LIST_SORTED_FIELDS = {
    registrationTime: 'created_at',
    oid: 'operation_id',
    player: 'player',
    username: 'nick_name',
    lastLogin: 'player_last_session_time',
    status: 'document_verification_status',
    accountStatus: 'account_status',
    gender: 'gender',
    agentOwner: 'agent_owner',
    kyc: 'is_kyc',
    citizenship: 'nationality',
    language: 'language',
    test: 'test',
    isBlockGamePlay: 'is_block_gameplay',
    realBalance: 'real_balance',
};

export const PLAYER_TRACKER_LIST_SORTED_FIELDS = {
    registrationTime: 'created_at',
    id: 'id',
    oid: 'operation_id',
    player: 'player',
    username: 'nick_name',
};

const getAllColumns = (trackerMode, isWinpotBrand = false) => {
    const columns = [
        COLUMN_IDS.registrationTime,
        COLUMN_IDS.id,
        COLUMN_IDS.player,
    ];
    columns.push(
        COLUMN_IDS.accountStatus,
        COLUMN_IDS.username,
        COLUMN_IDS.realBalance,
        COLUMN_IDS.lastLogin,
        COLUMN_IDS.product,
        COLUMN_IDS.agentOwner,
        COLUMN_IDS.ftdDate,
        COLUMN_IDS.ftdAmount,
        COLUMN_IDS.stdDate,
        COLUMN_IDS.stdAmount,
        COLUMN_IDS.ltdDate,
        COLUMN_IDS.ltdAmount,
        COLUMN_IDS.sportBonusBalance,
        COLUMN_IDS.casinoBonusBalance,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.totalWithdrawal,
        COLUMN_IDS.language,
        COLUMN_IDS.affiliateName,
        COLUMN_IDS.depositorStatus
    );

    if (isWinpotBrand) {
        const indexToInsert = columns.findIndex((column) => column === COLUMN_IDS.affiliateName) + 1;
        if (indexToInsert !== -1) {
            columns.splice(indexToInsert, 0, COLUMN_IDS.affiliateSubId);
        }
    }

    return columns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.accountStatus]: 'crm.accountStatus',
    [COLUMN_IDS.registrationTime]: 'crm.createdAt',
    [COLUMN_IDS.id]: 'crm.OIDorID',
    [COLUMN_IDS.player]: 'crm.playerName',
    [COLUMN_IDS.reconnectAfter]: 'crm.reconnectAfter',
    [COLUMN_IDS.sessionTime]: 'crm.sessionTime',
    [COLUMN_IDS.username]: 'crm.username',
    [COLUMN_IDS.realBalance]: 'crm.realBalance',
    [COLUMN_IDS.lastLogin]: 'crm.lastLogin',
    [COLUMN_IDS.product]: 'crm.affiliateProduct',
    [COLUMN_IDS.agentOwner]: 'crm.playerOwnerName',
    [COLUMN_IDS.ftdDate]: 'crm.ftdDate',
    [COLUMN_IDS.ftdAmount]: 'crm.ftdAmount',
    [COLUMN_IDS.stdDate]: 'crm.stdDate',
    [COLUMN_IDS.stdAmount]: 'crm.stdAmount',
    [COLUMN_IDS.ltdDate]: 'crm.ltdDate',
    [COLUMN_IDS.ltdAmount]: 'crm.ltdAmount',
    [COLUMN_IDS.sportBonusBalance]: 'crm.sportBonusBalance',
    [COLUMN_IDS.casinoBonusBalance]: 'crm.casinoBonusBalance',
    [COLUMN_IDS.totalDeposits]: 'crm.totalDeposit',
    [COLUMN_IDS.totalWithdrawal]: 'crm.totalWithdrawal',
    [COLUMN_IDS.language]: 'crm.language',
    [COLUMN_IDS.affiliateName]: 'crm.affiliateAffiliateId',
    [COLUMN_IDS.affiliateSubId]: 'crm.affiliateSubId',
    [COLUMN_IDS.depositorStatus]: 'crm.depositorStatus',
};

const TdElem = ({
    item,
    field,
    timeFormat,
    isChecked = false,
    isTrackerPage = false,
    windowSize,
    handleSelectRow = () => {},
}) => {
    const { t } = useTranslation();
    const getUserDepositStatus = (playerDepositsCount) => {
        const numberOfDeposits = parseInt(playerDepositsCount);
        let status = DEPOSITOR_STATUS.NonDepositor;

        if (!isNaN(numberOfDeposits)) {
            if (numberOfDeposits === 1) {
                status = DEPOSITOR_STATUS.OneTimeDepositor;
            } else if (numberOfDeposits > 1) {
                status = DEPOSITOR_STATUS.MultiDepositor;
            }
        }

        return t(`crm.${status}`);
    };


    switch (field) {
        case COLUMN_IDS.tdForCheckBox:
            return windowSize > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                <td className={'tdForCheckBox'}>
                    <CheckboxInput
                        checked={isChecked}
                        onChange={handleSelectRow(item.userId)}
                        wrapperClassName={'wrapperCheckBok'}
                    />
                </td>
            )
        case COLUMN_IDS.registrationTime:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.createdAt')}: </span>}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.registered))}
            </td>;
        case COLUMN_IDS.id:
            return <td
                className={
                    'text-center player-td left-td cursor-pointer justify-content-center text-nowrap player-id'
                }
            >
                <IdCell
                    item={item}
                    url={{
                        type: isTrackerPage ? 'tracker' : 'list',
                        id: item.id
                    }}
                />
            </td>;
        case COLUMN_IDS.player:
            return <td
                className={
                    'text-center player-td left-td cursor-pointer justify-content-center text-nowrap player-user-data-td'
                }
                style={{ overflow: 'visible' }}
            >
                <PlayerCell
                    item={item}
                    url={{
                        type: isTrackerPage ? 'tracker' : 'list',
                        id: item.id
                    }}
                />
            </td>;

        case COLUMN_IDS.accountStatus:
            return <td className={clsx('accountStatus', {
                    Active: item.status === ACCOUNT_STATUS.Active,
                    Blocked: item.status === ACCOUNT_STATUS.Blocked,
                    Frozen: item.status === ACCOUNT_STATUS.Frozen,
                    SoftBlock: item.status === ACCOUNT_STATUS.SoftBlock,
                    SelfClosed: item.status === ACCOUNT_STATUS.SelfClosed,
                    Lead: item.status === ACCOUNT_STATUS.Lead,
                    CompleteProfile: item.status === ACCOUNT_STATUS.CompleteProfile,
                    Closed: item.status === ACCOUNT_STATUS.Closed,
                })}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.accountStatus')}: </span>}
                {item.status}
            </td>
        case COLUMN_IDS.reconnectAfter:
            return <td>
                {item.reconnectAfter}
            </td>;
        case COLUMN_IDS.sessionTime:
            return <td>
                {item.sessionTime}
            </td>;
        case COLUMN_IDS.username:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.username')}: </span>}
                {item.username?.slice(0, 20)}
            </td>;
        case COLUMN_IDS.realBalance:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.realBalance')}: </span>}
                {item.realBalance
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.realBalance), item.currency)
                    : '—'
                }
            </td>;
        case COLUMN_IDS.lastLogin:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.lastLogin')}: </span>}
                {item.lastLogin && (item.lastLogin !== '-')
                    ? Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.lastLogin))
                    : '—'
                }
            </td>;
        case COLUMN_IDS.product:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.affiliateProduct')}: </span>}
                {getProductName(item?.affiliateProduct) || "—"}
            </td>;
        case COLUMN_IDS.agentOwner:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.agentOwner')}: </span>}
                {item.agentOwner
                    ? item.agentOwner
                    : '—'}
            </td>;
        case COLUMN_IDS.ftdDate:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.ftdDate')}: </span>}
                {item.ftdDate
                    ? Formatter.displayDate(timeFormat.parseTime(item.ftdDate))
                    : '—'
                }
            </td>;
        case COLUMN_IDS.ftdAmount:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.ftdAmount')}: </span>}
                {item.ftdAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.ftdAmount),
                        item.currency
                    )
                    : '—'
                }
            </td>;
        case COLUMN_IDS.stdDate:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.stdDate')}: </span>}
                {item.stdDate
                    ? Formatter.displayDate(timeFormat.parseTime(item.stdDate))
                    : '—'}
            </td>;
        case COLUMN_IDS.stdAmount:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.stdAmount')}: </span>}
                {item.stdAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.stdAmount),
                        item.currency
                    )
                    : '—'
                }
            </td>;
        case COLUMN_IDS.ltdDate:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.ltdDate')}: </span>}
                {item.ltdDate
                    ? Formatter.displayDate(timeFormat.parseTime(item.ltdDate))
                    : '-'
                }
            </td>;
        case COLUMN_IDS.ltdAmount:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.ltdAmount')}: </span>}
                {item.ltdAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.ltdAmount),
                        item.currency
                    )
                    : '—'
                }
            </td>;
        case COLUMN_IDS.sportBonusBalance:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.sportBonusBalance')}: </span>}
                {item.sportBonusBalance
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.sportBonusBalance),
                        item.currency
                    )
                    : '—'
                }
            </td>;
        case COLUMN_IDS.casinoBonusBalance:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.casinoBonusBalance')}: </span>}
                {item.casinoBonusBalance
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.casinoBonusBalance),
                        item.currency
                    )
                    : '—'
                }
            </td>;
        case COLUMN_IDS.totalDeposits:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.deposits')}: </span>}
                {`${
                item?.paymentInfo?.numberOfDeposits || '—'
            } / ${item?.paymentInfo?.totalDeposits
                ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item?.paymentInfo?.totalDeposits),
                        item.currency
                )
                : '—'
            }`}</td>;
        case COLUMN_IDS.totalWithdrawal:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.withdrawals')}: </span>}
                {`${
                item?.paymentInfo?.numberOfWithdrawals || '—'
            } / ${item?.paymentInfo?.totalWithdrawal
                ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item?.paymentInfo?.totalWithdrawal),
                        item.currency
                )
                : '—'
            }`}</td>;
        case COLUMN_IDS.language:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.language')}: </span>}
                {item.language}
            </td>;
        case COLUMN_IDS.affiliateName:
            return <td>
                {windowSize <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && <span>{i18n.t('crm.affiliateAffiliateId')}: </span>}
                {item?.affiliateName || "—"}
            </td>;
        case COLUMN_IDS.affiliateSubId:
            return <td>
                {item.affiliateSubId || '—'}
            </td>;
        case COLUMN_IDS.depositorStatus:
            return <td>
                {getUserDepositStatus(item?.paymentInfo?.numberOfDeposits)}
            </td>;
        default:
            return null;
    }
};

function PlayersTable({
    isLoading,
    playerList,
    selectedRows,
    setSelectedRows,
    onSortPlayers,
    trackerMode,
    match,
    isShowTableSettings,
    onCloseTableSettings,
}) {
    const { i18n } = useTranslation();
    const size = useWindowSize();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const { handleSelectAll, handleSelectRow } = useTableCheckbox(
        playerList ? playerList.map(item => item.userId) : [],
        selectedRows,
        setSelectedRows
    );

    const initialAllColumns = useMemo(() => getAllColumns(trackerMode), []);
    
    const [isResponsive, setIsResponsive] = useState(size?.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT);
    const [isWinpotBrand, setIsWinpotBrand] = useState(false);
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(initialAllColumns);
    const [brandActiveColumns, setBrandActiveColumns] = useState([...activeColumns]);
    const [allColumns, setAllColumns] = useState(initialAllColumns);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const { timeFormat } = useTimeContext();
    const [activeRow, setActiveRow] = useState(false);

    useEffect(() => {
        const defaultColumns = getDefaultColumns(trackerMode ? 'tracker' : 'playerList', initialAllColumns);
        setDefaultColumns(defaultColumns);
        initTableColumns(match.path, () => defaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const defaultColumns = getDefaultColumns(trackerMode ? 'tracker' : 'playerList', allColumns);
        setDefaultColumns(defaultColumns);
    }, [trackerMode, allColumns]);

    useEffect(() => {
        setIsResponsive(size?.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT);
    }, [size]);

    useEffect(() => {
        setIsWinpotBrand(activeBrand?.siteName === SITE_BRAND.WINPOTMX);
    }, [activeBrand?.siteName]);

    useEffect(() => {
        if (isWinpotBrand) {
            setBrandActiveColumns([...activeColumns]);
        }
        else {
            setBrandActiveColumns(activeColumns.filter((column) => column !== COLUMN_IDS.affiliateSubId));
        }
    }, [isWinpotBrand, activeColumns]);

    useEffect(() => {
        const allColumns = getAllColumns(trackerMode, isWinpotBrand);
        const defaultColumns = getDefaultColumns(trackerMode ? 'tracker' : 'playerList', allColumns);

        setAllColumns(allColumns);
        setDefaultColumns(defaultColumns);
    }, [trackerMode, isWinpotBrand]);

    useEffect(() => {
        const columnsData = [
            {
                custom: (
                    <th className={'thForCheckBox'}>
                        <CheckboxInput
                            checked={
                                playerList?.length === selectedRows?.length
                            }
                            onChange={handleSelectAll}
                            wrapperClassName={'wrapperCheckBok'}
                        />
                    </th>
                )
            },
            ...brandActiveColumns.map((key) => ({
                label: i18n.t(KEY_I18N_MAP[COLUMN_IDS[key]]),
                id: COLUMN_IDS[key],
            })),
        ];

        const currListSortedFields = trackerMode && PLAYER_TRACKER_LIST_SORTED_FIELDS || PLAYER_LIST_SORTED_FIELDS;
        columnsData
            .filter((column) => column.id in currListSortedFields)
            .forEach((column) => column.sorted = true);

        setColumns(columnsData);
    }, [playerList, selectedRows, isWinpotBrand, timeFormat, i18n.language, brandActiveColumns]);

    const isTrackerPage = window.location.pathname.includes('/tracker');
    const isShortTable = playerList && playerList.length <= 10;

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <NewCustomTable
                columns={columns}
                isHeader={true}
                className={'player-table player-table-common'}
                isLoading={isLoading}
                isResponsive={isResponsive}
                onSortItems={onSortPlayers}
                defaultSortColumn={columns[1]}
                defaultOrder={DIRECTION.DESC}
                sortAfterMount={false}
                playerList={playerList}
            >
                {isLoading && !playerList ?  (
                    <tr className='player-list-loader'>
                        <td colSpan={columns.length}>
                            <Loader />
                        </td>
                    </tr>
                ) : (
                    playerList?.map((item, index) => {
                        const isChecked = selectedRows.includes(item.userId);

                        return (
                            <tr
                                key={index}
                                className={clsx('font-w-500', {
                                    checked: isChecked,
                                    fullTableHeight: isShortTable,
                                    open: activeRow === index
                                })}
                                onClick={() => handleToggle(index)}
                            >
                                <TdElem
                                    key={`tdForCheckBox_${index}`}
                                    item={item}
                                    field={COLUMN_IDS.tdForCheckBox}
                                    timeFormat={timeFormat}
                                    isChecked={isChecked}
                                    isTrackerPage={isTrackerPage}
                                    handleSelectRow={handleSelectRow}
                                    windowSize={size?.width}
                                />
                                {brandActiveColumns.map((column) =>
                                    <TdElem
                                        key={`${column}_${index}`}
                                        item={item}
                                        field={column}
                                        timeFormat={timeFormat}
                                        isTrackerPage={isTrackerPage}
                                        windowSize={size?.width}
                                    />
                                )}
                            </tr>
                        );
                    }))}
            </NewCustomTable>
            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={brandActiveColumns}
                    defaultColumns={defaultColumns}
                    allColumns={allColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    );
}

PlayersTable.propTypes = {
    isLoading: PropTypes.bool,
    playerList: PropTypes.array,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    trackerMode: PropTypes.bool,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
};

PlayersTable.defaultProps = {
    trackerMode: false,
    onCloseTableSettings: () => {},
};

export default React.memo(withRouter(PlayersTable));
