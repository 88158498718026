import { get, getFile, getSync, patch, post } from "./api_helper"
import { changeFilterConditionKeys, prepareFilterSearch, prepareSearch } from './SearchUtils';

const getPlayerSelectListApi = () => {
    const url = `/players/list`;
    return get(url);
};

const getPlayerListApi = query => {
    const url = `/players?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerKYCListApi = query => {
    const url = `/players/kyc?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerKYCCountApi = query => {
    const url = `/players/kyc-count?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerTrackerListApi = ({netStatus, query}) => {
    const url = `/players/tracker/${netStatus}?${prepareFilterSearch(query)}`;
    return get(url);
};

const searchPlayersApi = query => {
    const url = `/players/search?${prepareFilterSearch(query)}`;
    return get(url);
};

const updatePlayersApi = dto => {
    const url = `/players`;
    return patch(url, dto);
};

const updatePlayerIdentityInfoApi = ({ playerOid, docOid, dto }) => {
    const url = `/players/oid/${playerOid}/${docOid}/update/identity-info`;
    return patch(url, dto);
};

const getPlayerJointApi = id => {
    const url = `/players/oid/${id}/joint`;
    return get(url);
};

const getPlayerReferralsApi = oid => {
    const url = `/players/oid/${oid}/referral_dashboard`;
    return get(url);
}

const updatePlayerJointApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/joint`;
    return patch(url, dto);
};

const getPlayerGeneralApi = id => {
    const url = `/players/oid/${id}/general`;
    return get(url);
};

const updatePlayerGeneralApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/general`;
    return patch(url, dto);
};

const updatePlayerAffiliateInfoApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/affiliate-info`;
    return patch(url, dto);
};

const updatePlayerStatusApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/status`;
    return patch(url, dto);
};

const getPlayerDepositListApi = ({ id, query }) => {
    const url = `/players/oid/${id}/deposits?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerWithdrawalListApi = ({ id, query }) => {
    const url = `/players/oid/${id}/withdrawals?${prepareFilterSearch(query)}`;
    return get(url);
};

const downloadPlayerDepositListApi = ({ id, query }) => {
    const url = `/players/oid/${id}/deposit/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const downloadPlayerWithdrawalListApi = ({ id, query }) => {
    const url = `/players/oid/${id}/withdrawal/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const getPlayerCommunicationApi = id => {
    const url = `/players/oid/${id}/communication`;
    return get(url);
};

const updatePlayerCommunicationApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/communication`;
    return patch(url, dto);
};

const sendPlayerCommunicationEmailApi = id => {
    const url = `/players/oid/${id}/communication/email/send-code`;
    return patch(url);
};

const sendPlayerCommunicationPhoneApi = id => {
    const url = `/players/oid/${id}/communication/phone/send-code`;
    return patch(url);
};

const getPlayerCommunicationPhoneListApi = ({ id, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/communication/phone?${preparedQuery}`;
    return get(url);
};

const getPlayerCommunicationEmailListApi = ({ id, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/communication/email?${preparedQuery}`;
    return get(url);
};

const getPlayerPromotionsListApi = ({ id, query }) => {
    changeFilterConditionKeys(query);
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/promotions?${preparedQuery}`;
    return get(url);
};

const getPromotionTemplatesListApi = ({ id }) => {
    const url = `/players/oid/${id}/promotion-templates`;
    return get(url);
};

const applyPlayerPromotionApi = ({ id, promotionId, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/promotions/${promotionId}?${preparedQuery}`;
    return patch(url);
};

const closePlayerPromotionApi = ({ id, playerPromotionId, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/promotions/exit/${playerPromotionId}?${preparedQuery}`;
    return patch(url);
};

const getPlayerSportPromotionsListApi = ({ id, query }) => {
    changeFilterConditionKeys(query);
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/sport-promotions?${preparedQuery}`;
    return get(url);
};

const applyPlayerSportPromotionApi = ({ id, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/sport-promotions?${preparedQuery}`;
    return post(url);
};

const closePlayerSportPromotionApi = ({ id, playerPromotionId, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/sport-promotions/exit/${playerPromotionId}?${preparedQuery}`;
    return patch(url);
};

const getPlayerAuditLoginsListApi = ({ id, query }) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/audit/logins?${preparedQuery}`;
    return get(url);
};

const downloadPlayerAuditLoginsListApi = ({ id, query }) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/audit/logins/excel?${preparedQuery}`;
    return getFile(url);
};

const getPlayerAuditStatusListApi = ({ id, query }) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/audit/statuses?${preparedQuery}`;
    return get(url);
};

const getPlayerAuditChangesListApi = ({ id, query }) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/oid/${id}/audit/changes?${preparedQuery}`;
    return get(url);
};

const getPlayerAuditFiltersApi = ({ id }) => {
    const url = `/players/oid/${id}/audit/filter/changes`;
    return get(url);
};

const getPlayerGamePlayApi = ({ id, query }) => {
    const url = `/players/oid/${id}/game-play?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerGameSessionsApi = ({ id, query }) => {
    const url = `/players/oid/${id}/game-sessions?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerGameRoundsApi = ({ id, query }) => {
    const url = `/players/oid/${id}/game-rounds?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerBetSlipApi = ({ id, query }) => {
    const url = `/players/oid/${id}/bet-slip?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerGameSessionRoundsApi = ({ id, sessionId, query }) => {
    const url = `/players/oid/${id}/${sessionId}/game-session-rounds?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPlayerUniqueGameIdsApi = (id) => {
    const url = `/players/oid/${id}/unique-list-played-games`;
    return get(url);
};

const getPlayerVerificationApi = id => {
    const url = `/players/oid/${id}/verification`;
    return get(url);
};

const updatePlayerVerificationApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/verification`;
    return patch(url, dto);
};

const getPlayerVerificationHistoryListApi = ({ id, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/verification-history?${preparedQuery}`;
    return get(url);
};

const getPlayerSofAgreementApi = ({oid}) => {
    const url = `/players/validate/${oid}/sof-doc`;
    return get(url);
};

const getPlayerSelfExclusionAgreementApi = ({oid}) => {
    const url = `/players/validate/${oid}/self-exclusion-doc`;
    return get(url);
};

const getPlayerAccountRecoveryAgreementApi = ({oid}) => {
    const url = `/players/validate/${oid}/account-recovery-doc`;
    return get(url);
};

const getPlayerOccupationApi = ({ oid, occupation}) => {
    return get(`/players/validate/${oid}/occupation?occupation=${occupation}`)
}

const getPlayerRestrictionsApi = id => {
    const url = `/players/oid/${id}/restrictions`;
    return get(url);
};

const updatePlayerRestrictionsApi = ({ id, dto }) => {
    const url = `/players/oid/${id}/restrictions`;
    return patch(url, dto);
};

const downloadPlayersApi = (query) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/excel?${preparedQuery}`;
    return getFile(url);
};

const downloadPlayersPendingApi = (query) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/players/pending-kyc/excel?${preparedQuery}`;
    return getFile(url);
};

const getPlayerEmailApi = (oid) => {
    const url = `/players/oid/${oid}/email`;
    return get(url);
};

const updatePlayerEmailApi = ({ id, email }) => {
    const url = `/players/oid/${id}/email`;
    return patch(url, { email });
};

const getPlayerPhoneApi = (oid) => {
    const url = `/players/oid/${oid}/phone`;
    return get(url);
};

const updatePlayerPhoneApi = ({ id, phone }) => {
    const url = `/players/oid/${id}/phone`;
    return patch(url, { phone });
};

const updatePlayerNoteApi = ({ oid, dto }) => {
    const url = `/players/oid/${oid}/note`;
    return patch(url, dto);
};

const downloadGamePlayListApi = ({ id, type, timezoneOffsetInMinutes }) => {
    const url = `/players/oid/${id}/game-play/excel?type=${type}&timezoneOffsetInMinutes=${timezoneOffsetInMinutes}`;
    return getFile(url);
};

const getPlayerAttributionListApi = ({ id, query }) => {
    const preparedQuery = prepareSearch(query);
    const url = `/players/oid/${id}/attributions?${preparedQuery}`;
    return get(url);
};

const getPlayerAuditVipListApi = ({ oid }) => {
    const filterDynamic = [
        { key: 'property', value: 'agentOwner' },
        { key: 'property', value: 'vipLevel' }
    ];
    const preparedQuery = prepareFilterSearch({
        filterDynamic
    });

    const url = `/players/oid/${oid}/audit/action/InfoChanged/changes/simple?${preparedQuery}`;
    return get(url);
};

export {
    updatePlayerJointApi,
    getPlayerJointApi,
    getPlayerSelectListApi,
    getPlayerListApi,
    getPlayerKYCListApi,
    getPlayerKYCCountApi,
    getPlayerTrackerListApi,
    searchPlayersApi,
    updatePlayersApi,
    updatePlayerIdentityInfoApi,
    getPlayerGeneralApi,
    updatePlayerGeneralApi,
    updatePlayerAffiliateInfoApi,
    updatePlayerStatusApi,
    getPlayerWithdrawalListApi,
    getPlayerDepositListApi,
    downloadPlayerDepositListApi,
    downloadPlayerWithdrawalListApi,
    getPlayerCommunicationApi,
    updatePlayerCommunicationApi,
    sendPlayerCommunicationEmailApi,
    sendPlayerCommunicationPhoneApi,
    getPlayerCommunicationPhoneListApi,
    getPlayerCommunicationEmailListApi,
    getPlayerPromotionsListApi,
    getPromotionTemplatesListApi,
    applyPlayerPromotionApi,
    closePlayerPromotionApi,
    getPlayerSportPromotionsListApi,
    applyPlayerSportPromotionApi,
    closePlayerSportPromotionApi,
    getPlayerAuditLoginsListApi,
    downloadPlayerAuditLoginsListApi,
    getPlayerAuditStatusListApi,
    getPlayerAuditChangesListApi,
    getPlayerAuditFiltersApi,
    getPlayerGamePlayApi,
    getPlayerGameSessionsApi,
    getPlayerGameRoundsApi,
    getPlayerBetSlipApi,
    getPlayerGameSessionRoundsApi,
    getPlayerVerificationApi,
    updatePlayerVerificationApi,
    getPlayerVerificationHistoryListApi,
    getPlayerSofAgreementApi,
    getPlayerRestrictionsApi,
    updatePlayerRestrictionsApi,
    downloadPlayersApi,
    getPlayerEmailApi,
    updatePlayerEmailApi,
    getPlayerPhoneApi,
    updatePlayerPhoneApi,
    updatePlayerNoteApi,
    downloadPlayersPendingApi,
    downloadGamePlayListApi,
    getPlayerAttributionListApi,
    getPlayerOccupationApi,
    getPlayerUniqueGameIdsApi,
    getPlayerSelfExclusionAgreementApi,
    getPlayerAccountRecoveryAgreementApi,
    getPlayerReferralsApi,
    getPlayerAuditVipListApi,
};
