import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import GoogleButton from './GoogleButton';
import { apiError, googleAuth, loginUser } from '../../store/actions';
import wiztechLogo from '../../assets/images/logo/Logo.png';
import dataSwappLogo from '../../assets/images/logo/data-swapp.svg';

import useAlertService from '../../hooks/useAlertService';

import i18n from '../../i18n';

import '../../assets/scss/pages/login.scss';
import { DATACENTER, DATACENTER_CODE, DATACENTER_CODE_TYPE, isLocalMode } from '../../common/constants/common';
import eye from '../../../src/assets/images/common/eye.svg';
import closedEye from '../../assets/images/common/closedEye.svg';
import { getStorageXSiteId, removeXSiteIdFromStorages } from '../../common/utils/common';
import MetaTags from 'react-meta-tags';


const initialState = {
  email: '',
  password: '',
};

const Login = ({ loginUser, googleAuth, history, apiError, error }) => {
  const { loading } = useSelector(state => state.Login);

  const { showError } = useAlertService();
  const [state, setState] = useState(initialState);
  const [isShowAnimate, setShowAnimate] = useState(false);
  const [isHidePage, setHidePage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const logo = useMemo(() => {
    switch (DATACENTER_CODE) {
      case DATACENTER_CODE_TYPE.wiz_fk:
      case DATACENTER_CODE_TYPE.wiz_ir:
      case DATACENTER_CODE_TYPE.wiz_sp:
        return wiztechLogo;
      case DATACENTER_CODE_TYPE.ds_fk:
      case DATACENTER_CODE_TYPE.ds_ir:
        return dataSwappLogo;
      default:
          return undefined;
    }
  }, [DATACENTER_CODE]);

  const handleValidSubmit = (event, values) => {
    setTimeout(() => loginUser(values, history), 0);
  };

  const handleGoogleAuth = (authData) => {
    // Need for authData check
    console.log({ authData });

    googleAuth(authData, history);
  };

  useEffect(() => {
    setHidePage(loading);
  }, [loading]);

  useEffect(() => {
    const currUser = JSON.parse(localStorage.getItem('authUser'));
    const currBrand = getStorageXSiteId();
    const isUserControlsBrand = currUser?.sites?.some(site => site.id === currBrand) || false;

    if (!isUserControlsBrand) {
      removeXSiteIdFromStorages();
    }

  }, []);

  const handleChangeEmail = event => {
    setState({ email: event.target.value });
  };

  const handleChangePassword = event => {
    setState({ password: event.target.value });
  };

  const fields = useMemo(
    () => [
      {
        name: 'email',
        value: state.email,
        errorMessage: i18n.t('authentication.emailIsEmpty'),
        placeholder: i18n.t('authentication.enterEmail'),
        onChange: handleChangeEmail,
        type: 'text',
        className: 'login-page__content-form__field-input',
        required: true,
        label: i18n.t('authentication.email'),
      },
      {
        name: 'password',
        value: state.password,
        errorMessage: i18n.t('authentication.passwordIsEmpty'),
        placeholder: i18n.t('authentication.enterPassword'),
        onChange: handleChangePassword,
        type: passwordShown ? 'text' : 'password',
        className: 'login-page__content-form__field-input',
        required: true,
        label: i18n.t('authentication.password'),
      },
      {
        type: 'checkbox',
        name: 'remember',
        label: i18n.t('authentication.rememberMe'),
        className: 'app-checkbox',
      },
    ],
    [],
  );

  useEffect(() => {
    apiError('');

    // so that the animation has time to play
    setTimeout(() => {
      setShowAnimate(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (error) {
      error.map(item => {
        showError(item.message);
      });
    }
  }, [error]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <MetaTags>
        <title>Back-office | {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <div
        className={`login-page wiztech-bg${
          isShowAnimate ? ' wiztech-bg__showed' : ''
        }`}
      >
        <div
          className={`login-page__content${
            isShowAnimate ? ' login-page__content__showed' : ''
          }${isHidePage ? ' login-page__content__hided' : ''}`}
        >
          <div className={'login-page__content-text'}>
            <h5>{i18n.t('authentication.welcomeBack')}</h5>
            <p>{DATACENTER}</p>
          </div>

          <AvForm
            className={'login-page__content-form'}
            onValidSubmit={handleValidSubmit}
          >
            {fields.map(({ type, label, errorMessage, ...props }, index) => (
              <Fragment key={index}>
                {type === 'checkbox' ? (
                  <AvField
                    type={'checkbox'}
                    label={label}
                    {...props}
                  />
                ) : (
                  <div className="login-page__content-form__field">
                    <label className="login-page__content-form__field-label">
                      {label}
                    </label>
                    <div className="wrapper" style={{ position: 'relative' }}>
                      <AvField type={passwordShown && label === 'Password' ? 'text' : type}
                               validate={
                                 props.name === 'email'
                                   ? {
                                     required: {
                                       value: true,
                                       errorMessage: errorMessage,
                                     },
                                     minLength: {
                                       value: 7,
                                       errorMessage: i18n.t('errors.invalidEmailLength'),
                                     },
                                     maxLength: {
                                       value: 50,
                                       errorMessage: i18n.t('errors.invalidEmailLength'),
                                     },
                                   }
                                   : {
                                     required: {
                                       value: true,
                                       errorMessage: errorMessage,
                                     },
                                   }
                               }
                               {...props}
                      />
                      {props.name === 'password' &&
                        <div onClick={togglePassword} className="password-eye-reset">
                          <img src={passwordShown ? eye : closedEye} alt="eye-icon" />
                        </div>
                      }
                    </div>
                  </div>
                )}
              </Fragment>
            ))}

            <button
              className="btn btn-primary shadow-none"
              type="submit"
            >
              {i18n.t('authentication.Login')}
            </button>
          </AvForm>
          <span className="clear-cache-button" onClick={() => {
            localStorage.clear();
          }}>Renew Session</span>
        </div>

        <footer className="footer-login">
          {logo &&
            <img src={logo} className="wizthechLogo" alt="logo" />
          }
          {new Date().getFullYear()} © <br />
        </footer>
      </div>
    </>
  );
};

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  googleAuth: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    googleAuth,
    apiError,
  })(Login),
);
