import React, { useMemo } from "react"
import PropTypes from 'prop-types';

import './status.scss';
import i18n from '../../i18n';
import { useLocation } from "react-router-dom"

function PaymentStatusView({ status }) {
    const location = useLocation();

    const isWithdrawalPage = useMemo(() => {
        return location.pathname.includes('withdrawals') || location.search.includes('subTab=WITHDRAWALS');
    }, [location.pathname])


    const getStatusText = () => {
        if (isWithdrawalPage && status === 'REFUNDED') {
            return i18n.t('finance.deposit.status.CANCELED_BY_PSP');
        }
        return i18n.t(`finance.deposit.status.${status}`);
    };

    return (
      <div className={`payment-status ${status}`}>
          {getStatusText()}
      </div>
    );
}

PaymentStatusView.propTypes = {
    status: PropTypes.string.isRequired
};

export default React.memo(PaymentStatusView);
