import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
    getPlayerWithdrawalList,
    resetPlayerWithdrawalErrors
} from '../../../store/actions';
import Loader from '../../../common/components/Loader';
import useAlertService from '../../../hooks/useAlertService';
import useList from '../../../hooks/useList';
import SelectTableView from "../../Content/components/SelectTableView";
import WithdrawalsTabTable from "./WithdrawalsTabTable";


function WithdrawalsTabContent(props) {
    const { playerId, activeTab, brandCurrencies } = props;
    const { currentFilter } = useSelector(state => state.Filter);
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { isLoading, total, playerWithdrawalList, query, error } =
        useSelector(state => state.PlayerWithdrawals);

    const [_query, _setQuery] = useState(query);

    const { pageCount, activePage, handleChangePage } = useList(
        query => dispatch(getPlayerWithdrawalList({ id: playerId, query })),
        total,
        _query
    );

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetPlayerWithdrawalErrors());
        }
    }, [error]);

    useEffect(() => {
        const requestQuery = _query;

        if (currentFilter) {
            requestQuery.filterDynamic = currentFilter;
        }
        requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();

        dispatch(getPlayerWithdrawalList({ id: playerId, query: { ...requestQuery } }))
    }, [currentFilter]);

    useEffect(() => {
        dispatch(
            getPlayerWithdrawalList({ id: playerId, query: { ..._query } })
        );
    }, []);

    useEffect(() => {
        handleChangePage(1);
    }, [_query.limit])

    const onChangeRecords = (value) => {
        _setQuery({
            limit: value,
            offset: 0
        })
    }

    return (
        <div className="detailed-card__content withdrawals">
            {playerWithdrawalList && !isLoading ? (
                <>
                    <WithdrawalsTabTable
                        list={playerWithdrawalList}
                        activeTab={activeTab}
                        playerId={playerId}
                        currentFilter={currentFilter}
                        isLoading={isLoading}
                        brandCurrencies={brandCurrencies}
                    />
                    <div className='mt-3'>
                        <SelectTableView
                            pageCount={pageCount}
                            activePage={activePage}
                            recordsCount={total}
                            onChangePage={handleChangePage}
                            pageSize={_query.limit}
                            onChange={onChangeRecords}
                        />
                    </div>
                </>
            ) : (
                <Loader size={'lg'}/>
            )}
        </div>
    );
}

WithdrawalsTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(WithdrawalsTabContent);
