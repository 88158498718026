import {
    GET_PLAYER_AUDIT_LOGINS_LIST,
    GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    GET_PLAYER_AUDIT_LOGINS_LIST_FAIL,
    GET_PLAYER_AUDIT_STATUS_LIST,
    GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS,
    GET_PLAYER_AUDIT_STATUS_LIST_FAIL,
    GET_PLAYER_AUDIT_CHANGES_LIST,
    GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS,
    GET_PLAYER_AUDIT_CHANGES_LIST_FAIL,
    GET_PLAYER_AUDIT_FILTERS,
    GET_PLAYER_AUDIT_FILTERS_SUCCESS,
    GET_PLAYER_AUDIT_FILTERS_FAIL,
    RESET_PLAYER_AUDIT_LIST_ERRORS,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL,
    GET_PLAYER_VIP_AUDIT_LIST,
    GET_PLAYER_VIP_AUDIT_LIST_SUCCESS,
    GET_PLAYER_VIP_AUDIT_LIST_FAIL
} from "./actionTypes"

export const getPlayerAuditLoginsList = payload => ({
    type: GET_PLAYER_AUDIT_LOGINS_LIST,
    payload
});

export const getPlayerAuditLoginsListSuccess = payload => ({
    type: GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    payload
});

export const getPlayerAuditLoginsListFail = payload => ({
    type: GET_PLAYER_AUDIT_LOGINS_LIST_FAIL,
    payload
});

export const getPlayerAuditStatusList = payload => ({
    type: GET_PLAYER_AUDIT_STATUS_LIST,
    payload
});

export const getPlayerAuditStatusListSuccess = payload => ({
    type: GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS,
    payload
});

export const getPlayerAuditStatusListFail = payload => ({
    type: GET_PLAYER_AUDIT_STATUS_LIST_FAIL,
    payload
});

export const getPlayerAuditChangesList = payload => ({
    type: GET_PLAYER_AUDIT_CHANGES_LIST,
    payload
});

export const getPlayerAuditChangesListSuccess = payload => ({
    type: GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS,
    payload
});

export const getPlayerAuditChangesListFail = payload => ({
    type: GET_PLAYER_AUDIT_CHANGES_LIST_FAIL,
    payload
});

export const getPlayerAuditFilters = payload => ({
    type: GET_PLAYER_AUDIT_FILTERS,
    payload
});

export const getPlayerAuditFiltersSuccess = payload => ({
    type: GET_PLAYER_AUDIT_FILTERS_SUCCESS,
    payload
});

export const getPlayerAuditFiltersFail = payload => ({
    type: GET_PLAYER_AUDIT_FILTERS_FAIL,
    payload
});

export const resetPlayerAuditListErrors = payload => ({
    type: RESET_PLAYER_AUDIT_LIST_ERRORS,
    payload
});

export const downloadPlayerAuditLoginsList = payload => ({
    type: DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST,
    payload
});

export const downloadPlayerAuditLoginsListSuccess = payload => ({
    type: DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    payload
});

export const downloadPlayerAuditLoginsListFail = payload => ({
    type: DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL,
    payload
});

export const getPlayerVipAuditList = payload => ({
    type: GET_PLAYER_VIP_AUDIT_LIST,
    payload
});

export const getPlayerVipAuditListSuccess = payload => ({
    type: GET_PLAYER_VIP_AUDIT_LIST_SUCCESS,
    payload
});

export const getPlayerVipAuditListFail = payload => ({
    type: GET_PLAYER_VIP_AUDIT_LIST_FAIL,
    payload
});