import { useState } from "react";
import {
  activateSecretVipLevel,
  addVipComment,
  getSecretVipLevelAvailable,
} from "../helpers/vipSecretApi"
import useAlertService from "./useAlertService";
import { useTranslation } from "react-i18next";

const useVipSecretLevel = () => {
  const alertService = useAlertService();
  const { t } = useTranslation();

  const [isLoadingAvailableSecretLevel, setIsLoadingAvailableSecretLevel] = useState(false);
  const [isSecretLevelActivated, setIsSecretLevelActivated] = useState(false);
  const [vipComment, setVipComment] = useState('');

  const [isLoadingActivate, setIsLoadingActivate] = useState(false);
  const [dataActivate, setDataActivate] = useState(null);

  const fetchSecretVipLevelAvailable = async (oid) => {
    setIsLoadingAvailableSecretLevel(true);
    try {
      const response = await getSecretVipLevelAvailable(oid);
      setIsSecretLevelActivated(response?.data?.levelStatus);
      setVipComment(response?.data?.comments);
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoadingAvailableSecretLevel(false);
    }
  };

  const activateSecretVipLevelForPlayer = async (oid) => {
    setIsLoadingActivate(true);
    try {
      const response = await activateSecretVipLevel(oid);
      setDataActivate(response);

      if (response?.success) {
        alertService.showSuccess(t('crm.secretLevelActivated'));
        fetchSecretVipLevelAvailable(oid);
      }
    } catch (err) {
      alertService.showError(err)
    } finally {
      setIsLoadingActivate(false);
    }
  };

  const addVipCommentForPlayer = async (oid, comment = '') => {
    setIsLoadingActivate(true);
    try {
      const response = await addVipComment(oid, comment);

      if (response?.success) {
        alertService.showSuccess(t('crm.CommentAdded'));
        fetchSecretVipLevelAvailable(oid);
      }
    } catch (err) {
      alertService.showError(err)
    } finally {
      setIsLoadingActivate(false);
    }
  };

  return {
    isLoadingAvailableSecretLevel,
    isSecretLevelActivated,
    vipComment,
    fetchSecretVipLevelAvailable,

    isLoadingActivate,
    dataActivate,
    activateSecretVipLevelForPlayer,

    addVipCommentForPlayer,
  };
};

export default useVipSecretLevel;