import React, {memo, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import i18n from "../../../../i18n";
import MetaTags from "react-meta-tags";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import ACommonDataField from "../../../../components/GenericComponents/CommonDataField";
import addIcon from "../../../../assets/images/common/addIconLight.svg";
import TooltipMUI from "../../../../UI/tooltip/TooltipMUI";
import {basicFields} from "./Fields/fields";
import {createCashierMethod, updateCashierMethod} from "../../../../store/cashier/actions";
import {addFieldStates, newPaymentMethodID} from "../../Cashier";
import CashierMethodButtons from "../CashierSaveCancel/CashierMethodButtons";
import clsx from "clsx";
import {useParams} from "react-router-dom";


const initialMethodValues = {
    status: false,
    id: '',
    name: '',
    method: '',
    image: '',
    players: '',
    countriesAvailable: [],
    countriesDisable: [],
    requiredFields: [],
}

const PaymentMethodSettings = (props) => {
    const {
        isLoading,
        selectedMethod,
        setAddFieldState,
        addNewSupplier,
        canEditCashier,
        removeMethod,
        isNotSavedSupplier
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const basicFieldsCopy = [...basicFields];
    const params = useParams();
    const method = params?.id;

    const [methodValues, setMethodValues] = useState(initialMethodValues);

    const onChange = (stateKey, value) => {
        setMethodValues((prevValues) => ({
            ...prevValues,
            [stateKey]: value,
        }));
    };

    useEffect(() => {
        if (selectedMethod?.id) {
            setMethodValues({
                id: selectedMethod.id,
                status: selectedMethod.status || false,
                type: method.toUpperCase() || 'DEPOSIT',
                priority: selectedMethod?.priority || 1,
                name: selectedMethod.name || '',
                method: selectedMethod.method || '',
                image: selectedMethod.image || '',
                players: selectedMethod.players || '',
                countriesAvailable: selectedMethod.countriesAvailable || [],
                countriesDisable: selectedMethod.countriesDisable || [],
                requiredFields: selectedMethod.requiredFields || [],
            });
        } else {
            setMethodValues(initialMethodValues)
        }
    }, [selectedMethod]);

    // split basicFields to each groups with max 4 fields
    const basicFieldsGroups = useMemo(() => {
        const groups = [];
        let group = [];
        const firstElement = basicFieldsCopy.shift();

        basicFieldsCopy.forEach((field, index) => {
            if(index % 4 === 0 && index !== 0) {
                groups.push(group);
                group = [];
            }
            group.push(field);
        });

        groups.push(group);

        return {
            state: firstElement,
            groups
        };
    }, []);

    const updateMethod = () => {
        if (selectedMethod?.id) {
            const updatedMethodValues = { ...methodValues };
            updatedMethodValues.id = selectedMethod.id;
            dispatch(updateCashierMethod(updatedMethodValues));
        }
    };

    const createMethod = () => {
        const { id, ...methodBody } = methodValues;
        dispatch(createCashierMethod(methodBody));
        setAddFieldState(addFieldStates.READY);
    }

    const isEdit = useMemo(() => {
        if(!selectedMethod) {
            setMethodValues(methodValues);
        } else {
            const keys = Object.keys(methodValues);
            return keys?.some(
                (key) =>
                    (
                        methodValues?.hasOwnProperty(key) &&
                        selectedMethod?.hasOwnProperty(key) &&
                        methodValues[key] !== selectedMethod[key] ||
                        selectedMethod?.id === newPaymentMethodID
                    )
            );
        }
    }, [methodValues, selectedMethod]);

    return (
        <div className='d-flex'>
            <MetaTags>
                <title>{t('crm.cashier')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Row>
                <Col xs={12}>
                    <div className='basic-fields'>
                        {basicFieldsGroups.state && (
                            <ACommonDataField
                                key={basicFieldsGroups.state.stateKey}
                                fulWidth={true}
                                id={basicFieldsGroups.state.stateKey}
                                component={basicFieldsGroups.state.component}
                                helperText={basicFieldsGroups.state?.hint || ''}
                                label={i18n.t(`crm.${basicFieldsGroups.state.stateKey}`)}
                                value={methodValues[basicFieldsGroups.state.stateKey] || ''}
                                handleChange={(e) => onChange(basicFieldsGroups.state.stateKey, e)}
                                className={basicFieldsGroups.state?.className}
                                disabled={basicFieldsGroups.state?.disabled || !canEditCashier}
                            />
                        )}

                        {basicFieldsGroups.groups.map((group, index) => (
                            <div className="basic-fields__item">
                                {group.map((item, index) => (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                    }}>
                                        <div className='d-flex justify-content-end align-items-center'>
                                            {item.info && (
                                                <TooltipMUI
                                                    title={`${item.info}\n${item?.hint || ''}`}
                                                />
                                            )}
                                        </div>
                                        <ACommonDataField
                                            key={index}
                                            fulWidth={true}
                                            id={item.stateKey}
                                            component={item.component}
                                            label={i18n.t(`crm.${item.stateKey}`)}
                                            value={methodValues[item?.stateKey] || ''}
                                            handleChange={(e) => onChange(item.stateKey, e)}
                                            className={item.className || ''}
                                            disabled={item?.disabled || !canEditCashier}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div
                            className={clsx('new-supplier', {
                                'disabledBtn': !canEditCashier ||
                                                selectedMethod?.id === newPaymentMethodID ||
                                                isNotSavedSupplier
                            })}
                            onClick={addNewSupplier}
                        >
                            <img src={addIcon} alt="Add field"/>
                            <a className='add-new-supplier'>
                                {t('crm.addNewSupplier')}
                            </a>
                        </div>
                    </div>
                    {isEdit && !isLoading && (
                        <CashierMethodButtons
                            onSave={selectedMethod?.id !== newPaymentMethodID
                                ? updateMethod
                                : createMethod
                            }
                            className='z-index-30'
                            onCancel={selectedMethod?.id !== newPaymentMethodID
                                ? () => setMethodValues(selectedMethod)
                                : () => removeMethod(selectedMethod?.id)
                            }
                        />
                    )}
                    <hr className='mt-2'/>
                </Col>
            </Row>
        </div>
    )
}

export default memo(PaymentMethodSettings);