import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import MainCard from './MainCard';
import DetailedCard from './DetailedCard';
import i18n from '../../../i18n';

import './user-general.scss';

import { useDispatch, useSelector } from 'react-redux';
import useAlertService from '../../../hooks/useAlertService';
import {
    getCardNetworks,
    getPlayerGeneral,
    getPlayerPromotionsList,
    resetPlayerErrors,
    resetPlayerState,
} from "../../../store/actions"
import Loader from "../../../common/components/Loader"
import { PlayerTabContextProvider } from "./context/PlayerTabContext"
import MetaTags from "react-meta-tags";
import { getBrandTags } from "../../../store/brand/brandPage/actions";
import { SITE_PERMISSION } from "../../../common/constants/common";
import PermissionWrapper from "../../../components/PermissionWrapper";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import { MultiWalletCurrencyProvider } from "../../../context/MultiWalletCurrencyContext"

function UserGeneral() {
    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const {playerGeneral, error} = useSelector(
        state => state.PlayerGeneral
    );
    const { agentSelf } = useSelector(state => state.AgentSelf);

    const [playerModel, setPlayerModel] = useState(
        JSON.parse(JSON.stringify(playerGeneral))
    );
    const [fieldErrors, setFieldErrors] = useState({});
    const [isShowMainCard, setShowMainCard] = useState(true);

    useEffect(() => {
        dispatch(getCardNetworks());
    }, []);

    useEffect(() => {
        if (agentSelf?.permissions?.includes(SITE_PERMISSION.IT__Get_Brand_Tags)) {
            dispatch(getBrandTags());
        }
    }, [agentSelf?.permissions]);

    useEffect(() => {
        setPlayerModel(JSON.parse(JSON.stringify(playerGeneral)));
    }, [playerGeneral]);

    useEffect(() => {
        if (error?.data?.amlRFC) {
            alertService.showError(i18n.t("crm.alerts.amlRFC.already.exists"));
        } else if (error?.data?.amlCURP) {
            alertService.showError(i18n.t("crm.alerts.amlCURP.already.exists"));
        } else {
            if (error?.validation) {
               typeof validation === 'string'
                   ? alertService.showError(error.validation)
                   : error.validation.forEach(mess => {
                       if (mess.includes('vipLevel')) {
                           alertService.showError(i18n.t("crm.alerts.amlCURP.already.exists"));
                       } else {
                           alertService.showError(mess);
                       }
                    })
            } else {
                alertService.showError(error?.message);
            }
        }
        dispatch(resetPlayerErrors());
    }, [error]);

    useEffect(() => {
        if (params.id) {
            dispatch(getPlayerGeneral(params.id));
            dispatch(
                getPlayerPromotionsList({
                    id: params.id,
                    query: {},
                })
            );
        }

        return () => dispatch(resetPlayerState());
    }, [params.id]);

    const handleToggleMainCard = useCallback(() => {
        setShowMainCard(!isShowMainCard);
    }, [isShowMainCard]);

    return (
        <ErrorBoundary>
            <PlayerTabContextProvider>
                <MetaTags>
                    <title>{i18n.t("crm.playerView")} - {process.env.REACT_APP_APP_NAME}</title>
                </MetaTags>
                <PermissionWrapper
                    accessPermissions={[SITE_PERMISSION.Player__View_Player_Info]}
                    withPermissionError={true}
                >
                    <div className="page-content user-general">
                        <Container fluid>
                            {playerGeneral ? (
                                <Row className="user-general__cards m-0">
                                    <Col lg="12">
                                        {playerModel && (
                                          <>
                                              <MultiWalletCurrencyProvider>
                                                  <MainCard
                                                    playerModel={playerModel}
                                                    setPlayerModel={setPlayerModel}
                                                    fieldErrors={fieldErrors}
                                                    setFieldErrors={setFieldErrors}
                                                    onToggleDropdown={handleToggleMainCard}
                                                  />
                                                  <DetailedCard
                                                    playerModel={playerModel}
                                                    setPlayerModel={setPlayerModel}
                                                    fieldErrors={fieldErrors}
                                                    setFieldErrors={setFieldErrors}
                                                    showMainCard={isShowMainCard}
                                                  />
                                              </MultiWalletCurrencyProvider></>
                                        )}
                                    </Col>
                                </Row>
                            ) : (
                                <Loader size={'lg'}/>
                            )}

                        </Container>
                    </div>
                </PermissionWrapper>
            </PlayerTabContextProvider>
        </ErrorBoundary>
    );
}

export default React.memo(UserGeneral);
