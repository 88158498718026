import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from 'react-i18next';
import GamificationWidget from './GamificationWidget';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import ButtonsPanel from "./ButtonsPanel";
import useAlertService from "../../../hooks/useAlertService";
import { prepareUpdatePlayerGeneralDto } from "./model/playerEditModel";
import { updatePlayerGeneral } from "../../../store/players/general/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { SITE_PERMISSION } from "../../../common/constants/common"
import { SMARTICO_DRIVE_LINKS } from "../../../constants/siteBrands";
import userSearch from "../../../assets/images/userSearch.svg";
import Tooltip from "@mui/material/Tooltip";
import doc from "../../../assets/images/common/doc.svg";
import RenderVIPAudit from "./RenderVIPAudit";
import { getAgentsList } from "../../../store/agents/agents/actions";
import { getPlayerVipAuditList } from "../../../store/players/audit/actions";
import './vipStyles.scss';
import useVipSecretLevel from "../../../hooks/useVipSecretLevel";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField"

const VipTabContent = (props) => {
    const { playerId, playerModel, setPlayerModel, playerOid } = props;
    const { vipAuditList } = useSelector(state => state.PlayerAudit);
    const { isLoading, playerGeneral: initialState, successMessage } = useSelector(state => state.PlayerGeneral);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const alertService = useAlertService();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const brand = activeBrand?.siteName;
    const agentPermissions = agentSelf?.permissions;
    const canEditAgentOwner = agentPermissions.includes(SITE_PERMISSION.Player__Change_Agent_Owner);
    const canEditVip = agentPermissions.includes(SITE_PERMISSION.Players__Change_Player_VIP_Level)

    const {
        fetchSecretVipLevelAvailable,
        activateSecretVipLevelForPlayer,
        isLoadingAvailableSecretLevel,
        isSecretLevelActivated,
        addVipCommentForPlayer,
        vipComment
    } = useVipSecretLevel();

    useEffect(() => {
        dispatch(getAgentsList());
        dispatch(getPlayerVipAuditList(playerOid));
        fetchSecretVipLevelAvailable(playerOid);
    }, []);

    useEffect(() => {
        if (vipComment.length) {
            setComment(vipComment);
        }
    }, [vipComment])

    const fields = [
        {
            component: 'select-vip-level',
            stateKey: 'vipLevel',
            hasClear: false,
            defaultValue: playerModel.vipLevel,
            disabled: true,
        },
        {
            component: 'select-agent-owner',
            stateKey: 'agentOwnerId',
            hasClear: true,
            defaultValue: playerModel.agentOwnerId === null ? '' : playerModel.agentOwnerId,
            disabled: !canEditAgentOwner
        },
    ];

    const isEditVipComment = useMemo(() => {
        return !isEqual(vipComment, comment)
    }, [vipComment, comment]);

    const isEditGeneral = useMemo(() => {
        return !isEqual(initialState.vipLevel, playerModel.vipLevel) ||
          !isEqual(initialState.agentOwnerId, playerModel.agentOwnerId)
    }, [initialState.vipLevel, playerModel.vipLevel, initialState.agentOwnerId, playerModel.agentOwnerId]);

    const handleSave = () => {
        const dto = prepareUpdatePlayerGeneralDto(
          initialState,
          playerModel
        );
        if (dto.agentOwnerId === '') {
            dto.agentOwnerId = null;
        }

        if (isEditGeneral) {
            dispatch(updatePlayerGeneral({ id: playerModel.oid, dto }));
        }

        if (isEditVipComment) {
            addVipCommentForPlayer(playerModel.oid, comment);
        }
    };

    const handleCancel = () => {
        setPlayerModel(initialState);
        setComment(vipComment);
    }

    const handleChange = (stateKey) => (value) => {
        if (stateKey === 'agentOwnerId' && value === '') {
            value = null;
        }
        if (stateKey === 'vipComment') {
            setComment(value);
        }
        setPlayerModel(prevState => ({
            ...prevState,
            [stateKey]: value
        }));
    }

    useEffect(() => {
        if (successMessage) {
            alertService.showSuccess(t(successMessage));
        }
    }, [successMessage]);


    const openSmarticoLink = (link) => {
        window.open(link, '_blank');
    }

    const activateSecretLevel = () => {
        activateSecretVipLevelForPlayer(playerOid);
    }

    return (
      <div className="vip-tab-container">
          <div className="vip-tab-left">
              {fields.map((item, index) => (
                <div key={index} className="vip-level-field">
                    <ACommonDataField
                      component={item.component}
                      label={t(`crm.${item.label || item.stateKey}`)}
                      value={item.defaultValue}
                      handleChange={handleChange(item.stateKey)}
                      hasClear={item.hasClear}
                      disabled={item.disabled}
                    />
                </div>
              ))}
              <div className="vip-tab-smartico">
                  <GamificationWidget userId={playerId} />
                  {SMARTICO_DRIVE_LINKS[brand] && (
                    <Tooltip title={t("crm.openSmarticoDrive")} arrow>
                        <button
                          className="btn vip-btn"
                          onClick={() => openSmarticoLink(SMARTICO_DRIVE_LINKS[brand].replace("{{PLAYER_ID}}", playerId))}
                        >
                            <img src={userSearch} alt="Smartico users" />
                        </button>
                    </Tooltip>
                  )}
              </div>
              {/*canEditVip*/}
              {process.env.REACT_APP_ENV_MODE !== 'prod' && (
                <>
                    <Tooltip
                      title={t("crm.secretLevelTooltip")}
                      placement="top"
                      arrow
                    >
                        <button
                          className="btn btn-rounded btn-primary vip-secret-activation"
                          disabled={isSecretLevelActivated || isLoadingAvailableSecretLevel}
                          onClick={activateSecretLevel}
                        >
                            {t("crm.activateVipSecretLevel")}
                        </button>
                    </Tooltip>
                    <div className="vip-comment-field">
                        <ACommonDataField
                          multiline
                          fulWidth={true}
                          component={A_COMMON_DATA_FIELD.TEXT}
                          label={"Comment"}
                          value={comment}
                          handleChange={handleChange("vipComment")}
                          disabled={!canEditAgentOwner}
                        />
                    </div>
                </>
              )}
          </div>

          <div className="vertical-separator" />

          <div className="vip-tab-right">
              {vipAuditList && vipAuditList.length > 0 && (
                <div className="vip-audit">
                    <div className="vip-audit-header">
                        <img className="me-3" src={doc} alt="" />
                        <span>{t("crm.auditOfChanges")}</span>
                    </div>
                    <RenderVIPAudit vipAuditList={vipAuditList} />
                </div>
              )}
          </div>

          {(isEditVipComment || isEditGeneral) && (
            <ButtonsPanel
              onSave={handleSave}
              onCancel={handleCancel}
              isDisabled={isLoading}
            />
          )}
      </div>

    );
}

export default React.memo(VipTabContent);