import i18n from '../i18n';
import { COUNTRIES } from '../common/inputs/CountryMultiSelect';
import { LANGUAGES } from '../utils/languages';
import { VENDORS } from '../utils/vendors';
import { PROMOTIONS_TYPES } from '../utils/promotions_types';
import { ACCOUNT_STATUS, AMLCompliantStatus } from './status';
import { PRODUCT } from "../pages/Promotions/common/promotionConstants";
import { DEVICES } from "../common/constants/common";
import { PROVIDERS } from "../utils/providers";
import { AttributionType, BooleanAttributions } from "../pages/Content/common/constants";
import { AUDIT_TABS } from "./audit";

export const AffiliateProductObj = {
    Casino: 1,
    Sport: 2,
    Bingo: 3,
};

export const LOGIN_LOGOUT_ACTIONS = {
    LOGIN: 'Login',
    LOGOUT: 'Logout',
}

export const USER_DEVICES = {
    DESKTOP: 'Desktop',
    MOBILE: 'Mobile',
};

export const FILTER_MENU_SELECT_LIST_DISABLE_VALUES = {
    PROMOTIONS: 'promotions',
    ALLOW_PROMOTIONS: 'allow_promotions',
    FTD_DATE: 'ftd_date',
    LTD_DATE: 'ltd_date',
    LAST_LOGIN: 'last_login',
    // LAST_CONTACT_DATE: 'last_contact_date',
    PROMOTION_ID: 'promotion_id',
    SPORT_PROMOTION_ID: 'sport_promotion_id',
    DEVICE: 'device',
    PROMOCODE_APPLIED: 'promocode_applied',
    BALANCE_RANGE: 'balance_range',
    PROMOTION: 'promotion',
};
export const FILTER_MENU_SELECT_LIST_ACTIVE_VALUES = {
    PLAYER_NAME: 'player_name',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    NAME: 'name',
    IS_ONLINE: 'is_online',
    GAME: 'game',
    FRIENDLY_NAME: 'friendly_name',
    GAME_NAME: 'game_name',
    GAME_NAME_CATEGORY: 'game_name_category',
    STATUS: 'status',
    ACCOUNT_STATUS: 'account_status',
    TEST: 'test',
    IS_BLOCK_DEPOSITS: 'is_block_deposits',
    IS_BLOCK_SPORT: 'is_block_sport',
    IS_BLOCK_LIVE_GAMES: 'is_block_live_games',
    IS_BLOCK_WITHDRAWALS: 'is_block_withdrawals',
    IS_BLOCK_ACCOUNT: 'is_block_account',
    IS_PHONE_NEWSLETTER: 'is_phone_newsletter',
    IS_BLOCK_GAMEPLAY: 'is_block_gameplay',
    IS_EMAIL_NEWSLETTER: 'is_email_newsletter',
    IS_KYC: 'is_kyc',
    NICK_NAME: 'nick_name',
    NATIONALITY: 'nationality',
    GENDER: 'gender',
    BIRTH_DATE: 'birth_date',
    EMAIL: 'email',
    PHONE: 'phone',
    CURRENCY: 'currency',
    AFFILIATE_ID: 'affiliate_id',
    AFFILIATE_SUB_ID: 'affiliate_sub_id',
    SOURCE_ID: 'source_id',
    OFFER_ID: 'offer_id',
    OPERATION_ID: 'operation_id',
    KYC: 'kyc',
    KYC_STATUS: 'kyc_status',
    TYPE: 'type',
    REFERENCE: 'reference',
    AMOUNT: 'amount',
    TOTAL_AMOUNT: 'totalAmount',
    PRIORITY: 'priority',
    EMPHASIZE: 'emphasize',
    ANIMATED: 'animated',
    CREATED_AT: 'created_at',
    ADDED_TIME: 'added_time',
    START_TIME: 'start_time',
    END_TIME: 'end_time',
    EXTERNAL_ID: 'external_id',
    SESSION_ID: 'session_id',
    ROUND_ID: 'round_id',
    CASH_BET: 'cash_bet',
    CASH_WIN: 'cash_win',
    OPEN_BALANCE: 'open_balance',
    CLOSE_BALANCE: 'close_balance',
    ID: 'id',
    OID: 'oid',
    CITIZENSHIP: 'citizenship',
    COUNTRY: 'country',
    LANGUAGE: 'language',
    LAST_DEPOSIT_DATE: 'last_deposit_date',
    IS_PHONE_VERIFICATION: 'is_phone_verification',
    PLAYER_ID: 'player_id',
    ACTION_TYPE: 'action_type',
    INITIATOR: 'initiator',
    RTP: 'rtp',
    LINES: 'lines',
    VENDOR: 'vendor',
    PROVIDER: 'provider',
    USER_DEVICE: 'user_device',
    GAME_PROVIDER: 'game_provider',
    BONUS_FACTOR: 'bonusFactor',
    EXTERNAL_MOBILE_ID: 'externalMobileId',
    EXTERNAL_DESKTOP_ID: 'externalDesktopId',
    TOTAL_BET: 'total_bet',
    TOTAL_WIN: 'total_win',
    BONUS_BET: 'bonus_bet',
    BONUS_WIN: 'bonus_win',
    RESULT: 'result',
    BET_ID: 'bet_id',
    IS_LIVE: 'is_live',
    TAB_PROMOTION_ID: 'tab_promotion_id',
    PROMOTION_PLAYER_ID: 'promotion_player_id',
    PROVIDER_TYPE: 'provider_type',
    PAYMENT_METHOD: 'payment_method',
    CARD: 'card',
    AGENT_OWNER: 'agent_owner',
    PRODUCT: 'product',
    VIPLEVEL: 'viplevel',
    GAME_SESSION_ID: 'game_session_id',
    GAME_ROUND_ID: 'game_round_id',
    GAME_ID: 'game_id',
    FREE_SPINS: 'supportFreeSpins',
    ENABLED_TIME: 'enabledAt'
};

export const FILTER_MENU_SELECT_LIST_VALUES = {
    ...FILTER_MENU_SELECT_LIST_DISABLE_VALUES,
    ...FILTER_MENU_SELECT_LIST_ACTIVE_VALUES
};

export const FILTER_MENU_SELECT_LIST_DISABLE_TAB_VALUES = {
    [AUDIT_TABS.LOGIN_LOGOUT]: [FILTER_MENU_SELECT_LIST_VALUES.ACTION_TYPE],
};

export const FILTER_DECLINE_PAYMENTS_DEFAULT_VALUES = [
    {"key": "status", "value": "ERROR"},
    {"key": "status", "value": "DECLINED"},
    {"key": "status", "value": "CANCELED"}
];

export const FILTER_PENDING_FOR_WIRE_TRANSFER_DEFAULT_VALUES = [
    {"key": "status", "value": "PENDING_FOR_WIRE_TRANSFER"}
]

export const FILTER_WD_PENDING_FOR_APPROVAL = [
    {"key": "status", "value": "PENDING_FOR_APPROVAL"}
];

export const FILTER_WD_PENDING_FOR_ACTION = [
    {"key": "status", "value": "MANUAL_ACTION_REQUIRED"}
];

export const FILTER_SIMPLE_BOOL_COMMON_VALUES = {
    YES: true,
    NO: false
};

export const FILTER_SIMPLE_BOOL_COMMON_OPTIONS = [
    {
        label: i18n.t(
            `filter.constant.${FILTER_SIMPLE_BOOL_COMMON_VALUES.YES}`
        ),
        value: FILTER_SIMPLE_BOOL_COMMON_VALUES.YES
    },
    {
        label: i18n.t(`filter.constant.${FILTER_SIMPLE_BOOL_COMMON_VALUES.NO}`),
        value: FILTER_SIMPLE_BOOL_COMMON_VALUES.NO
    }
];

export const FILTER_GAME_ROUND_RESULT_VALUES = {
    WIN: 'win',
    LOSS: 'loss'
}

export const FILTER_SIMPLE_GENDER_VALUES = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other'
};

export const FILTER_SIMPLE_GENDER_OPTIONS = [
    {
        content: i18n.t(`filter.constant.${FILTER_SIMPLE_GENDER_VALUES.MALE}`),
        value: FILTER_SIMPLE_GENDER_VALUES.MALE
    },
    {
        content: i18n.t(
            `filter.constant.${FILTER_SIMPLE_GENDER_VALUES.FEMALE}`
        ),
        value: FILTER_SIMPLE_GENDER_VALUES.FEMALE
    },
    {
        content: i18n.t(
            `filter.constant.${FILTER_SIMPLE_GENDER_VALUES.OTHER}`
        ),
        value: FILTER_SIMPLE_GENDER_VALUES.OTHER
    },
];

export const CHECKBOX_TYPE_KEYS = {
    ALL: 'ALL'
};

export const FILTER_EXCEL = {
    BLANK: 'BLANK',
    EXCLUDE_BLANK: 'EXCLUDE_BLANK',
    LESS_THAN: 'LESS_THAN',
    LESS_OR_EQ: 'LESS_OR_EQ',
    GREATER_THAN: 'GREATER_THAN',
    GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL'
};

export const FILTER_DATE_RANGE_ACTIVE_INPUT = {
    BETWEEN: 'BETWEEN',
    WITHIN_THE_LAST: 'WITHIN_THE_LAST',
    MORE_THAN: 'MORE_THAN'
};

export const FILTER_DATE_RANGE_VALUES = {
    MINS: 'MINS',
    HOURS: 'HOURS',
    DAYS: 'DAYS',
    WEEKS: 'WEEKS',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};

export const FILTER_DATE_RANGE_OPTIONS = [
    FILTER_DATE_RANGE_VALUES.MINS,
    FILTER_DATE_RANGE_VALUES.HOURS,
    FILTER_DATE_RANGE_VALUES.DAYS,
    FILTER_DATE_RANGE_VALUES.WEEKS,
    FILTER_DATE_RANGE_VALUES.MONTH,
    FILTER_DATE_RANGE_VALUES.YEAR
].map(item => {
    const label = i18n.t(`filter.constant.${item}`);

    // used 'label' and 'content' for support old and new select component
    return {
        label: label,
        content: label,
        value: item
    };
});

export const FILTER_POPUPS = {
    STARTS_WITH: 'STARTS_WITH',
    EQUAL: 'EQUAL',
    CONTAINS: 'CONTAINS',
    CHECKBOX: 'CHECKBOX',
    SIMPLE: 'SIMPLE',
    SIMPLE_BOOL: 'SIMPLE_BOOL',
    SIMPLE_SELECT: 'SIMPLE_SELECT',
    EXCEL: 'EXCEL',
    DATE_RANGE: 'DATE_RANGE',
    CHECKBOX_AND_SEARCH: 'CHECKBOX_AND_SEARCH',
    PROMOTION_MODAL: 'PROMOTION_MODAL',
    LIST: 'LIST',
};

export const PAYMENT_METHODS = {
    CC_CARD: 'cc_card',
    CASH_IN: 'cash_in',
    TRANSFER_IN: 'transfer_in',
    WINPOT_RETAIL: 'winpot-retail',
    APM_EMULATOR: 'ApmEmulator',
    OXXO: 'Oxxo',
    NETELLER: 'Neteller',
    STPMEXX: 'STPmex',
};

export const PAYMENT_METHODS_WITH_PREFIX = [
    'ApmEmulator',
    'Oxxo',
    'Neteller',
    'STPmex',
];

export const FILTER_GAME_ROUNDS_STATUS_VALUES = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    REFUNDED: 'REFUNDED',
    FAILED: 'FAILED',
}

export const FILTER_CHECKBOX_FINANCE_TYPE_VALUES = {
    DEPOSIT: 'DEPOSIT',
    WITHDRAW: 'WITHDRAW',
    CREDIT_CARD_DEPOSIT: 'CreditCardDeposit',
    MANUAL_DEPOSIT: 'ManualDeposit',
    CREDIT_CARD_WITHDRAWAL: 'CreditCardWithdrawal',
    MANUAL_WITHDRAWAL: 'ManualWithdrawal',
};

export const FILTER_CHECKBOX_DEPOSITS_TYPE_VALUES = {
    DEPOSIT: 'DEPOSIT',
    CREDIT_CARD_DEPOSIT: 'CreditCardDeposit',
    MANUAL_DEPOSIT: 'ManualDeposit',
};

export const FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES = {
    WITHDRAW: 'WITHDRAW',
    CREDIT_CARD_WITHDRAWAL: 'CreditCardWithdrawal',
    MANUAL_WITHDRAWAL: 'ManualWithdrawal',
};

export const FILTER_CHECKBOX_FINANCE_STATUS_VALUE = {
    ERROR: 'ERROR',
    DECLINED: 'DECLINED',
    CANCELED: 'CANCELED',
    REFUNDED: 'REFUNDED',
    CHARGEBACK: "CHARGEBACK",
    COMPLETED: 'COMPLETED',
    PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL',
    PENDING_FOR_WIRE_TRANSFER: 'PENDING_FOR_WIRE_TRANSFER',
    PROCESSING: 'PROCESSING',
    PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
    PENDING_FOR_PLAYER: 'PENDING_FOR_PLAYER',
};

export const FILTER_CHECKBOX_GAME_STATUS_VALUE = {
    WIN: 'Win',
    LOSS: 'Loss',
    RUNNING: 'Running',
    PENDING: 'Pending',
    CASHOUTED: 'Cashouted',
    OPENED: 'Opened',
    REFUNDED: 'Refunded',
    REJECTED: 'Rejected',
    COMPLETED: 'Completed',
};

export const FILTER_CHECKBOX_DEPOSITS_STATUS_VALUE = {
    ERROR: 'ERROR',
    DECLINED: 'DECLINED',
    CANCELED: 'CANCELED',
    REFUNDED: 'REFUNDED',
    CHARGEBACK: "CHARGEBACK",
    COMPLETED: 'COMPLETED',
    PROCESSING: 'PROCESSING',
    PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
    PENDING_FOR_PLAYER: 'PENDING_FOR_PLAYER',
    INITIALIZED: 'INITIALIZED'
};

export const FILTER_CHECKBOX_DECLINE_PAYMENTS_VALUE = {
    ERROR: 'ERROR',
    DECLINED: 'DECLINED',
    CANCELED: 'CANCELED'
}

export const FILTER_CHECKBOX_WITHDRAWALS_STATUS_VALUE = {
    ERROR: 'ERROR',
    DECLINED: 'DECLINED',
    CANCELED: 'CANCELED',
    REFUNDED: 'REFUNDED',
    CHARGEBACK: "CHARGEBACK",
    COMPLETED: 'COMPLETED',
    PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL',
    PENDING_FOR_WIRE_TRANSFER: 'PENDING_FOR_WIRE_TRANSFER',
    PROCESSING: 'PROCESSING',
    PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
    PENDING_FOR_PLAYER: 'PENDING_FOR_PLAYER',
};

export const FILTER_CATEGORY = {
    NONE: 'none',
    PLAYER: 'player',
    PENDING_KYC: 'pending_kyc',
    GAME: 'game',
    GAME_CATEGORY: 'game_category',
    GAME_MANAGEMENT: 'game_management',
    AGENT: 'agent',
    AUDIT: 'audit',
    PAYMENT: 'payment',
    WITHDRAWS: 'withdrawal',
    PLAYER_DEPOSITS: 'player_deposits',
    PLAYER_WITHDRAWS: 'player_withdrawals',
    PENDING_WITHDRAWALS: 'pending_withdrawal',
    PENDING_WITHDRAWALS_FOR_PAYMENT: 'pending_withdrawal_for_payment',
    GAME_PLAY: 'game_play',
    BONUSES: 'bonuses',
};

export const INITIAL_FILTER_POPUP = {
    activeModal: '',
    title: '',
    data: null,
    key: null,
    isEditMode: false,
};

export const FILTER_MENU_PLAYER_LIST = (t, brandCurrencies = [], stateVipBrand = {}) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${
                fieldName === 'name' 
                    ? 'player_name'
                    : fieldName
            }`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.NAME:
            case FILTER_MENU_SELECT_LIST_VALUES.NICK_NAME:
            case FILTER_MENU_SELECT_LIST_VALUES.AFFILIATE_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.AFFILIATE_SUB_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.PROMOTION_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.SPORT_PROMOTION_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.STARTS_WITH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.SOURCE_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.OFFER_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            case FILTER_MENU_SELECT_LIST_VALUES.OID:
            case FILTER_MENU_SELECT_LIST_VALUES.ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.LIST,
                    initialValue: [],
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CURRENCY:
                return {
                    ...option,
                    list: brandCurrencies?.map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.EMAIL:
            case FILTER_MENU_SELECT_LIST_VALUES.PHONE:
            case FILTER_MENU_SELECT_LIST_VALUES.PROMOCODE_APPLIED:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            case FILTER_MENU_SELECT_LIST_VALUES.TEST:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_LIVE_GAMES:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_SPORT:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_DEPOSITS:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_WITHDRAWALS:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_ACCOUNT:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_PHONE_NEWSLETTER:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_PHONE_VERIFICATION:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_BLOCK_GAMEPLAY:
            case FILTER_MENU_SELECT_LIST_VALUES.IS_EMAIL_NEWSLETTER:
            case FILTER_MENU_SELECT_LIST_VALUES.ALLOW_PROMOTIONS:
            case FILTER_MENU_SELECT_LIST_VALUES.KYC:
                return {
                    ...option,
                    modal: FILTER_POPUPS.SIMPLE_BOOL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.BALANCE_RANGE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.GENDER:
                return {
                    ...option,
                    list: FILTER_SIMPLE_GENDER_OPTIONS,
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.LANGUAGE:
                return {
                    ...option,
                    list: LANGUAGES.map(key => ({
                        value: key,
                        content: t(`language.${key}`)
                    })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.ACCOUNT_STATUS:
                return {
                    ...option,
                    list: Object.keys(ACCOUNT_STATUS).map(item => ({
                        label: t(`filter.constant.${item}`),
                        key: item
                    })),
                    initialValue: Object.keys(ACCOUNT_STATUS).reduce(
                        (acc, cur) => {
                            acc[cur] = false;
                            return acc;
                        },
                        {}
                    ),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            case FILTER_MENU_SELECT_LIST_VALUES.PRODUCT:
                return {
                    ...option,
                    list: Object.keys(PRODUCT).map(item => ({
                        label: t(`filter.constant.${item}`),
                        key: AffiliateProductObj[item]
                    })),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            case FILTER_MENU_SELECT_LIST_VALUES.DEVICE:
                return {
                    ...option,
                    list: Object.keys(DEVICES).map(item => ({
                        label: t(`filter.constant.${item}`),
                        key: item
                    })),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            case FILTER_MENU_SELECT_LIST_VALUES.VIPLEVEL:
                return {
                    ...option,
                    list: Object.entries(stateVipBrand)?.map(([key, value]) => ({
                        label: value,
                        key: key
                    })),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            case FILTER_MENU_SELECT_LIST_VALUES.KYC_STATUS:
                return {
                    ...option,
                    list: Object.keys(AMLCompliantStatus).map(item => ({
                        label: t(`filter.constant.${item}`),
                        key: item
                    })),
                    initialValue: Object.keys(AMLCompliantStatus).reduce(
                      (acc, cur) => {
                          acc[cur] = false;
                          return acc;
                      },
                      {}
                    ),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            case FILTER_MENU_SELECT_LIST_VALUES.FTD_DATE:
            case FILTER_MENU_SELECT_LIST_VALUES.LTD_DATE:
            case FILTER_MENU_SELECT_LIST_VALUES.BIRTH_DATE:
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
            case FILTER_MENU_SELECT_LIST_VALUES.LAST_LOGIN:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE,
                    list: []
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CITIZENSHIP:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                    list: COUNTRIES.map(country => ({
                        key: country,
                        label: t(country)
                    }))
                };
            case FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                    // will be set dynamically from server
                    list: []
                };
            case FILTER_MENU_SELECT_LIST_VALUES.PROMOTIONS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.PROMOTION_MODAL,
                    list: PROMOTIONS_TYPES.map(promoType => ({
                        key: promoType,
                        label: t(`filter.constant.${promoType}`),
                    }))
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_KYC_LIST = (t, brandCurrencies = []) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${
                fieldName === 'name' 
                    ? 'player_name'
                    : fieldName
            }`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.KYC:
                return {
                    ...option,
                    list: Object.keys(AMLCompliantStatus).map(item => ({
                        label: t(`filter.constant.${item}`),
                        key: item
                    })),
                    initialValue: Object.keys(AMLCompliantStatus).reduce(
                      (acc, cur) => {
                          acc[cur] = false;
                          return acc;
                      },
                      {}
                    ),
                    modal: FILTER_POPUPS.CHECKBOX
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });


export const FILTER_MENU_GAME_LIST = (gameVendors = [], gameTypes = []) => {
    const baseItems = Object.keys(
        FILTER_MENU_SELECT_LIST_VALUES
    )
        .map(key => {
            const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
            const gameTypesWithNoType = [...gameTypes, 'No type'];
            const option = {
                label: i18n.t(`filter.constant.${fieldName}`),
                value: fieldName
            };

            switch (fieldName) {
                case FILTER_MENU_SELECT_LIST_VALUES.FRIENDLY_NAME:
                case FILTER_MENU_SELECT_LIST_VALUES.OPERATION_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_MOBILE_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_DESKTOP_ID:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.CONTAINS
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                    return {
                        ...option,
                        list: gameTypesWithNoType.map(value => ({ value: value === 'No type' ? '' : value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.ADDED_TIME:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.DATE_RANGE,
                        list: []
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.RTP:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.EXCEL
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                    return {
                        ...option,
                        list: gameVendors.length > 0
                            ? gameVendors.map(({name}) => ({ value: name, content: name }))
                            : VENDORS.map(value => ({ value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                default:
                    return undefined;
            }
        });

    const attributionAtems = Object.keys(
        AttributionType
    )
        .map(key => {
            const fieldName = AttributionType[key];

            return {
                label: i18n.t(`filter.constant.${fieldName}`),
                value: fieldName,
                modal: BooleanAttributions.includes(key)
                    ? FILTER_POPUPS.SIMPLE_BOOL
                    : FILTER_POPUPS.CONTAINS,
            };
        });

    return [...baseItems, ...attributionAtems]
        .filter(item => !!item)
        .sort((a, b) => {
            return a.label < b.label ? -1 :
                a.label > b.label ? 1 : 0;
        });
}


export const FILTER_MENU_CATEGORY_GAME_LIST = (gameVendors = [], uniqueGameTypes = []) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: i18n.t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME_CATEGORY:
            case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_MOBILE_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_DESKTOP_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                return {
                    ...option,
                    list: uniqueGameTypes.map(value => {
                        const newValue = value === 'No type' ? '' : value;
                        return {
                            value: newValue,
                            content: value
                        };
                    }),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.EMPHASIZE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.SIMPLE_BOOL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.ADDED_TIME:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE,
                    list: []
                };
            case FILTER_MENU_SELECT_LIST_VALUES.BONUS_FACTOR:
            case FILTER_MENU_SELECT_LIST_VALUES.RTP:
            case FILTER_MENU_SELECT_LIST_VALUES.LINES:
            case FILTER_MENU_SELECT_LIST_VALUES.PRIORITY:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                return {
                    ...option,
                    list: gameVendors.length > 0
                        ? gameVendors.map(({name}) => ({ value: name, content: name }))
                        : [],
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });


export const FILTER_MENU_GAME_MANAGEMENT_LIST = (brandProviders = [], brandVendors = [], gameTypes = []) => {
    const baseItems = Object.keys(
        FILTER_MENU_SELECT_LIST_VALUES
    )
        .map(key => {
            const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
            const gameTypesWithNoType = [...gameTypes, 'No type'];
            const option = {
                label: i18n.t(`filter.constant.${fieldName}`),
                value: fieldName
            };

            switch (fieldName) {
                case FILTER_MENU_SELECT_LIST_VALUES.ANIMATED:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.SIMPLE_BOOL
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME:
                case FILTER_MENU_SELECT_LIST_VALUES.OPERATION_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_MOBILE_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_DESKTOP_ID:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.CONTAINS
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                    return {
                        ...option,
                        list: gameTypesWithNoType.map(value => ({ value: value === 'No type' ? '' : value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.ADDED_TIME:
                case FILTER_MENU_SELECT_LIST_VALUES.ENABLED_TIME:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.DATE_RANGE,
                        list: []
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.BONUS_FACTOR:
                case FILTER_MENU_SELECT_LIST_VALUES.RTP:
                case FILTER_MENU_SELECT_LIST_VALUES.LINES:
                case FILTER_MENU_SELECT_LIST_VALUES.PRIORITY:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.EXCEL
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                    return {
                        ...option,
                        list: brandVendors.length > 0
                            ? brandVendors.map(({name}) => ({ value: name, content: name }))
                            : VENDORS.map(value => ({ value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.PROVIDER:
                    const providers = brandProviders.length > 0
                        ? brandProviders.map((provider) => provider.name)
                        : PROVIDERS;
                    return {
                        ...option,
                        list: providers.map(value => ({ value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                default:
                    return undefined;
            }
        });

    const attributionAtems = Object.keys(
        AttributionType
    )
        .map(key => {
            const fieldName = AttributionType[key];

            return {
                label: i18n.t(`filter.constant.${fieldName}`),
                value: fieldName,
                modal: BooleanAttributions.includes(key)
                    ? FILTER_POPUPS.SIMPLE_BOOL
                    : FILTER_POPUPS.CONTAINS,
            };
        });

    return [...baseItems, ...attributionAtems]
        .filter(item => !!item)
        .sort((a, b) => {
            return a.label < b.label ? -1 :
                a.label > b.label ? 1 : 0;
        });
}


export const FILTER_MENU_PAYMENT_LIST = ({ t, isDeposit = false, brandCurrencies = [], stateVipBrand = {} }) => {
    const isDepositPath = location.pathname === '/deposits' || location.pathname === '/deposits-declines';
    return Object.keys(
        FILTER_MENU_SELECT_LIST_VALUES
    )
        .map(key => {
            const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
            const option = {
                label: t(`filter.depositFilter.${fieldName}`),
                value: fieldName
            };

            switch (fieldName) {
                case FILTER_MENU_SELECT_LIST_VALUES.REFERENCE:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.STARTS_WITH
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.AMOUNT:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.EXCEL
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.CURRENCY:
                    return {
                        ...option,
                        list: brandCurrencies?.map(value => ({ value, content: value })),
                        modal: FILTER_POPUPS.SIMPLE_SELECT
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                    if (!isDepositPath) {
                        return {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX,
                            initialValue: {
                                [FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES.WITHDRAW]: false,
                                [FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES.CREDIT_CARD_WITHDRAWAL]: false,
                                [FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES.MANUAL_WITHDRAWAL]: false,
                            },
                            list: Object.keys(FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES).map(
                                item => ({
                                    label: t(`filter.constant.${item}`),
                                    key: FILTER_CHECKBOX_WITHDRAWALS_TYPE_VALUES[item]
                                })
                            ),
                        };
                    }
                    break;
                case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                    if (location.pathname === '/deposits') {
                        return {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX,
                            list: Object.keys(FILTER_CHECKBOX_DEPOSITS_STATUS_VALUE).map(
                                item => ({
                                    label: t(`filter.constant.${item}`),
                                    key: item
                                })
                            ),
                            initialValue: Object.keys(
                                FILTER_CHECKBOX_DEPOSITS_STATUS_VALUE
                            ).reduce((acc, cur) => {
                                acc[cur] = false;
                                return acc;
                            }, {})
                        };
                    } else if (location.pathname === '/deposits-declines') {
                        return {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX,
                            list: Object.keys(FILTER_CHECKBOX_DECLINE_PAYMENTS_VALUE).map(
                                item => ({
                                    label: t(`filter.constant.${item}`),
                                    key: item
                                })
                            ),
                            initialValue: Object.keys(
                                FILTER_CHECKBOX_DECLINE_PAYMENTS_VALUE
                            ).reduce((acc, cur) => {
                                acc[cur] = false;
                                return acc;
                            }, {})
                        };
                    } else if (location.pathname === '/deposits-action') {
                        return {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX,
                            list: Object.keys(FILTER_CHECKBOX_DECLINE_PAYMENTS_VALUE).map(
                                item => ({
                                    label: t(`filter.constant.${item}`),
                                    key: item
                                })
                            ),
                            initialValue: Object.keys(
                                FILTER_CHECKBOX_DECLINE_PAYMENTS_VALUE
                            ).reduce((acc, cur) => {
                                acc[cur] = false;
                                return acc;
                            }, {})
                        };
                    } else if (location.pathname === '/withdrawals-pending-transfer'
                        || location.pathname === '/withdrawals-pending-approve') {
                        return;
                    } else {
                        const getStatusLabel = (item) => {
                            if (item === 'REFUNDED' && location.pathname.includes('withdrawal')) {
                                return t('filter.constant.CANCELED_BY_PSP');
                            }
                            return t(`filter.constant.${item}`);
                        };

                        return {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX,
                            list: Object.keys(FILTER_CHECKBOX_WITHDRAWALS_STATUS_VALUE).map(
                                item => ({
                                    label: getStatusLabel(item),
                                    key: item
                                })
                            ),
                            initialValue: Object.keys(
                                FILTER_CHECKBOX_WITHDRAWALS_STATUS_VALUE
                            ).reduce((acc, cur) => {
                                acc[cur] = false;
                                return acc;
                            }, {})
                        };
                    }
                case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.DATE_RANGE
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.PLAYER_ID:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.STARTS_WITH
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.VIPLEVEL:
                    return {
                        ...option,
                        list: Object.entries(stateVipBrand)?.map(([key, value]) => ({
                            label: value,
                            key: key
                        })),
                        modal: FILTER_POPUPS.CHECKBOX
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.AFFILIATE_ID:
                    return {
                        ...option,
                        modal: FILTER_POPUPS.STARTS_WITH
                    };
                case FILTER_MENU_SELECT_LIST_VALUES.PROVIDER_TYPE:
                    return isDeposit || isDepositPath
                        ? {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                            // will be set dynamically from server
                            list: []
                        }
                        : undefined;
                case FILTER_MENU_SELECT_LIST_VALUES.COUNTRY:
                    return !isDeposit && !isDepositPath
                        ? {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                            list: COUNTRIES.map(country => ({
                                key: country,
                                label: t(country)
                            }))
                        }
                        : undefined;
                case FILTER_MENU_SELECT_LIST_VALUES.PAYMENT_METHOD:
                    return isDeposit || isDepositPath
                        ? {
                            ...option,
                            modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                            // will be set dynamically from server
                            list: []
                        }
                        : undefined;
                case FILTER_MENU_SELECT_LIST_VALUES.CARD:
                    return isDeposit || isDepositPath
                        ? {
                            ...option,
                            modal: FILTER_POPUPS.EQUAL
                        }
                        : undefined;
                default:
                    return undefined;
            }
        })
        .filter(item => !!item)
        .sort((a, b) => {
            return a.label < b.label ? -1 :
                a.label > b.label ? 1 : 0;
        });
}

export const FILTER_MENU_PLAYER_DEPOSITS_LIST = (t, brandCurrencies = []) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.REFERENCE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.STARTS_WITH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.AMOUNT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CURRENCY:
                return {
                    ...option,
                    list: brandCurrencies?.map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX,
                    list: Object.keys(FILTER_CHECKBOX_DEPOSITS_STATUS_VALUE).map(
                        item => ({
                            label: t(`filter.constant.${item}`),
                            key: item
                        })
                    ),
                    initialValue: Object.keys(
                        FILTER_CHECKBOX_DEPOSITS_STATUS_VALUE
                    ).reduce((acc, cur) => {
                        acc[cur] = false;
                        return acc;
                    }, {})
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            case FILTER_MENU_SELECT_LIST_VALUES.PROVIDER_TYPE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                    // will be set dynamically from server in FilterSearchAndCheckbox
                    list: []
                };
            case FILTER_MENU_SELECT_LIST_VALUES.PAYMENT_METHOD:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH,
                    // will be set dynamically from server in FilterSearchAndCheckbox
                    list: []
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CARD:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EQUAL
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_PLAYER_WITHDRAWALS_LIST = (t, brandCurrencies = []) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.REFERENCE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.STARTS_WITH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.AMOUNT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CURRENCY:
                return {
                    ...option,
                    list: brandCurrencies?.map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                const getStatusLabel = (item) => {
                    if (item === 'REFUNDED' && location.search.includes('subTab=WITHDRAWALS')) {
                        return t('filter.constant.CANCELED_BY_PSP');
                    }
                    return t(`filter.constant.${item}`);
                };


                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX,
                    list: Object.keys(FILTER_CHECKBOX_WITHDRAWALS_STATUS_VALUE).map(
                        item => ({
                            label: getStatusLabel(item),
                            key: item
                        })
                    ),
                    initialValue: Object.keys(
                        FILTER_CHECKBOX_WITHDRAWALS_STATUS_VALUE
                    ).reduce((acc, cur) => {
                        acc[cur] = false;
                        return acc;
                    }, {})
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_PLAYER_AUDIT = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.ACTION_TYPE:
            case FILTER_MENU_SELECT_LIST_VALUES.INITIATOR:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_LOGIN_LOGOUT_AUDIT = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.ACTION_TYPE:
            case FILTER_MENU_SELECT_LIST_VALUES.USER_DEVICE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_STATUS_AUDIT = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.ACCOUNT_STATUS:
            case FILTER_MENU_SELECT_LIST_VALUES.INITIATOR:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_BONUSES = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.TAB_PROMOTION_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.PROMOTION_PLAYER_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.STARTS_WITH
                }
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                }
            case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_SPORT_BONUSES = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.TAB_PROMOTION_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.PROMOTION_PLAYER_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.STARTS_WITH
                }
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                }
            case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_GAME_SESSION = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${
                fieldName === 'created_at' 
                    ? 'start_time' 
                    : fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_BET:
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_WIN:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                return {
                    ...option,
                    list: VENDORS.map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_SESSION_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EQUAL
                }
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    list: ['Open', 'Closed'].map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_GAME_ROUND = (t, uniqueGames) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                return {
                    ...option,
                    list: VENDORS.map(value => ({ value, content: value })),
                    modal: FILTER_POPUPS.SIMPLE_SELECT
                };
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX,
                    list: Object.values(FILTER_GAME_ROUNDS_STATUS_VALUES).map(
                        item => ({
                            label: t(`filter.constant.${item}`),
                            key: item
                        })
                    ),
                }
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_BET:
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_WIN:
            case FILTER_MENU_SELECT_LIST_VALUES.BONUS_BET:
            case FILTER_MENU_SELECT_LIST_VALUES.BONUS_WIN:
            case FILTER_MENU_SELECT_LIST_VALUES.CASH_BET:
            case FILTER_MENU_SELECT_LIST_VALUES.CASH_WIN:
            case FILTER_MENU_SELECT_LIST_VALUES.OPEN_BALANCE:
            case FILTER_MENU_SELECT_LIST_VALUES.CLOSE_BALANCE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_PROVIDER:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX_AND_SEARCH
                };
            case FILTER_MENU_SELECT_LIST_VALUES.RESULT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX,
                    list: Object.keys(FILTER_GAME_ROUND_RESULT_VALUES).map(
                        item => ({
                            label: t(`filter.constant.${item}`),
                            key: item
                        })
                    ),
                    initialValue: Object.keys(
                        FILTER_GAME_ROUND_RESULT_VALUES
                    ).reduce((acc, cur) => {
                        acc[cur] = false;
                        return acc;
                    }, {})
                };
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME:
                return {
                    ...option,
                    label: i18n.t('filter.constant.gameName'),
                    modal: FILTER_POPUPS.CONTAINS
                };
            case FILTER_MENU_SELECT_LIST_VALUES.ROUND_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EQUAL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.SESSION_ID:
            case FILTER_MENU_SELECT_LIST_VALUES.GAME_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EQUAL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.START_TIME:
                return {
                    ...option,
                    label:  i18n.t('filter.constant.start_time'),
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            case FILTER_MENU_SELECT_LIST_VALUES.END_TIME:
                return {
                    ...option,
                    label:  i18n.t('filter.constant.end_time'),
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const FILTER_MENU_SPORT_BETS = (t) => Object.keys(
    FILTER_MENU_SELECT_LIST_VALUES
)
    .map(key => {
        const fieldName = FILTER_MENU_SELECT_LIST_VALUES[key];
        const option = {
            label: t(`filter.constant.${fieldName}`),
            value: fieldName
        };

        switch (fieldName) {
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_BET:
            case FILTER_MENU_SELECT_LIST_VALUES.TOTAL_WIN:
            case FILTER_MENU_SELECT_LIST_VALUES.BONUS_WIN:
            case FILTER_MENU_SELECT_LIST_VALUES.BONUS_BET:
                return {
                    ...option,
                    modal: FILTER_POPUPS.EXCEL
                };
            case FILTER_MENU_SELECT_LIST_VALUES.IS_LIVE:
                return {
                    ...option,
                    modal: FILTER_POPUPS.SIMPLE_BOOL
                }
            case FILTER_MENU_SELECT_LIST_VALUES.STATUS:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CHECKBOX,
                    list: Object.values(FILTER_CHECKBOX_GAME_STATUS_VALUE).map(
                        item => ({
                            label: t(`crm.status.${item}`),
                            key: item
                        })
                    ),
                }
            case FILTER_MENU_SELECT_LIST_VALUES.CREATED_AT:
                return {
                    ...option,
                    modal: FILTER_POPUPS.DATE_RANGE
                };
            case FILTER_MENU_SELECT_LIST_VALUES.BET_ID:
                return {
                    ...option,
                    modal: FILTER_POPUPS.CONTAINS
                };
            default:
                return undefined;
        }
    })
    .filter(item => !!item)
    .sort((a, b) => {
        return a.label < b.label ? -1 :
            a.label > b.label ? 1 : 0;
    });

export const DEFAULT_PLAYER_KYC_COUNT_QUERY = {
    filterDynamic: [
        {
            key: FILTER_MENU_SELECT_LIST_VALUES.KYC,
            value: AMLCompliantStatus.Review,
        }
    ],
};

export const DEFAULT_WITHDRAW_COUNT_QUERY = {
    headers: {
        "x-only-count": true
    },
};
