import React, { useEffect, useReducer, useState, useCallback, useRef, useMemo } from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from "react-i18next";
import { Card } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import AddAgentModal from '../modal/AddAgentModal';
import EditAgentModal from '../modal/EditAgentModal';
import ChangeAgentPasswordModal from '../modal/ChangeAgentPasswordModal';
import useAlertService from '../../../hooks/useAlertService';
import { getSync, postSync } from '../../../helpers/api_helper';
import {
    ActionTypes,
    agentBrandColumns,
    makeData,
} from '../../../components/DynamicTable/utils';
import { tableReducer } from '../../../components/DynamicTable/App';
import ATable from '../../../components/DynamicTable/Table';
import Loader from '../../../common/components/Loader';
import {createAgent, changeAgentPassword, resetAgentsError, getAgentsList} from "../../../store/agents/agents/actions";

import plus_icon from "../../../assets/images/common/plus icon.svg";

import './agents.scss';
import { SITE_PERMISSION } from "../../../common/constants/common";
import { SITE_BRAND } from "../../../constants/siteBrands"
import { getBrands } from "../../../store/brand/siteBrands/actions"

const MODAL_MODE = {
    CREATE: 'create',
    CHANGE: 'change',
    RESULT: 'result',
};

const AgentsPage = () => {
    const reduxDispatch = useDispatch();
    const { isCreating, isPasswordUpdating, error } = useSelector(
        state => state.AgentList
    );

    const {
        active: activeBrand,
        data: brandsData,
    } = useSelector(state => state.Brand);

    useEffect(() => {
        dispatch(getBrands());
        dispatch(getAgentsList());
    }, []);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;

    const canChangePasswordPermission = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Agent__Edit_Password);
    }, [agentSelf]);

    const canAgentRegisterPermission = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Agent__Register);
    }, [agentSelf]);

    const prevState = useRef({
        isCreating: false,
        isPasswordUploading: false,
    });

    const alertService = useAlertService();
    const { t, i18n } = useTranslation();
    const [response, setResponse] = useState();
    const [initialResponse, setInitialResponse] = useState();
    const [rolesList, setRolesList] = useState();
    const [updating, setUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [addModalMode, setAddModalMode] = useState(MODAL_MODE.CREATE);
    const [editModalShow, setEditModalShow] = useState(false);
    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false);
    const [changePasswordModalMode, setChangePasswordModalMode] = useState(MODAL_MODE.CHANGE);
    const [currentRow, setCurrentRow] = useState({});
    const [brandNames, setBrandNames] = useState([]);

    const [otherAgentsFromRole, setOtherAgentsFromRole] = useState([]);
    const [isRolePermissionsLoaded, setIsRolePermissionsLoaded] = useState(false);


    const is21Dealer = useMemo(() => {
        return activeBrand?.siteName === SITE_BRAND.Dealer21
    }, [activeBrand?.siteName])

    const agentRoleId = agentSelf?.roleId || '';

    const urlRolePermissions = `/it-manager/role-permissions-visibilities?roleId=${agentRoleId}`;

    useEffect(() => {
        const fetchRolePermissions = async () => {
            if (agentRoleId) {
                try {
                    const result = await getSync(urlRolePermissions, {}, setIsLoading);
                    setOtherAgentsFromRole(result?.data?.visibility?.otherAgents || []);
                    setIsRolePermissionsLoaded(true);
                } catch (error) {
                    setIsRolePermissionsLoaded(false);
                }
            }
        };
        fetchRolePermissions();
    }, [agentRoleId, urlRolePermissions]);

    useEffect(() => {
        const brandNames = brandsData.map((brand) => brand.siteName);
        setBrandNames(brandNames);
    }, [brandsData]);

    const getValues = async () => {
        const url = `/management`;
        setIsLoading(true);
        try {
            if (agentRoleId) {
                if (response) {
                    return;
                }
                const result = await getSync(url, {}, setIsLoading);
                let list = result.data;

                if (!agentSelf?.email.includes('wiztechgroup.com')) {
                    list.result = result.data.result.filter(agent => !agent.email.includes('wiztechgroup.com'));
                }

                if (is21Dealer && otherAgentsFromRole.length > 0) {
                    list.result = list.result.filter(agent => otherAgentsFromRole.includes(agent.id));
                }

                setResponse(list);
                setInitialResponse(_.clone(list));
            }
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError('Cannot complete, Contact support');
        } finally {
            setIsLoading(false);
        }
    };

    const getRoles = async () => {
        const url = `/it-manager/roles`;
        const result = await getSync(url, {});
        setRolesList(result.data);
    };

    useEffect(() => {
        if (!agentSelf) {
            return;
        }
        getRoles();

        if (isRolePermissionsLoaded) {
            getValues();
        }
    }, [agentSelf, isRolePermissionsLoaded]);


    useEffect(() => {
        if(!agentSelf) {
            return;
        }

        if (!isCreating && prevState.current.isCreating && !error) {
            getValues();
            setAddModalMode(MODAL_MODE.RESULT);
        }

        prevState.current = {
            ...prevState.current,
            isCreating,
        };
    }, [isCreating, agentSelf]);

    useEffect(() => {
        if (!isPasswordUpdating && prevState.current.isPasswordUpdating && !error) {
            setChangePasswordModalMode(MODAL_MODE.RESULT);
        }

        prevState.current = {
            ...prevState.current,
            isPasswordUpdating,
        };
    }, [isPasswordUpdating]);

    useEffect(() => {
        if (error) {
            alertService.showError(error?.message || 'Cannot create, Contact support');
            reduxDispatch(resetAgentsError());
        }
    }, [error]);

    const updateList = async (updatedAgent) => {
        const changes = [];

        const initData = initialResponse.result;
        state.data.find((raw, index) => {
            if (initData[index]?.id === updatedAgent.id) {
                raw.id = updatedAgent.id;
                changes.push({
                    ...raw,
                    department: updatedAgent.department === 'Other'
                        ? updatedAgent.departmentValue
                        : updatedAgent.department,
                    managerId: updatedAgent.managerId,
                    firstName: updatedAgent.firstName,
                    lastName: updatedAgent.lastName,
                    email: updatedAgent.email,
                    phoneExtension: updatedAgent.phoneExtension,
                });

                return true;
            }
        });

        try {
            setUpdating(true);
            const url = `/management`;
            const result = await postSync(url, changes);

            if (result.success) {
                alertService.showSuccess(`Updated!`);
                setUpdating(false);
                setEditModalShow(false);
            } else {
                alertService.showError('Cannot update, Contact support');
                setUpdating(false);
                setEditModalShow(false);
            }
        } catch (e) {
            alertService.showError(
                e?.message
                    ? i18n.t(`crm.${e?.message}`)
                    : 'Cannot update, Contact support'
            );
            setUpdating(false);
        }

        // refresh page
        window.location.reload();

    };

    const [state, dispatch] = useReducer(
        tableReducer,
        makeData(
            agentBrandColumns(rolesList, brandNames, t, canChangePasswordPermission, canAgentRegisterPermission),
            response,
        )
    );

    const initialState = useCallback(() => {
        return makeData(
            agentBrandColumns(rolesList, brandNames, t, canChangePasswordPermission, canAgentRegisterPermission),
            initialResponse,
        );
    }, [initialResponse])

    const handleCancel = () => {
        dispatch({ type: ActionTypes.CANCEL_CHANGES, value: initialState().data });
        setEditModalShow(false);
    }

    const handleCloseAddModal = () => {
        setAddModalShow(false);
        setAddModalMode(MODAL_MODE.CREATE);
    }

    const handleCloseChangePassword = () => {
        setChangePasswordModalShow(false);
        setChangePasswordModalMode(MODAL_MODE.CHANGE);
    }

    useEffect(() => {
        dispatch({ type: ActionTypes.ENABLE_RESET });
    }, [state.data, state.columns]);

    const isObjEquals = useCallback(() => {
        return !state.data.find((raw, index) => !objectEquals(response.result[index], state.data[index]));
    }, [state?.data, response?.result]);

    useEffect(() => {
        const equals = isObjEquals(state?.data, response?.result);

        if (equals) {
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
    }, [state?.data, response?.result]);

    useEffect(() => {
        if (rolesList) {
            const v = agentBrandColumns(rolesList, brandNames, t, canChangePasswordPermission, canAgentRegisterPermission);
            dispatch({ type: ActionTypes.SET_COLUMNS, state: v });
        }
        if (response) {
            const data = makeData(
                agentBrandColumns(rolesList, brandNames, t, canChangePasswordPermission, canAgentRegisterPermission),
                response,
            );
            dispatch({ type: ActionTypes.ADD_ROWS, value: data.data });
        }
    }, [ response, rolesList, agentSelf, i18n.language ]);

    const handleShowAddAgent = () => setAddModalShow(true);

    const handleCancelAddAgent = () => setAddModalShow(false);

    const handleShowChangePassword = () => setChangePasswordModalShow(true);

    const handleCancelChangePassword = () => setChangePasswordModalShow(false);

    const handleShowEditAgent = () => setEditModalShow(true);

    const handleConfirmAddAgent = (agentData) => {
        const requestData = {
            ...agentData,
            sites: [activeBrand?.id],
        }
        reduxDispatch(createAgent(requestData));
    }

    const handleConfirmChangePassword = (agentData) => {
        const agentId = currentRow?.original?.id;
        if (agentId) {
            reduxDispatch(changeAgentPassword({ id: agentId, data: agentData }));
        }
    }

    const hanldeShowEditModal = (row) => {
        setCurrentRow(row);
        setEditModalShow(true);
    }

    const hanldeShowChangePasswordModal = (row) => {
        setCurrentRow(row);
        setChangePasswordModalShow(true);
    }

    return (<div className='page-content statistics-page'>
        <MetaTags>
            <title>{i18n.t('admin.agents')} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>

        <Card className='page-card agents-card'>
            <Row className='d-flex justify-content-between site-main-card__header'>
                <Col
                    className='add-btn-section'
                >
                    <button
                        onClick={handleShowAddAgent}
                        className='btn btn-rounded btn-primary app-btn-only-img'
                    >
                        <img src={plus_icon} alt="" />
                    </button>
                </Col>
            </Row>
            <hr />
            <div>
                <div className='agents-table-wrapper'>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <ATable
                            columns={state.columns}
                            data={state.data}
                            dispatch={dispatch}
                            skipReset={state.skipReset}
                            setIsEdit={setIsEdit}
                            showEditModal={hanldeShowEditModal}
                            showChangePassword={hanldeShowChangePasswordModal}
                        />
                    }
                    <div id='popper-portal' />
                </div>
            </div>
        </Card>
        {addModalShow &&
            <AddAgentModal
                onCancel={handleCancelAddAgent}
                onCreate={handleConfirmAddAgent}
                onClose={handleCloseAddModal}
                rolesList={rolesList}
                mode={addModalMode}
                isCreating={isCreating}
            />
        }
        {canAgentRegisterPermission && editModalShow &&
            <EditAgentModal
                onCancel={handleCancel}
                onUpdate={updateList}
                row={currentRow}
                columns={state.columns}
                dispatch={dispatch}
                setIsEdit={setIsEdit}
                updating={updating}
            />
        }
        {canChangePasswordPermission && changePasswordModalShow &&
            <ChangeAgentPasswordModal
                onCancel={handleCancelChangePassword}
                onConfirm={handleConfirmChangePassword}
                onClose={handleCloseChangePassword}
                mode={changePasswordModalMode}
                isCreating={isCreating}
            />
        }
    </div>);
};

export default React.memo(AgentsPage);


function objectEquals(x, y) {
    if(!x || !y) {
        return false;
    }
    for (const ky of Object.keys(y)) {
        if((x[ky] || y[ky]) && JSON.stringify(x[ky]) !== JSON.stringify(y[ky])) {
            return false;
        }
    }
    return true;
}
