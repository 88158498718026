import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Card } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import i18n from '../../i18n';

import './datatables.scss';
import './styles.scss';
import '../../assets/scss/pages/common.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
    FILTER_CATEGORY,
    FILTER_MENU_KYC_LIST,
    FILTER_MENU_SELECT_LIST_VALUES,
} from "../../constants/Filter"
import PlayersPendingKycTable from "./tables/PlayersPendingKycTable"
import {
    getPlayerKYCList,
    resetPlayerListAlertsErrors
} from "../../store/players/list/actions";
import useListPage from "../../hooks/useListPage";
import { AMLCompliantStatus } from "../../constants/status";
import SelectTableView from "../Content/components/SelectTableView";
import { DIRECTION } from "../../constants/layout";
import settings from "../../assets/images/layout/settings.svg";
import clsx from "clsx";
import TableFilter from "../../components/TableFilter";
import { useTranslation } from "react-i18next";


const defaultRequestQuery = {
    filterDynamic: [{key: 'kyc', value: 'Review'}],
    limit: 50,
    offset: 0,
};

const KYC_LIST_SORTED_FIELDS = {
    ID: 'player_id',
    Player: 'player',
    KYC: 'is_kyc',
    'Verification Status': 'document_verification_status',
    'Pending Review': 'pending_review',
};

const ALL_KYC_STATUSES_FILTER = Object
    .keys(AMLCompliantStatus)
    .map((key) => ({
        key: FILTER_MENU_SELECT_LIST_VALUES.KYC,
        value: AMLCompliantStatus[key],
    }));

const DEFAULT_FILTER = [
    {
        key: FILTER_MENU_SELECT_LIST_VALUES.KYC,
        value: AMLCompliantStatus.Review,
    }
];

function PlayerList() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentFilter } = useSelector(state => state.Filter);
    const {
        isLoading,
        total,
        playerKYCList,
        query,
        error,
        successAlert,
    } = useSelector(state => state.PlayerList);
    const [selectedPlayerList, setSelectedPlayerList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [requestQuery, setRequestQuery] = useState(defaultRequestQuery);
    const [isShowTableSettings, setShowTableSettings] = useState(false);
    const [isFirstRequest, setFirstRequest] = useState(true);

    const currentLoadingRef = useRef();

    useEffect(() => {
        if (query.sort || query.order ) {
            delete query.sort
            delete query.order
        }
    }, [query])

    useEffect(() => {
        setSelectedPlayerList(playerKYCList);
    }, [playerKYCList]);

    const requestKYCList = (query) => {
        if (!currentLoadingRef.current) {
            setFirstRequest(false);
            currentLoadingRef.current = true;
            setRequestQuery(query);
            dispatch(getPlayerKYCList(query));
        }
    };

    useEffect(() => {
        if (currentFilter.length > 0) {
            setRequestQuery((prevQuery) => {
                requestKYCList({
                    ...prevQuery,
                    filterDynamic: currentFilter,
                });
                return prevQuery;
            });
        }

    }, [currentFilter]);

    useEffect(() => {
        if (!isLoading && currentLoadingRef.current) {
            currentLoadingRef.current = false;
        }
    }, [isLoading]);

    const { pageCount, activePage, handleChangePage } = useListPage(
        requestKYCList,
        total,
        requestQuery,
        error,
        () => dispatch(resetPlayerListAlertsErrors()),
        successAlert,
        currentFilter,
        currentFilter.length > 0
            ? undefined
            : isFirstRequest
                ? DEFAULT_FILTER
                : ALL_KYC_STATUSES_FILTER
    );

    const getDocuments = useCallback(() => {
        dispatch(getPlayerKYCList(requestQuery));
    }, [requestQuery]);

    const onChangeRecords = (value) => {
        setRequestQuery(prev => ({
            ...prev,
            limit: value,
            offset: 0
        }))
    };

    useEffect(() => {
        handleChangePage(1);
    }, [requestQuery.limit]);

    const handleSortPlayers = useCallback((column, direction = DIRECTION.ASC) => {
        const { sort, order } = requestQuery;
        if (sort !== column || order !== direction) {
            const query = {
                ...requestQuery,
                limit: 50,
                offset: 0,
                sort: KYC_LIST_SORTED_FIELDS[column],
                order: direction,
            };
            requestKYCList(query);
        }
    }, [requestQuery]);

    const handleShowTableSettings = () => {
        setShowTableSettings(true);
    };

    const handleCloseTableSettings = () => {
        setShowTableSettings(false);
    };

    return (
        <div className="player-list players-list__mobile document-content page-content">
            <MetaTags>
                <title>{i18n.t("crm.pendingKycVerification")} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>
            <div className="container-fluid">
                <Card className="page-card players-card__mobile players-card">
                    <div className="page-title-table page-card__header">
                        <TableFilter
                            filterListOptions={FILTER_MENU_KYC_LIST(t)}
                            isDisabled={isLoading}
                            category={FILTER_CATEGORY.PENDING_KYC}
                            buttonMode
                            defaultFilter={DEFAULT_FILTER}
                        />

                        <div className={clsx("d-flex justify-content-end align-items-center ml-10 mt-2 page-card__settings")}>
                            <button
                                className="btn settings-button kyc-settings-button"
                                style={{marginTop: 0}}
                                onClick={handleShowTableSettings}
                            >
                                <img src={settings} alt="Table's Settings" />
                            </button>
                        </div>
                    </div>

                    <PlayersPendingKycTable
                        playerList={selectedPlayerList}
                        isLoading={isLoading}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        getDocuments={getDocuments}
                        onSortPlayers={handleSortPlayers}
                        isShowTableSettings={isShowTableSettings}
                        onCloseTableSettings={handleCloseTableSettings}
                    />

                    <div className='mt-3 mobile-pagination'>
                        <SelectTableView
                            pageCount={pageCount}
                            activePage={activePage}
                            recordsCount={total}
                            onChangePage={handleChangePage}
                            pageSize={requestQuery.limit}
                            onChange={onChangeRecords}
                            isSticky={true}
                            selectedCount={selectedRows?.length}
                        />
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default React.memo(PlayerList);
