import React, {useEffect, useMemo, useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { LanguageContextProvider } from './context/languageContext';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';

// layouts
// TODO needs to be fixed if removing imports breaks sidebar styles
import VerticalLayout from './components/VerticalLayout/';

// Import scss
import './assets/scss/theme.scss';
import './components/GenericComponents/genericStyle.scss';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from './components/Alert/AlertTemplate';
import { CookiesProvider } from 'react-cookie';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import ProtectedRoutes from './routes/ProtectedRoutes';
import NotesPopup from './pages/CRM/general/notes/notesPopup';
import { useDispatch, useSelector } from "react-redux"
import {TimeContextProvider} from "./context/TimeContext";
import { SITE_BRAND } from "./constants/siteBrands"
import { getAgentSelf } from "./store/agents/self/actions"
import { DATACENTER_CODE, DATACENTER_CODE_TYPE } from './common/constants/common';
import wiztechLogo from './assets/images/logo/Logo.png';
import dataSwappLogo from './assets/images/logo/data-swapp.svg';

const theme = createTheme({
    palette: {
        primary: {
            main: '#5f5f8b',
        },
    },
});


const options = {
    position: positions.TOP_CENTER,
    timeout: 5 * 1000, // 5 seconds
    offset: '10px',
    transition: transitions.FADE,
    containerStyle: {
        marginTop: '80px',
        zIndex: 2001
    }
};

const initialState = {
    language: 'en'
};

const App = () => {
    const [state, setState] = useState(initialState);
    const { active: activeBrand } = useSelector(state => state.Brand);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAgentSelf());
    }, []);

    const url = useMemo(() => {
        switch (DATACENTER_CODE) {
            case DATACENTER_CODE_TYPE.wiz_fk:
            case DATACENTER_CODE_TYPE.wiz_ir:
            case DATACENTER_CODE_TYPE.wiz_sp:
                return '/faviconWizztech.ico';
            case DATACENTER_CODE_TYPE.ds_fk:
            case DATACENTER_CODE_TYPE.ds_ir:
                return '/faviconDataSwapp.ico';
            default:
                return '/favicon.ico';
        }
    }, [DATACENTER_CODE]);

    useEffect(() => {
        const faviconElement = document.getElementById('favicon');

        if (faviconElement) {
            faviconElement.href = url;
        }
    }, [url]);

    const setLanguage = language => {
        setState(prev => ({ ...prev, language }));
    };

    useEffect(() => {
        if (activeBrand?.siteName === SITE_BRAND.Dealer21) {
            localStorage.setItem('I18N_LANGUAGE', 'es');
            setLanguage('es')
        } else {
            localStorage.setItem('I18N_LANGUAGE', 'en');
            setLanguage('en')
        }
    }, [activeBrand?.siteName])


    const [showNotes, setShowNotes] = useState(false);
    const switchNotes = () => setShowNotes(!showNotes);
    const { agentSelf } = useSelector(state => state.AgentSelf);

    useMemo(() => {
        if (agentSelf?.pendingSummary?.length > 0) {
            setShowNotes(true);
        }
    }, [agentSelf]);

    return (
        <CookiesProvider>
            <ThemeProvider theme={theme}>
                <AlertProvider template={AlertTemplate} {...options}>
                    <TimeContextProvider>
                    <LanguageContextProvider
                        value={{
                            language: state.language,
                            setLanguage: setLanguage
                        }}
                    >
                        <Router
                            getUserConfirmation={() => {
                                /* Empty callback to block the default browser prompt */
                            }}
                        >
                            {showNotes && (
                                <NotesPopup
                                    onClose={switchNotes}
                                    afterCall={agentSelf?.pendingSummary}
                                />
                            )}

                            <Switch>
                                {publicRoutes.map((route, idx) => (
                                    <Route
                                        exact={true}
                                        key={route.path}
                                        path={route.path}
                                        component={route.component}
                                    />
                                ))}

                                <ProtectedRoutes />
                            </Switch>
                        </Router>
                    </LanguageContextProvider>
                    </TimeContextProvider>
                </AlertProvider>
            </ThemeProvider>
        </CookiesProvider>
    );
};

export default App;
