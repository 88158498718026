import adminRU from './admin.json';
import agentRU from './agent.json';
import affiliatesRU from './affiliates.json';
import attributionsRU from './attributions.json';
import auditRU from './audit.json';
import authenticationRU from './authentication.json';
import brandRU from './brand.json';
import commonRU from './common.json';
import contentRU from './content.json';
import countryRU from './country.json';
import crmTranslationRU from './crm.json';
import dashboardRU from './dashboard.json';
import documentRU from './document.json';
import engagementRU from './engagement.json';
import errorsRU from './errors.json';
import filterRU from './filter.json';
import financeTranslationRU from './finance.json';
import languageRU from './language.json';
import notificationRU from './notifications.json';
import promotionsRU from './promotions.json';
import permissionsRU from './permissions.json';
import reportsRU from './reports.json';
import retailRU from './retail.json';
import rolesRU from './roles.json';
import statesOfMexicoRU from './statesOfMexico.json';
import workflowRU from './workflow.json';
import pageTitlesRU from './pageTitles.json'

export default {
  ...adminRU,
  ...agentRU,
  ...affiliatesRU,
  ...attributionsRU,
  ...auditRU,
  ...authenticationRU,
  ...brandRU,
  ...commonRU,
  ...contentRU,
  ...countryRU,
  ...crmTranslationRU,
  ...dashboardRU,
  ...documentRU,
  ...engagementRU,
  ...errorsRU,
  ...filterRU,
  ...financeTranslationRU,
  ...languageRU,
  ...notificationRU,
  ...permissionsRU,
  ...promotionsRU,
  ...reportsRU,
  ...retailRU,
  ...rolesRU,
  ...statesOfMexicoRU,
  ...workflowRU,
  ...pageTitlesRU,
};
