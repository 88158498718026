import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";

import i18n from '../../../i18n';
import ClipboardButton from '../../../common/cellData/CopyButton';
import { getGuidShortFormat, replaceNewlinesWithSpace, TIME_FORMAT_DD_MM_YEAR } from "../../../common/utils/common"

import enter from '../../../assets/images/common/enter (1) 1.svg';
import enter_1 from '../../../assets/images/common/enter (1) 1 (1).svg';
import frame_108 from '../../../assets/images/common/Frame 108.svg';
import frame_108_1 from '../../../assets/images/common/Frame 108 (1).svg';
import reload from '../../../assets/images/common/reload.svg';
import reload_1 from '../../../assets/images/common/reload (1).svg';
import download from "../../../assets/images/common/download.svg"
import { useDispatch, useSelector } from 'react-redux';
import useList from '../../../hooks/useList';
import {
    downloadPlayerAuditLoginsList,
    getPlayerAuditChangesList,
    getPlayerAuditFilters,
    getPlayerAuditLoginsList,
    getPlayerAuditStatusList,
    resetPlayerAuditListErrors,
} from '../../../store/actions';
import Loader from '../../../common/components/Loader';
import useAlertService from '../../../hooks/useAlertService';
import useWindowSize from '../../../hooks/useWindowSize';
import Formatter from '../../../utils/Formatter';
import { SCREEN_SIZES } from '../../../utils/consts';
import FlagCountry from '../../../common/components/FlagCountry';
import NewCustomTable from '../../../components/NewCustomTable';
import moment from 'moment';
import { prepareTranslationAudit } from "../../../utils/prepareTranslationAudit";
import {
    FILTER_CATEGORY,
    FILTER_MENU_LOGIN_LOGOUT_AUDIT,
    FILTER_MENU_PLAYER_AUDIT,
    FILTER_MENU_STATUS_AUDIT
} from "../../../constants/Filter";
import TableFilter from "../../../components/TableFilter";
import { useTranslation } from "react-i18next";
import {AUDIT_TABS} from "../../../constants/audit";
import SelectTableView from "../../Content/components/SelectTableView";
import { useTimeContext } from "../../../context/TimeContext";
import AuditChangesModal from "../modal/AuditChangesModal";
import { clearCurrentFilter } from "../../../store/filters/action";

const MAX_LENGTH_TEXT = 200;

const checkValue = (value) => {
    if (value === 'Player status is freeze until he explain us who is the owner of the money and make a KYC for him') {
        return `${i18n.t('audit.didntPassSofVerification')}`;
    }
    if (value === 'Freeze account because the player\'s funds, not his') {
        return `${i18n.t('audit.isntPlayerMoney')}`;
    }
    if (value === 'Player with same CURP already exists') {
        return `${i18n.t('audit.playerSameCurpExists')}`;
    }
    return value
}

const formatDate = (date) => Formatter.isISODate(date)
    ? moment(date).format(TIME_FORMAT_DD_MM_YEAR)
    : date;

const prepareChanges = (currentChanges, agentsList) => {
    const currentChangesEntries = Object.entries(_.cloneDeep(currentChanges));
    let firstEntry = currentChangesEntries[0];
    if (!(firstEntry[1] && typeof firstEntry[1] === 'object')) {
        firstEntry = currentChangesEntries[1];
    }

    if (currentChangesEntries.length > 1 && firstEntry[1] && typeof firstEntry[1] === 'object') {
        const { date, agentId: firstAgentId } = firstEntry[1];
        const firstDate = formatDate(date);

        const { sameDate, sameAgentId } = currentChangesEntries
            .reduce(({ sameDate, sameAgentId }, [key, field], ind) => {
                if (['KYC_step', 'KYC_status'].includes(key)) {
                    return { sameDate, sameAgentId };
                }

                if (!field || typeof field !== 'object') {
                    return {
                        sameDate: false,
                        sameAgentId: false,
                    };
                }
                const { date, agentId } = field;

                if (date) {
                    field.date = formatDate(date);
                }

                return {
                    sameDate: sameDate && firstDate === field.date,
                    sameAgentId: sameAgentId && firstAgentId === agentId,
                };
            }, {
                sameDate: !!firstDate,
                sameAgentId: !!firstAgentId,
            });

        currentChangesEntries
            .filter(([_, field]) => !!field)
            .forEach(([key, field]) => {
                if (sameDate) {
                    delete field.date;
                }

                const agent = agentsList?.find(({ id }) => id === field?.agentId);
                if (agent && !sameAgentId) {
                    field.agent = agent?.agentName;
                }

                delete field.agentId;
            });

        if (sameAgentId && agentsList) {
            const agent = agentsList?.find(({ id }) => id === firstAgentId);
            if (agent) {
                currentChangesEntries.unshift(['agent', agent.agentName]);
            } else {
                currentChangesEntries.unshift(['agentId', firstAgentId]);
            }
        }

        if (sameDate) {
            currentChangesEntries.unshift(['date', firstDate]);
        }
    }

    return currentChangesEntries;
}

/**
 * renderField uses Mutual recursion with renderFieldByEntries:
 * renderField calls renderFieldByEntries, renderFieldByEntries cals renderField
 * @param currentChanges
 * @param t
 * @returns {*}
 */
function renderField(currentChanges, agentsList, t, pref = '') {
    const currentChangesEntries = prepareChanges(currentChanges, agentsList);

    // recursive call: renderField -> renderFieldByEntries -> renderField
    return renderFieldByEntries(currentChangesEntries, agentsList, t, pref);
}

function renderFieldByEntries(currentChangesEntries, agentsList, t, pref = '') {
    return currentChangesEntries.map(([key, field], ind) => {
        if (key === 'date') {
            field = formatDate(field);
        }
        else if (key === 'agentId') {
            const agent = agentsList?.find(({ id }) => id === field);

            if (agent) {
                key = 'agent';
                field = agent.agentName;
            }
        }

        const elKey = `${pref}${key}`;

        return (
            <React.Fragment>
                {ind !== 0 && <br/>}
                {key ? (
                    <span key={elKey} className='audit__property_bold'>
                            {`${t(`audit.${key}`)}: `}
                        </span>
                ) : ''}
                {typeof field === 'object' && field && Object.keys(field)?.length > 0
                    ? renderField(field, agentsList, t, `${elKey}_`)
                    : JSON.stringify(checkValue(field))}
            </React.Fragment>
        );
    })
}

const getRenderShortField = (agentsList, t) => {
    let currTextLength = 0;

    return function renderShortField(currentChanges, pref = '') {
        const currentChangesEntries = prepareChanges(currentChanges, agentsList);

        return currentChangesEntries.map((entry, ind) => {
            let [key, field] = entry;

            if (key === 'date') {
                field = formatDate(field);
                entry[1] = field;
            }
            else if (key === 'agentId') {
                const agent = agentsList?.find(({ id }) => id === field);

                if (agent) {
                    key = 'agent';
                    entry[0] = key;
                    field = agent.agentName;
                    entry[1] = field;
                }
            }

            const fieldLabel = key ? `${t(`audit.${key}`)}: ` : '';
            currTextLength += fieldLabel.length;

            if (currTextLength >= MAX_LENGTH_TEXT) {
                return <></>;
            }

            if (typeof field === 'string') {
                const fieldLength = JSON.stringify(field).length;
                currTextLength += fieldLength;

                if (currTextLength > MAX_LENGTH_TEXT) {
                    const remainingChars = MAX_LENGTH_TEXT - (currTextLength - fieldLength);
                    field = field.substring(0, remainingChars);
                }
            }
            const elKey = `${pref}${key}`;
            return (
                <React.Fragment>
                    {ind !== 0 && <br/>}
                    {key
                        ? (<span key={elKey} className='audit__property_bold'>
                            {fieldLabel}
                        </span>)
                        : ''
                    }
                    {typeof field === 'object' && field && Object.keys(field)?.length > 0
                        ? renderShortField(field, `${elKey}_`)
                        : JSON.stringify(field)
                    }
                </React.Fragment>
            );
        });
    }
}

const handleToggle = (index, setActiveRow, activeRow) => {
    activeRow === index ? setActiveRow(null) : setActiveRow(index)
}

function LoginsLogoutTable({ list }) {
    const { timeFormat } = useTimeContext();
    const size = useWindowSize();

    const [activeRow, setActiveRow] = useState(false);

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }

    const columns = [
        { label: i18n.t('crm.time') },
        { label: i18n.t('crm.action') },
        { label: i18n.t('crm.status') },
        { label: i18n.t('crm.country') },
        { label: i18n.t('crm.device') },
        { label: i18n.t('crm.operationSystem') },
        { label: i18n.t('crm.platform') },
        { label: i18n.t('crm.ip') }
    ];

    return (
        <NewCustomTable columns={columns} className={'mb-1 responsiveTable'}>
            {list.map((item, index) => (
                 <tr
                    key={index}
                    className={clsx({open: activeRow === index})}
                    onClick={() => handleToggle(index, setActiveRow, activeRow)}
                >
                    <td>
                        {item.startTime
                            ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.startTime))
                            : '—'
                        }
                    </td>
                    <td className='title__top'>{item.action}</td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.status')}: </span>
                        )}
                        {item.status}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.country')}: </span>
                        )}
                        {(item.baseLocation || item.citizenship) && (
                            <div className="flag-div small d-inline-block me-2">
                                <FlagCountry
                                    countryCode={[item.baseLocation,item?.address?.addressCountryAlfa2,item?.citizenship]}
                                    className="flag-wrapper"
                                />
                            </div>
                        )}
                        {i18n.t(item.country)}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.device')}: </span>
                        )}
                        {item.device}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.operationSystem')}: </span>
                        )}
                        {item.operationSystem}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.platform')}: </span>
                        )}
                        {item.browser}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.ip')}: </span>
                        )}
                        {item.ip}
                    </td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function StatusesTable({ list }) {
    const { timeFormat } = useTimeContext();
    const size = useWindowSize();

    const [activeRow, setActiveRow] = useState(false);

    if (list.length === 0) {
        return (
            <div className='noGamePlayData'>
                {i18n.t('tabs.NoAudit')}
            </div>
    )
    }
    const columns = [
        { label: i18n.t('crm.time') },
        { label: i18n.t('crm.fromStatus') },
        { label: i18n.t('crm.toStatus') },
        { label: i18n.t('crm.comment') },
        { label: i18n.t('crm.initiator') }
    ];

    return (
        <NewCustomTable columns={columns} className={'mb-1 responsiveTable'}>
            {list.map((item, index) => (
                <tr
                    key={index}
                    className={clsx({open: activeRow === index})}
                    onClick={() => handleToggle(index, setActiveRow, activeRow)}
                >
                    <td>
                        {item.time
                            ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.time))
                            : '—'
                        }
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.fromStatus')}: </span>
                        )}
                        {item.fromStatus}
                    </td>
                    <td className='title__top'>{item.toStatus}</td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.comment')}: </span>
                        )}
                        {prepareTranslationAudit(item.comment)}
                    </td>
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.initiator')}: </span>
                        )}
                        {item.agent}
                    </td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function ChangesTable({ list }) {
    const { t } = useTranslation();
    const { timeFormat } = useTimeContext();
    const { agentsList } = useSelector(state => state.AgentList);
    const size = useWindowSize();


    const [activeRow, setActiveRow] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }
    const columns = [
        { label: i18n.t('crm.time') },
        { label: i18n.t('crm.initiator') },
        { label: i18n.t('crm.action') },
        { label: i18n.t('crm.session') },
        { label: i18n.t('crm.from') },
        { label: i18n.t('crm.to') }
    ];

    const openChangesModal = (text) => {
        setModalText(text || '');
        setModalOpen(true);
    };

    const getTextLength = (changes) => {
        const currentText = typeof changes === "object"
            ? JSON.stringify(changes)
            : String(changes);
        return currentText?.length
    }

    const prepareProperties = (logPart, property) => {
        if (logPart !== undefined && logPart !== null) {
            if (Formatter.isISODate(logPart)) {
                return moment(logPart).format(TIME_FORMAT_DD_MM_YEAR);
            }
            if (property?.includes('vipLevel')) {
                return t(`admin.vipLevel.${[logPart]}`);
            }
            if (property === 'this.agentOwner') {
                const agent = agentsList?.find(({ id }) => logPart === id);
                return agent?.agentName
            }
        }
        return logPart;
    }

    const calcChangesLength = (changesEntries) => {
        let packLength = 0
        if (changesEntries.length > 0) {
            changesEntries.forEach((entry) => {
                let [key, field] = entry;
                packLength += key.length;
                if (field !== null) {
                    if (field && typeof field === 'object') {
                        const fieldStr = JSON.stringify(field).replaceAll(/[",{}:]/g, '');
                        packLength += fieldStr.length;
                    } else {
                        packLength += String(field).length;
                    }
                }
            })
        }

        return packLength
    }

    const generateContent = (currentChanges) => {
        const modifiedChanges = replaceNewlinesWithSpace(currentChanges);
        const currentChangesEntries = prepareChanges(modifiedChanges, agentsList);
        const res = renderFieldByEntries(currentChangesEntries, agentsList, t);

        const packLength = calcChangesLength(currentChangesEntries, 0);
        if (packLength > MAX_LENGTH_TEXT) {
            return (
                <>
                    {getRenderShortField(agentsList, t)(modifiedChanges)}
                    <button
                        className='view-audit-changes-btn'
                        onClick={() => openChangesModal(res)}
                    >
                        (...<span>{t('crm.more').toLowerCase()}</span>)
                    </button>
                </>
            );
        } else {
            return res ?? "-";
        }
    }

    return (
        <>
        <NewCustomTable columns={columns} className={'mb-1 responsiveTable responsiveTable__changes'}>
            {list.map((item, indexRow) => {
                // Hide item in case if initiator was SportBook
                if (item.initiator === 'sb') {
                    return null;
                }

                let totalTextFromLength = 0;
                let totalTextToLength = 0;
                const changesFrom = {};
                const changesTo = {};
                return (
                    <tr
                        key={indexRow}
                        className={clsx({open: activeRow === indexRow})}
                        onClick={() => handleToggle(indexRow, setActiveRow, activeRow)}
                    >
                        <td>
                            {item.time
                                ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.time))
                                : '—'
                            }
                        </td>
                        <td>
                            {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <span>{i18n.t('crm.initiator')}: </span>
                            )}
                            {item.initiator}
                        </td>
                        <td className='title__top'>{item.action}</td>
                        <td>
                            {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <span>{i18n.t('crm.session')}: </span>
                            )}
                            {!!item.session && (
                                <div>
                                    <span className="me-2">
                                        {getGuidShortFormat(item.session)}
                                    </span>
                                    <ClipboardButton text={item.session} />
                                </div>
                            )}
                        </td>
                        <td className='wide-content'>
                            {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <span>{i18n.t('crm.from')}: </span>
                            )}
                            <div className='audit__change__fix-width__td'>
                                {item?.changes?.map((change, index, arr) => {
                                    const key = change.property ?? '';
                                    changesFrom[key] = prepareProperties(change.from, change.property);
                                    if (arr.length - 1 > 0 && index !== arr.length - 1) {
                                        return;
                                    }
                                    return generateContent(changesFrom);
                                })}
                            </div>
                        </td>
                        <td className='wide-content'>
                            {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <span>{i18n.t('crm.to')}: </span>
                            )}
                            <div className='audit__change__fix-width__td'>
                                {item?.changes?.map((change, index, arr) => {
                                const key = change.property ?? '';
                                    changesTo[key] = prepareProperties(change.to, change.property);
                                    if (arr.length - 1 > 0 && index !== arr.length - 1) {
                                        return;
                                    }
                                    return generateContent(changesTo);
                                })}
                            </div>
                        </td>
                    </tr>
                )})}
        </NewCustomTable>
            {modalOpen &&
                <AuditChangesModal
                    isOpen={modalOpen}
                    toggle={() => setModalOpen(prevState => !prevState)}
                    currentChanges={modalText}
                />
            }
        </>
    );
}

const getTabIcon = (tab, activeTab) => {
    const isActiveTab = tab === activeTab;

    switch (tab) {
        case AUDIT_TABS.CHANGES:
            return isActiveTab ? reload_1 : reload;

        case AUDIT_TABS.STATUS:
            return isActiveTab ? frame_108_1 : frame_108;

        case AUDIT_TABS.LOGIN_LOGOUT:
            return isActiveTab ? enter : enter_1;
    }
};

const AuditTabHeader = ({ activeTab, onChange }) => (
    <ul className="d-flex sub-content__tab audit-tabs-list">
        {Object.keys(AUDIT_TABS).map(key => {
            return (
                <li
                    key={key}
                    className={`px-0 audit-tabs-item ${activeTab === key ? 'active' : ''}`}
                    onClick={() => onChange(key)}
                >
                    <img
                        className="me-2"
                        src={getTabIcon(key, activeTab)}
                        alt=""
                    />
                    {i18n.t(`crm.${key}`)}
                </li>
            );
        })}
    </ul>
);

function AuditTabContent({ playerId }) {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        isDownloading,
        isLoading,
        error,

        loginsList,
        totalLoginsList,
        queryLoginsList,

        statusList,
        totalStatusList,
        queryStatusList,

        changesList,
        totalChangesList,
        queryChangesList
    } = useSelector(state => state.PlayerAudit);

    const { currentFilter } = useSelector(state => state.Filter);
    const [_queryLoginsList, _setQueryLoginsList] = useState(queryLoginsList);
    const [_queryStatusList, _setQueryStatusList] = useState(queryStatusList);
    const [_queryChangesList, _setQueryChangesList] = useState(queryChangesList);

    const {
        pageCount: pageCountChangesList,
        activePage: activePageChangesList,
        handleChangePage: handleChangeChangesListPage
    } = useList(
        query =>
            dispatch(getPlayerAuditChangesList({ id: playerId, query })),
        totalChangesList,
        _queryChangesList,
        currentFilter,
        [],
        false
    );

    const {
        pageCount: pageCountLoginsList,
        activePage: activePageLoginsList,
        handleChangePage: handleChangeLoginsListPage
    } = useList(
        query =>
            dispatch(getPlayerAuditLoginsList({ id: playerId, query })),
        totalLoginsList,
        _queryLoginsList,
        currentFilter,
        [],
        false
    );

    const {
        pageCount: pageCountStatusList,
        activePage: activePageStatusList,
        handleChangePage: handleChangeStatusListPage
    } = useList(
        query =>
            dispatch(getPlayerAuditStatusList({ id: playerId, query })),
        totalStatusList,
        _queryStatusList,
        currentFilter,
        [],
        false
    );

    useEffect(
        () => {
            dispatch(getPlayerAuditFilters({ id: playerId }));
        },
        [],
    )

    useEffect(() => {
        switch (activeTab) {
            case AUDIT_TABS.CHANGES:
                handleChangeChangesListPage(1);
                break;
            case AUDIT_TABS.STATUS:
                handleChangeStatusListPage(1);
                break;
            case AUDIT_TABS.LOGIN_LOGOUT:
                handleChangeLoginsListPage(1);
                break;
        }
    }, [currentFilter]);

    useEffect(() => {
        dispatch(
            getPlayerAuditChangesList({
                id: playerId,
                query: _queryChangesList
            })
        );
    }, [playerId]);

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetPlayerAuditListErrors());
        }
    }, [error]);

    const [activeTab, setActiveTab] = useState(AUDIT_TABS.CHANGES);

    const handleChangeActiveTab = nextActiveTab => {
        dispatch(clearCurrentFilter());
        if (nextActiveTab === AUDIT_TABS.CHANGES) {
            dispatch(
                getPlayerAuditChangesList({
                    id: playerId,
                    query: _queryChangesList
                })
            );
        } else if (nextActiveTab === AUDIT_TABS.STATUS) {
            dispatch(
                getPlayerAuditStatusList({
                    id: playerId,
                    query: _queryStatusList
                })
            );
        } else if (nextActiveTab === AUDIT_TABS.LOGIN_LOGOUT) {
            dispatch(
                getPlayerAuditLoginsList({
                    id: playerId,
                    query: _queryLoginsList
                })
            );
        }
        setActiveTab(nextActiveTab);
    };

    const handleDownload = useCallback(() => {
        _queryLoginsList.timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        dispatch(downloadPlayerAuditLoginsList({
            id: playerId,
            query: _queryLoginsList
        }))
    }, [_queryLoginsList]);

    const onChangeRecords = (value) => {
        switch (activeTab) {
            case AUDIT_TABS.CHANGES:
                _setQueryChangesList({
                    limit: value,
                    offset: 0
                })
                break;
            case AUDIT_TABS.STATUS:
                _setQueryStatusList({
                    limit: value,
                    offset: 0
                })
                break;
            case AUDIT_TABS.LOGIN_LOGOUT:
                _setQueryLoginsList({
                    limit: value,
                    offset: 0
                })
                break;
        }
    }

    useEffect(() => {
        handleChangeStatusListPage(1);
        handleChangeLoginsListPage(1);
        handleChangeChangesListPage(1);
    }, [_queryChangesList.limit, _queryStatusList.limit, _queryLoginsList.limit])

    return (
        <div className="detailed-card__content audit">
            <div className="audit_header">
                <AuditTabHeader
                  activeTab={activeTab}
                  onChange={handleChangeActiveTab}
                />
                {activeTab === AUDIT_TABS.LOGIN_LOGOUT && (
                  <div className={clsx('d-flex justify-content-end mt-2 download-button', {
                      ['d-none']: !loginsList?.length
                  })}>
                      <button
                        className="btn btn-rounded btn-primary app-btn-only-img-sm"
                        onClick={handleDownload}
                        disabled={isDownloading}
                      >
                          <img src={download} alt="" />
                      </button>
                  </div>
                )}
            </div>

            <div className="page-title-table player-view-tab mb-3 bonuses-tabs-filter">
                <TableFilter
                    filterListOptions={activeTab === AUDIT_TABS.LOGIN_LOGOUT
                        ? FILTER_MENU_LOGIN_LOGOUT_AUDIT(t)
                        : activeTab === AUDIT_TABS.STATUS
                            ? FILTER_MENU_STATUS_AUDIT(t)
                            : FILTER_MENU_PLAYER_AUDIT(t)}
                    isDisabled={isLoading}
                    category={FILTER_CATEGORY.AUDIT}
                    activeTab={activeTab}
                />
            </div>

            {activeTab === AUDIT_TABS.CHANGES && (
                <>
                    <div className='audit__changes__table'>
                        {changesList && !isLoading ? (
                            <ChangesTable list={changesList} />
                        ) : (
                            <Loader size={'lg'}/>
                        )}
                    </div>

                     <div className='mt-3'>
                         <SelectTableView
                             pageCount={pageCountChangesList}
                             activePage={activePageChangesList}
                             recordsCount={totalChangesList}
                             onChangePage={handleChangeChangesListPage}
                             pageSize={_queryChangesList.limit}
                             onChange={onChangeRecords}
                         />
                     </div>
                </>
            )}

            {activeTab === AUDIT_TABS.STATUS && (
                <>
                    {statusList && !isLoading ?  (
                        <StatusesTable list={statusList} />
                    ) : (
                        <Loader size={'lg'}/>
                    )}

                    <br />
                    <SelectTableView
                        pageCount={pageCountStatusList}
                        activePage={activePageStatusList}
                        recordsCount={totalStatusList}
                        onChangePage={handleChangeStatusListPage}
                        pageSize={_queryStatusList.limit}
                        onChange={onChangeRecords}
                    />
                </>
            )}

            {activeTab === AUDIT_TABS.LOGIN_LOGOUT && (
                <>
                    {loginsList && !isLoading ? (
                        <LoginsLogoutTable list={loginsList} />
                    ) : (
                        <Loader size={'lg'}/>
                    )}

                    <br />
                    <SelectTableView
                        pageCount={pageCountLoginsList}
                        activePage={activePageLoginsList}
                        recordsCount={totalLoginsList}
                        onChangePage={handleChangeLoginsListPage}
                        pageSize={_queryLoginsList.limit}
                        onChange={onChangeRecords}
                    />
                </>
            )}
        </div>
    );
}

AuditTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(AuditTabContent);
