import { trimToNull } from '../../../../common/utils/StringUtils';
import Formatter from '../../../../utils/Formatter';
import { getType } from '../../../Admin/site/dataAdapters';
import { getProductId } from "../../../../utils/products"
import moment from 'moment';
import { uniq } from 'lodash';

// common
export function prepareFieldErrors(data = []) {
    const fieldErrors = {};

    data.forEach(item => {
        if (item.constraints) {
            const constraintsKeys = Object.keys(item.constraints);
            let errorMessageKey = '';

            // take the first value
            if (constraintsKeys.length > 0) {
                errorMessageKey = constraintsKeys[0];
            }

            fieldErrors[item.property] = item.constraints[errorMessageKey];
        }

        // for complicated objects
        if (item.children && item.children.length > 0) {
            fieldErrors[item.property] = prepareFieldErrors(item.children);
        }
    });

    return fieldErrors;
}

// general
export function prepareUpdatePlayerGeneralDto(
    oldPlayer = {},
    updatedPlayer = {}
) {
    const playerKeys = uniq([...Object.keys(oldPlayer), ...Object.keys(updatedPlayer)]);
    const dto = {};

    playerKeys.forEach(fieldName => {
        // If we want to clear user gender we must set 'other' as it's a default value
        if (fieldName === 'gender' && updatedPlayer[fieldName] === '') {
            updatedPlayer[fieldName] = 'other';
        }

        if (
            getType(oldPlayer[fieldName]) !== 'object' &&
            !Array.isArray(oldPlayer[fieldName]) &&
            oldPlayer[fieldName] !== updatedPlayer[fieldName] &&
            // '!(!oldPlayer[fieldName] && !updatedPlayer[fieldName])' Used if <>[fieldName] = '' or null
            !(!oldPlayer[fieldName] && !updatedPlayer[fieldName])
        ) {
            if (fieldName === 'birthDate' && (oldPlayer[fieldName] !== updatedPlayer[fieldName])) {
                if (oldPlayer?.birthDate) {
                    const birthDateLocal = new Date(oldPlayer?.birthDate);
                    const oldBirth = new Date(birthDateLocal.getTime() + (birthDateLocal.getTimezoneOffset() * 60 * 1000)).toISOString();
                    if (oldBirth !== updatedPlayer[fieldName]) {
                        dto[fieldName] = updatedPlayer[fieldName]
                            ? moment.utc(moment(updatedPlayer[fieldName]).format('YYYY-MM-DD')).toISOString()
                            : null;
                    }
                } else {
                    dto[fieldName] = updatedPlayer[fieldName]
                        ? moment.utc(moment(updatedPlayer[fieldName]).format('YYYY-MM-DD')).toISOString()
                        : null;
                }
            } else {
                if (fieldName === 'affiliateProduct') {
                    dto[fieldName] = getProductId(updatedPlayer[fieldName]);
                } else if (fieldName === 'vipLevel') {
                    dto[fieldName] = Number(updatedPlayer[fieldName]);
                } else {
                    dto[fieldName] = trimToNull(updatedPlayer[fieldName]);
                }
            }
        }

        if (fieldName === 'otherCitizenship') {
            if (
                Array.isArray(oldPlayer[fieldName]) &&
                Array.isArray(updatedPlayer[fieldName]) &&
                (oldPlayer[fieldName].length !==
                    updatedPlayer[fieldName].length ||
                    !oldPlayer[fieldName].every(item =>
                        updatedPlayer[fieldName].find(
                            country => country === item
                        )
                    ))
            ) {
                dto[fieldName] = updatedPlayer[fieldName];
            }
        }

        // Update player affiliate info
        if (fieldName === 'affiliateInformation' && oldPlayer.affiliateInformation && updatedPlayer?.affiliateInformation) {
            const affiliateInformationDto = {};
            Object.keys(updatedPlayer.affiliateInformation).forEach(affiliateInfoName => {
                if (
                    oldPlayer.affiliateInformation[affiliateInfoName] !==
                    updatedPlayer.affiliateInformation[affiliateInfoName]
                ) {
                    affiliateInformationDto[affiliateInfoName] = trimToNull(
                        updatedPlayer.affiliateInformation[affiliateInfoName]
                    );
                }
            });

            if (Object.keys(affiliateInformationDto).length > 0) {
                if (!dto[fieldName]) {
                    dto[fieldName] = {};
                }

                for (const key of Object.keys(affiliateInformationDto)) {
                    if (typeof affiliateInformationDto[key] !== 'undefined') {
                        dto[fieldName][key] = affiliateInformationDto[key];
                    } else if (oldPlayer.affiliateInformation && typeof oldPlayer.affiliateInformation[key] !== 'undefined') {
                        dto[fieldName][key] = oldPlayer.affiliateInformation[key];
                    }
                }
            }
        }

        // Update player address info
        if (fieldName === 'address' && oldPlayer.address && updatedPlayer?.address) {
            const addressDto = {};
            Object.keys(updatedPlayer.address).forEach(addressFieldName => {
                // fields are different?
                if (
                    oldPlayer.address[addressFieldName] !==
                    updatedPlayer.address[addressFieldName]
                ) {
                    addressDto[addressFieldName] = trimToNull(
                        updatedPlayer.address[addressFieldName]
                    );
                }
            });

            if (Object.keys(addressDto).length > 0) {
                dto[fieldName] = {
                    street: typeof addressDto.street !== 'undefined' ?
                        addressDto.street : oldPlayer.address.street,
                    city: typeof addressDto.city !== 'undefined' ?
                        addressDto.city : oldPlayer.address.city,
                    state: typeof addressDto.state !== 'undefined' ?
                        addressDto.state : oldPlayer.address.state,
                    addressCountryAlfa2: typeof addressDto.addressCountryAlfa2 !== 'undefined' ?
                        addressDto.addressCountryAlfa2 : oldPlayer.address.addressCountryAlfa2,
                    zipCode: typeof addressDto.zipCode !== 'undefined' ?
                        addressDto.zipCode : oldPlayer.address?.zipCode || "",
                };
            }
        }

        // Create player
        if (!oldPlayer.address) {
            const addressCountryAlfa2 = updatedPlayer.addressCountryAlfa2 || 'MX'
            const address = {
                addressCountryAlfa2,
                state: updatedPlayer.state,
                city: updatedPlayer.city,
                street: updatedPlayer.address,
                zipCode: updatedPlayer.zipCode,
            };
            dto.address = address;
            dto.citizenship = addressCountryAlfa2;

            // Remove address parts from top level of player object
            delete dto.country;
            delete dto.addressCountryAlfa2;
            delete dto.state;
            delete dto.city;
            delete dto.zipCode;
        }

        if (
            fieldName === 'personalIdentifiers' &&
            JSON.stringify(oldPlayer.personalIdentifiers) !==
                JSON.stringify(updatedPlayer.personalIdentifiers)
        ) {
            updatedPlayer.personalIdentifiers.forEach(id => {
                Object.keys(id).forEach(idKey => {
                    if (id[idKey] === '') {
                        id[idKey] = undefined;
                    }
                });
            });
            dto.personalIdentifiers = updatedPlayer.personalIdentifiers;
        }
        if (
            fieldName === 'bankInfo' &&
            JSON.stringify(oldPlayer.bankInfo) !==
                JSON.stringify(updatedPlayer.bankInfo)
        ) {
            updatedPlayer.bankInfo.forEach(id => {
                Object.keys(id).forEach(idKey => {
                    if (id[idKey] === '') {
                        id[idKey] = undefined;
                    }
                });
            });
            dto.bankInfo = updatedPlayer.bankInfo;
        }
    });

    return dto;
}

export function prepareUpdatePlayerGeneralFieldErrors(data = []) {
    return prepareFieldErrors(data);
}

// status
export function prepareUpdatePlayerStatusDto(model) {
    const dto = {
        accountStatus: model.status,
        reason: trimToNull(model.reason)
    };
    if (model.coolOffId) {
        dto.coolOffId = model.coolOffId;
    }
    return dto;
}

export function prepareUpdatePlayerStatusFieldErrors(data = []) {
    return prepareFieldErrors(data);
}

// communication
export function prepareUpdatePlayerCommunicationDto(
    oldData = {},
    updatedData = {}
) {
    const dto = {};

    Object.keys(oldData).forEach(fieldName => {
        if (oldData[fieldName] !== updatedData[fieldName]) {
            dto[fieldName] =
                typeof updatedData[fieldName] === 'boolean'
                    ? updatedData[fieldName]
                    : trimToNull(updatedData[fieldName]);
        }
    });

    return dto;
}

export function prepareUpdatePlayerCommunicationFieldErrors(data = []) {
    return prepareFieldErrors(data);
}

export function prepareUpdatePlayerVerificationDto(oldData, updatedData) {
    const dto = {};

    Object.keys(updatedData).forEach(fieldName => {
        if (
            updatedData[fieldName] &&
            typeof updatedData[fieldName] === 'object'
        ) {
            const result = prepareUpdatePlayerVerificationDto(
                oldData?.[fieldName],
                updatedData[fieldName]
            );
            if (Object.keys(result).length > 0) {
                dto[fieldName] = result;
            }
        } else if (
            oldData?.[fieldName] !== updatedData[fieldName] &&
            typeof updatedData[fieldName] === 'boolean'
        ) {
            dto[fieldName] = updatedData[fieldName];
        } else if (
            oldData?.[fieldName] !== updatedData[fieldName] &&
            fieldName === 'birthDate'
        ) {
            dto[fieldName] = Formatter.stringifyDate(
                new Date(updatedData[fieldName])
            );
        } else if (oldData?.[fieldName] !== updatedData[fieldName]) {
            dto[fieldName] = trimToNull(updatedData[fieldName]);
        }
    });

    return dto;
}

export function prepareUpdatePlayerVerificationFieldErrors(data = []) {
    return prepareFieldErrors(data);
}

export function prepareUpdatePlayerRestrictionsDto(oldData, updatedData) {
    const dto = {};

    Object.keys(updatedData).forEach(fieldName => {
        if (oldData?.[fieldName] !== updatedData[fieldName]) {
            dto[fieldName] = updatedData[fieldName];
        }
    });

    return dto;
}

export function prepareUpdatePlayerRestrictionsFieldErrors(data = []) {
    return prepareFieldErrors(data);
}

export function prepareManualAdjustmentModel(selectWalletId, playerId, commentType) {
    return {
        walletId: selectWalletId,
        playerId,
        type: '',
        amount: 0,
        comment: '',
        commentType,
        promoCode: ''
    };
}

export function prepareManualAdjustmentDto(selectWalletId, model) {
    return {
        walletId: selectWalletId || null,
        playerId: model.playerId,
        type: model.type,
        amount: +model.amount,
        comment: model.comment,
        promoCode: model.promoCode,
        commentType: getType(model.commentType) === "object" ? model.commentType?.value : model.commentType
    };
}
