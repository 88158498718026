export const prepareAntiFraudDto = (dto) => {
    return {
        providerType: "Seon",
        antiFraudRules: dto.map(item => {
            const actions = [];

            if (item.actions.includes("block_autowithdrawal")) {
                actions.push({
                    action: "block_autowithdrawal",
                    settings: {}
                });
            }

            if (item.actions.includes("block_promotions")) {
                actions.push({
                    action: "block_promotions",
                    settings: {}
                });
            }

            const hasPromotionIds = item.promotionIds && item.promotionIds.length > 0;
            const hasSportPromotionIds = item.sportPromotionIds && item.sportPromotionIds.length > 0;

            if (hasPromotionIds || hasSportPromotionIds) {
                const settings = {
                    promotionIds: item.promotionIds || [],
                    sportPromotionIds: item.sportPromotionIds || [],
                };

                actions.push({
                    action: "block_specific_promotions",
                    settings
                });
            }

            if (item.actions.includes("disable_payment_methods") && item.paymentMethodIds?.length > 0) {
                actions.push({
                    action: "disable_payment_methods",
                    settings: {
                        paymentMethodIds: item.paymentMethodIds.filter(id => id !== null && id !== undefined)
                    }
                });
            }

            return {
                externalRuleId: item.externalRuleId,
                actions
            };
        })
    };
};