import { useEffect, useState } from "react";
import { getPromotionListApi, getSportPromotionListApi } from "../helpers/promotionsApi";

const usePromotionLists = () => {
  const [casinoPromoList, setCasinoPromoList] = useState([]);
  const [sportPromoList, setSportPromoList] = useState([]);
  const [isLoadingPromoList, setIsLoadingPromoList] = useState(true);

  const filterAndMapPromotions = (promotions) => {
    return promotions
      .filter((item) => item.status === "Running")
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }));
  };

  useEffect(() => {
    const loadPromotions = async () => {
      try {
        const [promotionResponse, sportPromotionResponse] = await Promise.all([
          getPromotionListApi(),
          getSportPromotionListApi(),
        ]);

        const promotionData = promotionResponse.data || [];
        const sportPromotionData = sportPromotionResponse.data || [];

        setCasinoPromoList(filterAndMapPromotions(promotionData));
        setSportPromoList(filterAndMapPromotions(sportPromotionData));
      } catch (error) {
        console.error("Failed to load promotion lists", error);
        setCasinoPromoList([]);
        setSportPromoList([]);
      } finally {
        setIsLoadingPromoList(false);
      }
    };

    loadPromotions();
  }, []);

  return { casinoPromoList, sportPromoList, isLoadingPromoList };
};

export default usePromotionLists;