import SelectInput from "../../../../common/inputs/common/SelectInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import './styles.scss';
import { useMultiWalletCurrency } from "../../../../context/MultiWalletCurrencyContext"

const MultiWalletSelect = (props) => {
  const { playerGeneral } = useSelector(state => state.PlayerGeneral);
  const [multiWalletOptions, setMultiWalletOptions] = useState([]);

  const { selectedCurrency, changeCurrency } = useMultiWalletCurrency();

  const totalBalances = playerGeneral?.totalBalances ?? [];

  useEffect(() => {
    if (totalBalances) {
      const multiWalletCurrencies = totalBalances.map(balance => ({
        label: balance?.currency,
        value: balance?.currency
      }));
      setMultiWalletOptions(multiWalletCurrencies || []);
    }
  }, [totalBalances]);

  const onChangeCurrency = (currency) => {
    changeCurrency(currency);
  };

  return (
    <div className='multi-wallet-wrapper'>
      <SelectInput
        options={multiWalletOptions}
        value={selectedCurrency}
        onChange={onChangeCurrency}
        isSearchable
      />
    </div>
  );
};

export default MultiWalletSelect;