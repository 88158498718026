import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import {DATE_FORMAT, DATE_TIME_FORMAT, DATE_TIME_FORMAT_ss} from "../common/utils/common";
import { getCountry } from "iso-3166-1-alpha-2";



const getCurrencySign = (currency) => {
    const currBuilder = (val = '') => `${val}${getSymbolFromCurrency(currency) || currency} `

    switch (currency){
        case "MXN":
            return currBuilder('MX')
        case "CAD":
            return currBuilder('C');
        case "NZD":
            return currBuilder('NZ');
        default:
            return currBuilder()
    }
}

const calculateRatio = (val1, val2) => val2 === 0
    ? '-'
    : Math.round((val1 / val2) * 100)  + ' %';

const calcNetBonusRatio = (totalBonuses, netDeposits) => netDeposits === 0
    ? '-'
    : Math.round((totalBonuses / netDeposits) * 100) + ' %'

const getCountryNameByCode = (countryCode) => {
    return getCountry(countryCode);
}

function displayDate(date) {
    if (date) {
        if (date instanceof moment) {
            return date.format(DATE_FORMAT);
        } else {
            return moment(new Date(date)).format(DATE_FORMAT);
        }
    } else {
        return '-';
    }
}

function displayBirthDate(date) {
    if (!date) return '-';

    return moment.utc(date).format(DATE_FORMAT);
}

function displayDateTime(date) {
    if (!date) return '-';

    return moment(new Date(date)).format(DATE_TIME_FORMAT_ss);
}

function displayDateTimeMinutes(date) {
    if (date) {
        if (date instanceof moment) {
            return date.format(DATE_TIME_FORMAT);
        } else {
            return moment(new Date(date)).format(DATE_TIME_FORMAT);
        }
    } else {
        return '-';
    }
}

function displayDateTimeSeconds(date) {
    if (date) {
        if (date instanceof moment) {
            return date.format(DATE_TIME_FORMAT_ss);
        } else {
            return moment(new Date(date)).format(DATE_TIME_FORMAT_ss);
        }
    } else {
        return '-';
    }
}

function formatAmount(amount, currency) {
    if (!currency) return amount;
    if (typeof amount === 'number') {
        const hasDecimal = amount % 1 !== 0;
        if (hasDecimal) {
            amount = amount.toLocaleString('en-US', {minimumFractionDigits: 2});
        } else {
            amount = amount.toLocaleString('en-US');
        }
    }
    let symbolOfCurrency
    if (currency === "MXN") {
        symbolOfCurrency = "MX$"
    } else if (currency === "NZD" ) {
        symbolOfCurrency = "NZ$"
    } else if (currency === 'CAD') {
        symbolOfCurrency = 'C$'
    } else {
        symbolOfCurrency = getSymbolFromCurrency(currency)
            ? getSymbolFromCurrency(currency)
            : currency
    }

    return `${amount || 0}\xa0${symbolOfCurrency}`;
}

function stringifyDate(date) {
    if (!date) return null;

    return date?.toISOString();
}

function timeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
}

const roundNumber = (number) => (
  Math.round(number * 100) / 100
);

const formatDecimalNumber = (value) => {
    if (value.includes('.') && value.split('.')[1].length > 2) {
        return value.slice(0, value.length - 1);
    }
    const fractionDigits = value.includes('.')
        ? value.split('.')[1].length >= 2 ? 2 : 1
        : 0
    return fractionDigits
        ? Number(value).toFixed(fractionDigits)
        : value
};

const isISODate = (str) => (
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(str)
);

function isValidJSON(json_data) {
    try {
        if (typeof json_data === 'string') {
            JSON.parse(json_data);
        } else {
            JSON.stringify(json_data);
        }
        return !isValidJSONArray(json_data);

    } catch (e) {
        return false;
    }
}

function isValidJSONArray(str) {
    str = parseJSON(str);
    try {
        return Array.isArray(str);
    } catch (e) {
        return false;
    }
}

function parseJSON(string) {
    let inputValue = string;
    try {
        inputValue = JSON.parse(string);
    } catch (e) {}
    return inputValue;
}

/**
 * Merges two objects while giving priority to the first object.
 * @template T - The type of the objects being merged.
 * @param {T} obj1 - The first object to merge.
 * @param {T} obj2 - The second object to merge.
 * @returns {T} A new object that is the result of merging the two objects with priority given to obj1.
 */
function mergeWithPriority(obj1, obj2, seenObjects = new Set()) {
    if (!obj1) {
        return obj2;
    }
    if (!obj2) {
        return obj1;
    }
    if (!obj1 && !obj2) {
        return {};
    }

    // Check if both objects are the same object
    if (obj1 === obj2) {
        return obj1;
    }

    // Check if both objects have already been merged
    if (seenObjects.has(obj1) || seenObjects.has(obj2)) {
        return obj1;
    }

    seenObjects.add(obj1);
    seenObjects.add(obj2);

    const keys1 = Object.getOwnPropertyNames(obj1);
    const keys2 = Object.getOwnPropertyNames(obj2);

    const result = Array.isArray(obj2) ? [...obj2] : { ...obj2 };

    keys1.forEach(key => {
        const value1 = obj1[key];
        const value2 = obj2[key];

        if (value1 !== undefined && typeof value1 === 'object' && value2 !== undefined && typeof value2 === 'object') {
            result[key] = mergeWithPriority(value1, value2, seenObjects);
        } else {
            result[key] = value1 !== undefined ? value1 : value2;
        }
    });

    keys2.forEach(key => {
        if (!result.hasOwnProperty(key)) {
            result[key] = obj2[key];
        }
    });

    return result;
}

const roundToDecimalValue = (val) => {
    if (val === 0) return 0;

    if (typeof val === 'number') {
        const hasDecimal = val % 1 !== 0;
        if (hasDecimal) {
            val = val.toFixed(2);
        }
    }
    return val
}


const Formatter = {
    getCurrencySign,
    getCountryNameByCode,
    mergeWithPriority,
    isValidJSON,
    isValidJSONArray,
    parseJSON,
    displayDate,
    displayDateTime,
    displayDateTimeMinutes,
    displayDateTimeSeconds,
    formatAmount,
    stringifyDate,
    timeSince,
    roundNumber,
    displayBirthDate,
    formatDecimalNumber,
    isISODate,
    calculateRatio,
    calcNetBonusRatio,
    roundToDecimalValue,
};

export default Formatter;
